import React, { useContext } from 'react';
import styled from 'styled-components';
import breakpoint from 'modules/breakpoint';
import { format } from 'modules/helpers/strings';
import Translations from 'modules/translations';
import MainContext from 'components/QuickSearch/components/context';
import SimpleButton from '../shared/simpleButton';
import ClearHistoryButton from '../shared/clearHistoryButton';
import { layoutEnum, buttonEnum, listTypeEnum } from '../../enums';

const Header = styled.div`
    display: flex;
    flex: 0 0 100%;
    background-color: #ececec;
    padding: ${({ layout, isHandheld }) => (layout === layoutEnum.tab ? '10px 15px' : isHandheld ? '5px' : '5px 15px')};
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (min-width: ${breakpoint.sizes.xl}px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;
const Left = styled.div`
    display: flex;
    flex: 1 0 0px;
`;
const Title = styled.div`
    flex: 0 1 auto;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
`;
const Right = styled.div`
    display: flex;
    flex: 1 0 0px;
    justify-content: flex-end;
`;
const Caption = styled.span`
    display: block;
    margin: 15px;
    font-size: 12px;
    text-align: center;
`;

const renderButton = (buttonType, buttonLink, text) => {
    switch (buttonType) {
        case buttonEnum.link:
            if (!buttonLink) return null;
            return <SimpleButton text={text} buttonLink={buttonLink} />;
        case buttonEnum.clearHistory:
            return <ClearHistoryButton text={text} />;
        default:
            return null;
    }
};

export default props => {
    const { buttonLink, headline, captionText, buttonType, hitsShown, hitsTotal, listType } = props;
    const mainContext = useContext(MainContext);
    const { layout, isHandheld } = mainContext;

    return (
        <>
            <Header layout={layout} isHandheld={isHandheld}>
                {(layout === layoutEnum.row || layout === layoutEnum.tab) && <Left />}
                <Title>
                    {headline}
                    {(listType === listTypeEnum.product || listType === listTypeEnum.article) && (
                        <span>
                            {format(Translations.Website.SearchDialog.SectionTitleSubLine)(hitsShown, hitsTotal)}
                        </span>
                    )}
                </Title>
                <Right>
                    {(hitsTotal > hitsShown || buttonType === buttonEnum.clearHistory) &&
                        renderButton(buttonType, buttonLink)}
                </Right>
            </Header>
            {captionText && (
                <Caption
                    dangerouslySetInnerHTML={{
                        __html: captionText
                    }}
                />
            )}
        </>
    );
};
