
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './menu';

        export type MenuItemJsonModel = components['schemas']['MenuItemJsonModel'];
                    export type MenuStyling = components['schemas']['MenuStyling'];
                    export type MenuSectionHeadlineStyling = components['schemas']['MenuSectionHeadlineStyling'];
                    export type MenuSectionStyling = components['schemas']['MenuSectionStyling'];
                    export type MenuItemStyling = components['schemas']['MenuItemStyling'];
                    export type FrontendCustomMenuAction = components['schemas']['FrontendCustomMenuAction'];
                    export type MenuItemCustomActionViewModel = components['schemas']['MenuItemCustomActionViewModel'];
                    export type MenuItemCustomActionWithUrlViewModel = components['schemas']['MenuItemCustomActionWithUrlViewModel'];
                    export type MenuItemLinkViewModel = components['schemas']['MenuItemLinkViewModel'];
                    export type MenuItemLoginOrMyProfileViewModel = components['schemas']['MenuItemLoginOrMyProfileViewModel'];
                    export type MyProfileMenuItemSubTextViewModel = components['schemas']['MyProfileMenuItemSubTextViewModel'];
                    export type MyProfileMenuItemSubTextViewModelMaybe = components['schemas']['MyProfileMenuItemSubTextViewModelMaybe'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type LoginLevel = components['schemas']['LoginLevel'];
                    export type MenuItemMyProfileViewModel = components['schemas']['MenuItemMyProfileViewModel'];
                    export type MenuItemSubMenuViewModel = components['schemas']['MenuItemSubMenuViewModel'];
                    export type MenuItemViewModel = components['schemas']['MenuItemViewModel'];
                    export type PlaceholderLinkType = components['schemas']['PlaceholderLinkType'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type LinkKey = components['schemas']['LinkKey'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type LinkElementModel = components['schemas']['LinkElementModel'];
                    export type LinkElementModelMaybe = components['schemas']['LinkElementModelMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type MenuSectionViewModel = components['schemas']['MenuSectionViewModel'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MenuHeaderViewModel = components['schemas']['MenuHeaderViewModel'];
                    export type MenuViewModel = components['schemas']['MenuViewModel'];
                    export type MenuDisplayViewEnum = components['schemas']['MenuDisplayViewEnum'];
                    export type TooltipType = components['schemas']['TooltipType'];
                    export type TooltipModel = components['schemas']['TooltipModel'];
                    export type TooltipModelMaybe = components['schemas']['TooltipModelMaybe'];
                    export type MenuTooltipsViewModel = components['schemas']['MenuTooltipsViewModel'];
                    

        export function MenuItemViewModelcontentDiscriminator<T>(
    model: components['schemas']['MenuItemViewModel']['content'],
    funcMenuItemCustomActionViewModel: (m: components['schemas']['MenuItemCustomActionViewModel']) => T,
    funcMenuItemCustomActionWithUrlViewModel: (m: components['schemas']['MenuItemCustomActionWithUrlViewModel']) => T,
    funcMenuItemLinkViewModel: (m: components['schemas']['MenuItemLinkViewModel']) => T,
    funcMenuItemLoginOrMyProfileViewModel: (m: components['schemas']['MenuItemLoginOrMyProfileViewModel']) => T,
    funcMenuItemMyProfileViewModel: (m: components['schemas']['MenuItemMyProfileViewModel']) => T,
    funcMenuItemSubMenuViewModel: (m: components['schemas']['MenuItemSubMenuViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MenuItemCustomActionViewModel': return funcMenuItemCustomActionViewModel(model as components['schemas']['MenuItemCustomActionViewModel']);
        case 'MenuItemCustomActionWithUrlViewModel': return funcMenuItemCustomActionWithUrlViewModel(model as components['schemas']['MenuItemCustomActionWithUrlViewModel']);
        case 'MenuItemLinkViewModel': return funcMenuItemLinkViewModel(model as components['schemas']['MenuItemLinkViewModel']);
        case 'MenuItemLoginOrMyProfileViewModel': return funcMenuItemLoginOrMyProfileViewModel(model as components['schemas']['MenuItemLoginOrMyProfileViewModel']);
        case 'MenuItemMyProfileViewModel': return funcMenuItemMyProfileViewModel(model as components['schemas']['MenuItemMyProfileViewModel']);
        case 'MenuItemSubMenuViewModel': return funcMenuItemSubMenuViewModel(model as components['schemas']['MenuItemSubMenuViewModel']);

        default:
            return defaultFunc();
    }
}export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function MenuSectionViewModelitemsDiscriminator<T>(
    model: components['schemas']['MenuSectionViewModel']['items'][0],
    funcMenuItemViewModel: (m: components['schemas']['MenuItemViewModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MenuItemViewModel': return funcMenuItemViewModel(model as components['schemas']['MenuItemViewModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type MegaSuccess = paths['/internal/Menu/Mega']['get']['responses']['200']['content']['text/plain'];

        export const MegaUrl = '/internal/Menu/Mega';

        
        export function Mega(): Promise<MegaSuccess> {
            return fetchJSON<MegaSuccess>(`/internal/Menu/Mega`)
        }
    
        
     
    
        

        

        export type FooterSuccess = paths['/internal/Menu/Footer']['get']['responses']['200']['content']['text/plain'];

        export const FooterUrl = '/internal/Menu/Footer';

        
        export function Footer(): Promise<FooterSuccess> {
            return fetchJSON<FooterSuccess>(`/internal/Menu/Footer`)
        }
    
        
     
    
        

        export type ListParams = paths['/internal/Menu/List']['get']['parameters']['query'];

        export type ListSuccess = paths['/internal/Menu/List']['get']['responses']['200']['content']['text/plain'];

        export const ListUrl = '/internal/Menu/List';

        
        export function List(params: ListParams): Promise<ListSuccess> {
            return fetchJSON<ListSuccess>(`/internal/Menu/List${formatParams(params)}`)
        }
    
        
     
    
        

        export type MenuForWebshopParams = paths['/internal/Menu/MenuForWebshop']['get']['parameters']['query'];

        export type MenuForWebshopSuccess = paths['/internal/Menu/MenuForWebshop']['get']['responses']['200']['content']['text/plain'];

        export const MenuForWebshopUrl = '/internal/Menu/MenuForWebshop';

        
        export function MenuForWebshop(params: MenuForWebshopParams): Promise<MenuForWebshopSuccess> {
            return fetchJSON<MenuForWebshopSuccess>(`/internal/Menu/MenuForWebshop${formatParams(params)}`)
        }
    
        
     
    
        

        export type MenuParams = paths['/internal/Menu/Menu']['get']['parameters']['query'];

        export type MenuSuccess = paths['/internal/Menu/Menu']['get']['responses']['200']['content']['text/plain'];

        export const MenuUrl = '/internal/Menu/Menu';

        
        export function Menu(params: MenuParams): Promise<MenuSuccess> {
            return fetchJSON<MenuSuccess>(`/internal/Menu/Menu${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type MenuTooltipsViewModelSuccess = paths['/internal/ModelOnly/MenuTooltipsViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MenuTooltipsViewModelUrl = '/internal/ModelOnly/MenuTooltipsViewModel';

        
        export function MenuTooltipsViewModel(): Promise<MenuTooltipsViewModelSuccess> {
            return fetchJSON<MenuTooltipsViewModelSuccess>(`/internal/ModelOnly/MenuTooltipsViewModel`)
        }
    
        
     
    
    