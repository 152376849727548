export enum ButtonStyle {
    primary = 'primary',
    secondary = 'secondary',
    secondaryNegative = 'secondaryNegative',
    septenary = 'septenary',
    ghost = 'ghost',
    ghostNegative = 'ghostNegative',
    clubmatas = 'clubmatas',
    live = 'live',
    link = 'link',
    disabled = 'disabled',
    matasPlus = 'matasPlus',
    brandNegative = 'brandNegative',
    transparent = 'transparent'
}

export enum ButtonSize {
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}

export enum IconPosition {
    left = 'left',
    right = 'right'
}
