/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Subscription from './trans-website-basket-subscription';
export * as Samples from './trans-website-basket-samples';
export * as PrizeCertificate from './trans-website-basket-prizecertificate';
export * as Voucher from './trans-website-basket-voucher';
export * as GiveAsGift from './trans-website-basket-giveasgift';
export * as Wrapping from './trans-website-basket-wrapping';
export * as Shipping from './trans-website-basket-shipping';
export * as Powerstep from './trans-website-basket-powerstep';
export * as PotentialLoginDiscount from './trans-website-basket-potentiallogindiscount';

export const FailedToAddDigitalProduct = 'Website.Basket.FailedToAddDigitalProduct';
export const Gifts = 'Website.Basket.Gifts';
export const Products = 'Website.Basket.Products';
export const PricePerPiece = 'Website.Basket.PricePerPiece';
export const Quantity = 'Website.Basket.Quantity';
export const Price = 'Website.Basket.Price';
export const PageName = 'Website.Basket.PageName';
export const FixedLowPriceLine = 'Website.Basket.FixedLowPriceLine';
export const OnlyRedeemableOnce = 'Website.Basket.OnlyRedeemableOnce';
export const Before = 'Website.Basket.Before';
export const Now = 'Website.Basket.Now';
export const TotalPrice = 'Website.Basket.TotalPrice';
export const WithVAT = 'Website.Basket.WithVAT';
export const SubTotal = 'Website.Basket.SubTotal';
export const SubTotalWithBottleDeposit = 'Website.Basket.SubTotalWithBottleDeposit';
export const VATOfTotal = 'Website.Basket.VATOfTotal';
export const GoToCheckout = 'Website.Basket.GoToCheckout';
export const GoToCheckoutMobile = 'Website.Basket.GoToCheckoutMobile';
export const ContinueShopping = 'Website.Basket.ContinueShopping';
export const ContinueShoppingMobile = 'Website.Basket.ContinueShoppingMobile';
export const ShowBasket = 'Website.Basket.ShowBasket';
export const AddToBasket = 'Website.Basket.AddToBasket';
export const AddedMultipleToBasket = 'Website.Basket.AddedMultipleToBasket';
export const ErrorAddToBasket = 'Website.Basket.ErrorAddToBasket';
export const LoginToGetDiscount = 'Website.Basket.LoginToGetDiscount';
export const BasketHeaderPricePrefix = 'Website.Basket.BasketHeaderPricePrefix';
export const PayWithPoints = 'Website.Basket.PayWithPoints';
export const LinkedItemText = 'Website.Basket.LinkedItemText';
export const LinkedItemTextBottleDeposit = 'Website.Basket.LinkedItemTextBottleDeposit';
export const ChooseGift = 'Website.Basket.ChooseGift';
export const Choose = 'Website.Basket.Choose';
export const WrappingCost = 'Website.Basket.WrappingCost';
export const GiftCardDescription = 'Website.Basket.GiftCardDescription';
export const AmountPaidByGiftCard = 'Website.Basket.AmountPaidByGiftCard';
export const SwapCertificate = 'Website.Basket.SwapCertificate';
export const SwapCertificateToggle = 'Website.Basket.SwapCertificateToggle';
export const WrappingSelectedSwapCertificateRequired = 'Website.Basket.WrappingSelectedSwapCertificateRequired';
export const NoLinesInBasket = 'Website.Basket.NoLinesInBasket';
export const SelectFreight = 'Website.Basket.SelectFreight';
export const BasketCalculatorOffline = 'Website.Basket.BasketCalculatorOffline';
export const DisabledMessage = 'Website.Basket.DisabledMessage';
export const TotalDiscount = 'Website.Basket.TotalDiscount';
export const Pieces = 'Website.Basket.Pieces';
export const AchievementIntro = 'Website.Basket.AchievementIntro';
export const Free = 'Website.Basket.Free';
export const GoToBasket = 'Website.Basket.GoToBasket';
export const ExcludedFromGlobalCampaign = 'Website.Basket.ExcludedFromGlobalCampaign';
