import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'variables';
import Spinner from 'modules/shared/circleSpinner';
import type { IDefaultButtonProps } from 'modules/react-button/defaultButton';
import isPromise from 'modules/helpers/promise';

const ButtonInner = styled.div`
    position: relative;
    width: 100%;
`;

const ChildrenWrapper = styled.div<{ isLoading: boolean }>`
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const backgroundColor = {
    primary: colors.negative,
    secondary: colors.positive,
    septenary: colors.negative,
    ghost: colors.positive,
    ghostNegative: colors.positive,
    clubmatas: colors.positive,
    live: colors.positive
};

const buttonHeight = {
    sm: 15,
    md: 20,
    lg: 25
};

interface ISpinnerButtonProps extends IDefaultButtonProps {
    isLoading?: boolean;
}

const withSpinner = <T,>(
    DefaultButton: React.FC<React.PropsWithChildren<T>>
): React.ForwardRefExoticComponent<
    React.PropsWithoutRef<T & ISpinnerButtonProps> & React.RefAttributes<HTMLButtonElement>
> =>
    React.forwardRef<HTMLButtonElement, T & ISpinnerButtonProps>((props, ref) => {
        const { isLoading, children, buttonStyle, buttonSize = 'md', onClick, ...restProps } = props;
        const [loading, setLoading] = useState<boolean>(false);

        async function handleClick(e) {
            if (loading || isLoading) {
                return;
            }

            setLoading(true);

            if (onClick) {
                await onClick(e);
            }

            setLoading(false);
        }

        return (
            <DefaultButton {...({ ...restProps, buttonStyle, buttonSize } as T)} onClick={handleClick}>
                <ButtonInner>
                    {(loading || isLoading) && (
                        <Container>
                            <Spinner color={backgroundColor[buttonStyle]} size={buttonHeight[buttonSize]} />
                        </Container>
                    )}
                    <ChildrenWrapper isLoading={loading || isLoading}>{children}</ChildrenWrapper>
                </ButtonInner>
            </DefaultButton>
        );
    });

export default withSpinner;
