const featureTogglesObj = {};

export const setFeatureToggles = function (obj) {
    for (var item in obj) {
        featureTogglesObj[item] = obj[item];
    }
};

const FeatureToggles = function () {
    return featureTogglesObj;
};

export default FeatureToggles();

