import React from 'react';
import styled from 'styled-components';
import type { IDefaultButtonProps } from 'modules/react-button/defaultButton';
import { SvgSprite } from 'modules/svgSprite';
import { em } from 'modules/helpers/style';
import { SvgSpriteType } from 'autogen/spriteData';
import { IconPosition } from './enums';

const defaultIconPosition = IconPosition.left;
const defaultIconType = 'ArrowRight';

interface IIconProps {
    iconPosition?: string;
    hasChildren?: boolean;
}

interface IButtonStylingProps {
    centerContent?: boolean;
}

const ButtonInner = styled.div<IIconProps & IButtonStylingProps>`
    display: flex;
    flex-direction: ${({ iconPosition }) => (iconPosition === defaultIconPosition ? 'row' : 'row-reverse')};
    justify-content: space-between;
    width: ${({ centerContent }) => (centerContent ? 'auto' : '100%')};
`;

export const ButtonIconSpacer = styled.div<IButtonStylingProps>`
    flex: ${({ centerContent }) => (centerContent ? 'none' : '1 1')};
`;

export const ButtonIconWrapper = styled(ButtonIconSpacer)<IIconProps>`
    min-width: ${({ hasChildren }) => em(hasChildren ? 20 : 15)};
    display: flex;
    align-items: center;
    justify-content: ${({ iconPosition }) => (iconPosition === defaultIconPosition ? 'flex-start' : 'flex-end')};
`;

const ButtonChildren = styled.div`
    flex: 0 1 auto;
    flex-wrap: wrap;
`;

const Icon = styled(SvgSprite)`
    width: ${em('15px')};
    height: ${em('15px')};
`;

export interface IIcon {
    iconType: SvgSpriteType;
    iconPosition?: IconPosition;
    iconFill?: string;
}

export interface IIconButtonProps extends IDefaultButtonProps, IButtonStylingProps {
    icon: IIcon;
}

const withIcon = <T,>(
    DefaultButton: React.FC<React.PropsWithChildren<T>>
): React.ForwardRefExoticComponent<
    React.PropsWithoutRef<T & IIconButtonProps> & React.RefAttributes<HTMLButtonElement>
> =>
    React.forwardRef<HTMLButtonElement, T & IIconButtonProps>((props, ref) => {
        const { icon, centerContent = false, children } = props;
        const { iconType = defaultIconType, iconPosition = defaultIconPosition, iconFill = 'currentColor' } = icon;

        return (
            <DefaultButton {...props} ref={ref}>
                <ButtonInner iconPosition={iconPosition} centerContent={centerContent}>
                    <ButtonIconWrapper iconPosition={iconPosition} hasChildren={!!children}>
                        <Icon sprite={iconType} fill={iconFill} />
                    </ButtonIconWrapper>
                    <ButtonChildren>{children}</ButtonChildren>
                    <ButtonIconSpacer />
                </ButtonInner>
            </DefaultButton>
        );
    });

export default withIcon;
