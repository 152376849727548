
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {UpdateStatus,UpdateStatusSuccess,UpdateStatusParams,ProductCustomerNotificationCreateModel,ProductCustomerNotificationCreateModelSuccess,ProductReviewHeaderModel,ProductReviewHeaderModelSuccess,AdvisorToolboxModel,AdvisorToolboxModelSuccess,ProductSliderBlockDTOModel,ProductSliderBlockDTOModelSuccess,ProductReviewCreateViewModel,ProductReviewCreateViewModelSuccess,GetVariantColorPickerForPdp,GetVariantColorPickerForPdpSuccess,GetVariantColorPickerForPdpParams,GetVariantPickerForPlp,GetVariantPickerForPlpSuccess,GetVariantPickerForPlpParams,ProductVariantsPdpModel,ProductVariantsPdpModelSuccess,GetProductJson,GetProductJsonSuccess,GetProductJsonParams,_GetAllProductReviews,_GetAllProductReviewsSuccess,_GetAllProductReviewsParams,CreateCustomerNotification,CreateCustomerNotificationSuccess,CreateCustomerNotificationRequestBody,GetRegimePrice,GetRegimePriceSuccess,GetRegimePriceParams,GetProductRegime,GetProductRegimeSuccess,GetProductRegimeParams,ProductInformationViewModel,ProductInformationViewModelSuccess,ProductPageViewModel,ProductPageViewModelSuccess,ProductPageViewModelParams,_GetProductReturnInformation,GetCertification,GetCertificationSuccess,GetCertificationParams,PageActions,PageActionsSuccess,ProductDeliveryToStoreInfoViewModel,ProductDeliveryToStoreInfoViewModelSuccess,ProductDeliveryToStoreInfoViewModelParams,ProductDeliveryInfoViewModel,ProductDeliveryInfoViewModelSuccess,ProductDeliveryInfoViewModelParams,ProductListItems,ProductListItemsSuccess,ProductListItemsParams,GetProductListModel,GetProductListModelSuccess,ValidateField,ValidateFieldSuccess,ValidateFieldParams,Create,CreateSuccess,CreateParams,DeleteReview,DeleteReviewSuccess,DeleteReviewParams,AddReviewLike,AddReviewLikeSuccess,AddReviewLikeParams,QuickSearch,QuickSearchSuccess,QuickSearchParams,GetSearchViewModel,GetSearchViewModelSuccess,Disclaimer,DisclaimerSuccess,DisclaimerParams} from './index'

        
        
      export function useUpdateStatusMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateStatusSuccess, TError, {params: UpdateStatusParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) => 
    UpdateStatus(params, ), options)}
  
    
        
    export const ProductCustomerNotificationCreateModelQueryKey = 'product_ProductCustomerNotificationCreateModel';

    export function useProductCustomerNotificationCreateModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductCustomerNotificationCreateModelSuccess, TError, ProductCustomerNotificationCreateModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductCustomerNotificationCreateModelSuccess | (() => ProductCustomerNotificationCreateModelSuccess);},  baseQueryKey = ProductCustomerNotificationCreateModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductCustomerNotificationCreateModel, options);
        }
  
    
        
    export const ProductReviewHeaderModelQueryKey = 'product_ProductReviewHeaderModel';

    export function useProductReviewHeaderModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductReviewHeaderModelSuccess, TError, ProductReviewHeaderModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductReviewHeaderModelSuccess | (() => ProductReviewHeaderModelSuccess);},  baseQueryKey = ProductReviewHeaderModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductReviewHeaderModel, options);
        }
  
    
        
    export const AdvisorToolboxModelQueryKey = 'product_AdvisorToolboxModel';

    export function useAdvisorToolboxModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorToolboxModelSuccess, TError, AdvisorToolboxModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorToolboxModelSuccess | (() => AdvisorToolboxModelSuccess);},  baseQueryKey = AdvisorToolboxModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            AdvisorToolboxModel, options);
        }
  
    
        
    export const ProductSliderBlockDTOModelQueryKey = 'product_ProductSliderBlockDTOModel';

    export function useProductSliderBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductSliderBlockDTOModelSuccess, TError, ProductSliderBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductSliderBlockDTOModelSuccess | (() => ProductSliderBlockDTOModelSuccess);},  baseQueryKey = ProductSliderBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductSliderBlockDTOModel, options);
        }
  
    
        
    export const ProductReviewCreateViewModelQueryKey = 'product_ProductReviewCreateViewModel';

    export function useProductReviewCreateViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductReviewCreateViewModelSuccess, TError, ProductReviewCreateViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductReviewCreateViewModelSuccess | (() => ProductReviewCreateViewModelSuccess);},  baseQueryKey = ProductReviewCreateViewModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductReviewCreateViewModel, options);
        }
  
    
        
    export const GetVariantColorPickerForPdpQueryKey = 'product_GetVariantColorPickerForPdp';

    export function useGetVariantColorPickerForPdpQuery<TError = unknown>(params: GetVariantColorPickerForPdpParams, options?: Omit<UseQueryOptions<GetVariantColorPickerForPdpSuccess, TError, GetVariantColorPickerForPdpSuccess, [string, GetVariantColorPickerForPdpParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetVariantColorPickerForPdpSuccess | (() => GetVariantColorPickerForPdpSuccess);},  baseQueryKey = GetVariantColorPickerForPdpQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetVariantColorPickerForPdp(params), options);
        }
  
    
        
    export const GetVariantPickerForPlpQueryKey = 'product_GetVariantPickerForPlp';

    export function useGetVariantPickerForPlpQuery<TError = unknown>(params: GetVariantPickerForPlpParams, options?: Omit<UseQueryOptions<GetVariantPickerForPlpSuccess, TError, GetVariantPickerForPlpSuccess, [string, GetVariantPickerForPlpParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetVariantPickerForPlpSuccess | (() => GetVariantPickerForPlpSuccess);},  baseQueryKey = GetVariantPickerForPlpQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetVariantPickerForPlp(params), options);
        }
  
    
        
    export const ProductVariantsPdpModelQueryKey = 'product_ProductVariantsPdpModel';

    export function useProductVariantsPdpModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductVariantsPdpModelSuccess, TError, ProductVariantsPdpModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductVariantsPdpModelSuccess | (() => ProductVariantsPdpModelSuccess);},  baseQueryKey = ProductVariantsPdpModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductVariantsPdpModel, options);
        }
  
    
        
    export const GetProductJsonQueryKey = 'product_GetProductJson';

    export function useGetProductJsonQuery<TError = unknown>(params: GetProductJsonParams, options?: Omit<UseQueryOptions<GetProductJsonSuccess, TError, GetProductJsonSuccess, [string, GetProductJsonParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductJsonSuccess | (() => GetProductJsonSuccess);},  baseQueryKey = GetProductJsonQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetProductJson(params), options);
        }
  
    
        
    export const _GetAllProductReviewsQueryKey = 'product__GetAllProductReviews';

    export function use_GetAllProductReviewsQuery<TError = unknown>(params: _GetAllProductReviewsParams, options?: Omit<UseQueryOptions<_GetAllProductReviewsSuccess, TError, _GetAllProductReviewsSuccess, [string, _GetAllProductReviewsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _GetAllProductReviewsSuccess | (() => _GetAllProductReviewsSuccess);},  baseQueryKey = _GetAllProductReviewsQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => _GetAllProductReviews(params), options);
        }
  
    
        
      export function useCreateCustomerNotificationMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CreateCustomerNotificationSuccess, TError, {requestBody: CreateCustomerNotificationRequestBody} & TVariables>, 'mutationFn'>) {
          return useMutation(({ requestBody}) => 
    CreateCustomerNotification( requestBody), options)}
  
    
        
    export const GetRegimePriceQueryKey = 'product_GetRegimePrice';

    export function useGetRegimePriceQuery<TError = unknown>(params: GetRegimePriceParams, options?: Omit<UseQueryOptions<GetRegimePriceSuccess, TError, GetRegimePriceSuccess, [string, GetRegimePriceParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetRegimePriceSuccess | (() => GetRegimePriceSuccess);},  baseQueryKey = GetRegimePriceQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetRegimePrice(params), options);
        }
  
    
        
    export const GetProductRegimeQueryKey = 'product_GetProductRegime';

    export function useGetProductRegimeQuery<TError = unknown>(params: GetProductRegimeParams, options?: Omit<UseQueryOptions<GetProductRegimeSuccess, TError, GetProductRegimeSuccess, [string, GetProductRegimeParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductRegimeSuccess | (() => GetProductRegimeSuccess);},  baseQueryKey = GetProductRegimeQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetProductRegime(params), options);
        }
  
    
        
    export const ProductInformationViewModelQueryKey = 'product_ProductInformationViewModel';

    export function useProductInformationViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductInformationViewModelSuccess, TError, ProductInformationViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductInformationViewModelSuccess | (() => ProductInformationViewModelSuccess);},  baseQueryKey = ProductInformationViewModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            ProductInformationViewModel, options);
        }
  
    
        
    export const ProductPageViewModelQueryKey = 'product_ProductPageViewModel';

    export function useProductPageViewModelQuery<TError = unknown>(params: ProductPageViewModelParams, options?: Omit<UseQueryOptions<ProductPageViewModelSuccess, TError, ProductPageViewModelSuccess, [string, ProductPageViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductPageViewModelSuccess | (() => ProductPageViewModelSuccess);},  baseQueryKey = ProductPageViewModelQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => ProductPageViewModel(params), options);
        }
  
    
        
    export const _GetProductReturnInformationQueryKey = 'product__GetProductReturnInformation';

    export function use_GetProductReturnInformationQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = _GetProductReturnInformationQueryKey) {
            return useQuery([baseQueryKey, ], 
            _GetProductReturnInformation, options);
        }
  
    
        
    export const GetCertificationQueryKey = 'product_GetCertification';

    export function useGetCertificationQuery<TError = unknown>(params: GetCertificationParams, options?: Omit<UseQueryOptions<GetCertificationSuccess, TError, GetCertificationSuccess, [string, GetCertificationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCertificationSuccess | (() => GetCertificationSuccess);},  baseQueryKey = GetCertificationQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => GetCertification(params), options);
        }
  
    
        
    export const PageActionsQueryKey = 'product_PageActions';

    export function usePageActionsQuery<TError = unknown>( options?: Omit<UseQueryOptions<PageActionsSuccess, TError, PageActionsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PageActionsSuccess | (() => PageActionsSuccess);},  baseQueryKey = PageActionsQueryKey) {
            return useQuery([baseQueryKey, ], 
            PageActions, options);
        }
  
    
        
    export const ProductDeliveryToStoreInfoViewModelQueryKey = 'product_ProductDeliveryToStoreInfoViewModel';

    export function useProductDeliveryToStoreInfoViewModelQuery<TError = unknown>(params: ProductDeliveryToStoreInfoViewModelParams, options?: Omit<UseQueryOptions<ProductDeliveryToStoreInfoViewModelSuccess, TError, ProductDeliveryToStoreInfoViewModelSuccess, [string, ProductDeliveryToStoreInfoViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductDeliveryToStoreInfoViewModelSuccess | (() => ProductDeliveryToStoreInfoViewModelSuccess);},  baseQueryKey = ProductDeliveryToStoreInfoViewModelQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => ProductDeliveryToStoreInfoViewModel(params), options);
        }
  
    
        
    export const ProductDeliveryInfoViewModelQueryKey = 'product_ProductDeliveryInfoViewModel';

    export function useProductDeliveryInfoViewModelQuery<TError = unknown>(params: ProductDeliveryInfoViewModelParams, options?: Omit<UseQueryOptions<ProductDeliveryInfoViewModelSuccess, TError, ProductDeliveryInfoViewModelSuccess, [string, ProductDeliveryInfoViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductDeliveryInfoViewModelSuccess | (() => ProductDeliveryInfoViewModelSuccess);},  baseQueryKey = ProductDeliveryInfoViewModelQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => ProductDeliveryInfoViewModel(params), options);
        }
  
    
        
    export const ProductListItemsQueryKey = 'product_ProductListItems';

    export function useProductListItemsQuery<TError = unknown>(params: ProductListItemsParams, options?: Omit<UseQueryOptions<ProductListItemsSuccess, TError, ProductListItemsSuccess, [string, ProductListItemsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductListItemsSuccess | (() => ProductListItemsSuccess);},  baseQueryKey = ProductListItemsQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => ProductListItems(params), options);
        }
  
    
        
    export const GetProductListModelQueryKey = 'product_GetProductListModel';

    export function useGetProductListModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetProductListModelSuccess, TError, GetProductListModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductListModelSuccess | (() => GetProductListModelSuccess);},  baseQueryKey = GetProductListModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            GetProductListModel, options);
        }
  
    
        
      export function useValidateFieldMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ValidateFieldSuccess, TError, {params: ValidateFieldParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) => 
    ValidateField(params, ), options)}
  
    
        
      export function useCreateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CreateSuccess, TError, {params: CreateParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) => 
    Create(params, ), options)}
  
    
        
      export function useDeleteReviewMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteReviewSuccess, TError, {params: DeleteReviewParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) => 
    DeleteReview(params, ), options)}
  
    
        
      export function useAddReviewLikeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddReviewLikeSuccess, TError, {params: AddReviewLikeParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) => 
    AddReviewLike(params, ), options)}
  
    
        
    export const QuickSearchQueryKey = 'product_QuickSearch';

    export function useQuickSearchQuery<TError = unknown>(params: QuickSearchParams, options?: Omit<UseQueryOptions<QuickSearchSuccess, TError, QuickSearchSuccess, [string, QuickSearchParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: QuickSearchSuccess | (() => QuickSearchSuccess);},  baseQueryKey = QuickSearchQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => QuickSearch(params), options);
        }
  
    
        
    export const GetSearchViewModelQueryKey = 'product_GetSearchViewModel';

    export function useGetSearchViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetSearchViewModelSuccess, TError, GetSearchViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSearchViewModelSuccess | (() => GetSearchViewModelSuccess);},  baseQueryKey = GetSearchViewModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            GetSearchViewModel, options);
        }
  
    
        
    export const DisclaimerQueryKey = 'product_Disclaimer';

    export function useDisclaimerQuery<TError = unknown>(params: DisclaimerParams, options?: Omit<UseQueryOptions<DisclaimerSuccess, TError, DisclaimerSuccess, [string, DisclaimerParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: DisclaimerSuccess | (() => DisclaimerSuccess);},  baseQueryKey = DisclaimerQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => Disclaimer(params), options);
        }
  
    
    