import React, { type HTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';
import { breakpoint, colors, sizes } from 'variables';
import { Spacing16, Spacing32, BorderRadiusMedium } from 'autogen/design-tokens/tokens';

interface ICard extends HTMLAttributes<HTMLDivElement> {
    mediaQuery?: string;
}

const CardElement = styled.div<ICard>`
    ${({ mediaQuery }): string => mediaQuery || '&'} {
        background-color: ${colors.negative};
        border-radius: ${BorderRadiusMedium};
        margin-bottom: ${Spacing16};
        min-width: 1px;

        ${breakpoint.up(sizes.md)} {
            margin-bottom: ${Spacing32};
        }
    }
`;

const Card = forwardRef((props: ICard, ref: any): JSX.Element => {
    const { children, ...rest } = props;
    return (
        <CardElement ref={ref} {...rest}>
            {children}
        </CardElement>
    );
});

export default Card;
