const websiteTimeObj = {};

export const setWebsiteTime = function (obj) {
    for (var item in obj) {
        websiteTimeObj[item] = obj[item];
    }
};

const WebsiteTime = function () {
    return websiteTimeObj;
};

export default WebsiteTime();
