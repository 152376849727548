
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './basket';

        export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type GiftSwapInfo = components['schemas']['GiftSwapInfo'];
                    export type WrapInfo = components['schemas']['WrapInfo'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type BookingViewModel = components['schemas']['BookingViewModel'];
                    export type BookingViewModelMaybe = components['schemas']['BookingViewModelMaybe'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusViewModel = components['schemas']['FavoriteStatusViewModel'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type TriggeredDiscountViewModel = components['schemas']['TriggeredDiscountViewModel'];
                    export type PotentialDiscountViewModel = components['schemas']['PotentialDiscountViewModel'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type ProgressiveDiscountViewModelMaybe = components['schemas']['ProgressiveDiscountViewModelMaybe'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type DiscountInfoViewModel = components['schemas']['DiscountInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionInfoViewModelMaybe = components['schemas']['SubscriptionInfoViewModelMaybe'];
                    export type ProductLinkedItemType = components['schemas']['ProductLinkedItemType'];
                    export type LinkedItemPriceInfoViewModel = components['schemas']['LinkedItemPriceInfoViewModel'];
                    export type BasketLineSubscriptionNudgePriceInfo = components['schemas']['BasketLineSubscriptionNudgePriceInfo'];
                    export type BasketLineSubscriptionNudgeTexts = components['schemas']['BasketLineSubscriptionNudgeTexts'];
                    export type BasketLineSubscriptionNudge = components['schemas']['BasketLineSubscriptionNudge'];
                    export type BasketLineSubscriptionNudgeMaybe = components['schemas']['BasketLineSubscriptionNudgeMaybe'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MatasPlusBasketLineInfoModel = components['schemas']['MatasPlusBasketLineInfoModel'];
                    export type MatasPlusBasketLineInfoModelMaybe = components['schemas']['MatasPlusBasketLineInfoModelMaybe'];
                    export type DonationBasketLineInfoModel = components['schemas']['DonationBasketLineInfoModel'];
                    export type DonationBasketLineInfoModelMaybe = components['schemas']['DonationBasketLineInfoModelMaybe'];
                    export type BasketLineViewModel = components['schemas']['BasketLineViewModel'];
                    export type BasketGiftLineViewModel = components['schemas']['BasketGiftLineViewModel'];
                    export type BasketSampleLineViewModel = components['schemas']['BasketSampleLineViewModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type IBasketInfoBoxMaybe = components['schemas']['IBasketInfoBoxMaybe'];
                    export type BasketLineGroupViewModel = components['schemas']['BasketLineGroupViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type BasketGiftCardSummary = components['schemas']['BasketGiftCardSummary'];
                    export type BasketGiftCardSummaryMaybe = components['schemas']['BasketGiftCardSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type BasketSummaryViewModel = components['schemas']['BasketSummaryViewModel'];
                    export type VoucherModel = components['schemas']['VoucherModel'];
                    export type ChoosablePrizeCertificateModel = components['schemas']['ChoosablePrizeCertificateModel'];
                    export type PointInfo = components['schemas']['PointInfo'];
                    export type BasketPointInfo = components['schemas']['BasketPointInfo'];
                    export type BasketPrizeCertificateModel = components['schemas']['BasketPrizeCertificateModel'];
                    export type BasketPrizeCertificateModelMaybe = components['schemas']['BasketPrizeCertificateModelMaybe'];
                    export type InfoUrls = components['schemas']['InfoUrls'];
                    export type ListItemUrls = components['schemas']['ListItemUrls'];
                    export type PinCodeTexts = components['schemas']['PinCodeTexts'];
                    export type PinCodeTextsMaybe = components['schemas']['PinCodeTextsMaybe'];
                    export type PersonalOfferListItem = components['schemas']['PersonalOfferListItem'];
                    export type PersonalOfferInfoModel = components['schemas']['PersonalOfferInfoModel'];
                    export type PersonalOfferInfoModelMaybe = components['schemas']['PersonalOfferInfoModelMaybe'];
                    export type BasketFooterViewModel = components['schemas']['BasketFooterViewModel'];
                    export type BasketValidationError = components['schemas']['BasketValidationError'];
                    export type BasketValidationInfo = components['schemas']['BasketValidationInfo'];
                    export type LoginDirectLinkMaybe = components['schemas']['LoginDirectLinkMaybe'];
                    export type BasketMessageDiscountViewModel = components['schemas']['BasketMessageDiscountViewModel'];
                    export type BasketMessageType = components['schemas']['BasketMessageType'];
                    export type BasketMessageGwpViewModel = components['schemas']['BasketMessageGwpViewModel'];
                    export type BasketMessageMatasPlusViewModel = components['schemas']['BasketMessageMatasPlusViewModel'];
                    export type BasketMessageSameDayDeliveryViewModel = components['schemas']['BasketMessageSameDayDeliveryViewModel'];
                    export type BasketMessageSampleGroupViewModel = components['schemas']['BasketMessageSampleGroupViewModel'];
                    export type BasketMessageShippingViewModel = components['schemas']['BasketMessageShippingViewModel'];
                    export type IBasketMessageMaybe = components['schemas']['IBasketMessageMaybe'];
                    export type BasketMessagesViewModel = components['schemas']['BasketMessagesViewModel'];
                    export type BasketMessagesViewModelMaybe = components['schemas']['BasketMessagesViewModelMaybe'];
                    export type SignupNudgingType = components['schemas']['SignupNudgingType'];
                    export type SignupNudgingFooter = components['schemas']['SignupNudgingFooter'];
                    export type SignupNudgingFooterMaybe = components['schemas']['SignupNudgingFooterMaybe'];
                    export type SignupNudgingModel = components['schemas']['SignupNudgingModel'];
                    export type SignupNudgingModelMaybe = components['schemas']['SignupNudgingModelMaybe'];
                    export type DonationTexts = components['schemas']['DonationTexts'];
                    export type DonationOption = components['schemas']['DonationOption'];
                    export type DonationModel = components['schemas']['DonationModel'];
                    export type DonationModelMaybe = components['schemas']['DonationModelMaybe'];
                    export type BasketDetailedSamplesViewModel = components['schemas']['BasketDetailedSamplesViewModel'];
                    export type UnclaimedGiftViewModel = components['schemas']['UnclaimedGiftViewModel'];
                    export type BasketDetailedViewModel = components['schemas']['BasketDetailedViewModel'];
                    export type FavoriteStatusViewModelMaybe = components['schemas']['FavoriteStatusViewModelMaybe'];
                    export type BasketLineModifiedResultViewModel = components['schemas']['BasketLineModifiedResultViewModel'];
                    export type ModifiedBasketViewModel = components['schemas']['ModifiedBasketViewModel'];
                    export type BasketCheckoutMessageType = components['schemas']['BasketCheckoutMessageType'];
                    export type BasketCheckoutButtonMessageModel = components['schemas']['BasketCheckoutButtonMessageModel'];
                    export type LoginInfoType = components['schemas']['LoginInfoType'];
                    export type LoginMode = components['schemas']['LoginMode'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginModalRequest = components['schemas']['LoginModalRequest'];
                    export type BasketCheckoutButtonModel = components['schemas']['BasketCheckoutButtonModel'];
                    export type SplitTestVariant = components['schemas']['SplitTestVariant'];
                    export type BasketContentViewModel = components['schemas']['BasketContentViewModel'];
                    export type BasketViewModel = components['schemas']['BasketViewModel'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type ShippingSupplierGroupOptionModel = components['schemas']['ShippingSupplierGroupOptionModel'];
                    export type BasketShippingOptionModel = components['schemas']['BasketShippingOptionModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type TrackingOperationResult = components['schemas']['TrackingOperationResult'];
                    export type OperationResult = components['schemas']['OperationResult'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type SampleSelectionViewModel = components['schemas']['SampleSelectionViewModel'];
                    export type SampleGroupViewModel = components['schemas']['SampleGroupViewModel'];
                    export type SampleSelectionResponseViewModel = components['schemas']['SampleSelectionResponseViewModel'];
                    export type ActionWillAffectSampleResult = components['schemas']['ActionWillAffectSampleResult'];
                    export type PointInfoType = components['schemas']['PointInfoType'];
                    export type PlusAdvantages = components['schemas']['PlusAdvantages'];
                    export type PlusAdvantagesMaybe = components['schemas']['PlusAdvantagesMaybe'];
                    export type BasketPointInfoModel = components['schemas']['BasketPointInfoModel'];
                    export type BasketShippingSupplierOption = components['schemas']['BasketShippingSupplierOption'];
                    export type ShippingType = components['schemas']['ShippingType'];
                    export type ShippingDeliveryType = components['schemas']['ShippingDeliveryType'];
                    export type ShippingManagementSystem = components['schemas']['ShippingManagementSystem'];
                    export type Dimensions = components['schemas']['Dimensions'];
                    export type IShippingBasketConstraints = components['schemas']['IShippingBasketConstraints'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type ShippingProductPrice = components['schemas']['ShippingProductPrice'];
                    export type ShippingPriceIntervalConstraints = components['schemas']['ShippingPriceIntervalConstraints'];
                    export type ShippingPriceInterval = components['schemas']['ShippingPriceInterval'];
                    export type Time = components['schemas']['Time'];
                    export type TimeInterval = components['schemas']['TimeInterval'];
                    export type IClosedIntervalOfZipCode = components['schemas']['IClosedIntervalOfZipCode'];
                    export type ZipCodeIntervalCollection = components['schemas']['ZipCodeIntervalCollection'];
                    export type IShippingZipCodeConstraints = components['schemas']['IShippingZipCodeConstraints'];
                    export type ShippingDailyLimits = components['schemas']['ShippingDailyLimits'];
                    export type ShippingActualLimit = components['schemas']['ShippingActualLimit'];
                    export type ShippingActualLimitMaybe = components['schemas']['ShippingActualLimitMaybe'];
                    export type ISingleShipping = components['schemas']['ISingleShipping'];
                    export type ShippingPriceIntervalMaybe = components['schemas']['ShippingPriceIntervalMaybe'];
                    export type IDeliveryInfo = components['schemas']['IDeliveryInfo'];
                    export type IDeliveryInfoMaybe = components['schemas']['IDeliveryInfoMaybe'];
                    export type DeliveryMinMax = components['schemas']['DeliveryMinMax'];
                    export type ShippingPickupDay = components['schemas']['ShippingPickupDay'];
                    export type ShippingPickupWeek = components['schemas']['ShippingPickupWeek'];
                    export type ShippingPickup = components['schemas']['ShippingPickup'];
                    export type ShippingPickupChange = components['schemas']['ShippingPickupChange'];
                    export type ShippingPickupAdditionalPackagingBuffer = components['schemas']['ShippingPickupAdditionalPackagingBuffer'];
                    export type ExpectedPickupInfo = components['schemas']['ExpectedPickupInfo'];
                    export type ExpectedPickupInfoMaybe = components['schemas']['ExpectedPickupInfoMaybe'];
                    export type SubscriptionScheduleDates = components['schemas']['SubscriptionScheduleDates'];
                    export type ShippingSubscriptionInfo = components['schemas']['ShippingSubscriptionInfo'];
                    export type FixedFreeShippingReason = components['schemas']['FixedFreeShippingReason'];
                    export type DeliveryTime = components['schemas']['DeliveryTime'];
                    export type InstaboxSupplierResponse = components['schemas']['InstaboxSupplierResponse'];
                    export type InstaboxSupplierResponseMaybe = components['schemas']['InstaboxSupplierResponseMaybe'];
                    export type ShippingDetailed = components['schemas']['ShippingDetailed'];
                    export type ShippingDetailedMaybe = components['schemas']['ShippingDetailedMaybe'];
                    export type FreeShippingInfo = components['schemas']['FreeShippingInfo'];
                    export type BasketShippingSummary = components['schemas']['BasketShippingSummary'];
                    export type ShippingSummaryModel = components['schemas']['ShippingSummaryModel'];
                    

        export function BasketInfoBoxbuttonsDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelinformationDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModel']['information'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelachievementsDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModel']['achievements'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelnudgingMessagesDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModel']['nudgingMessages'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelmatasPlusMessagesDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModel']['matasPlusMessages'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelMaybeinformationDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModelMaybe']['information'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelMaybeachievementsDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModelMaybe']['achievements'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelMaybenudgingMessagesDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModelMaybe']['nudgingMessages'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessagesViewModelMaybematasPlusMessagesDiscriminator<T>(
    model: components['schemas']['BasketMessagesViewModelMaybe']['matasPlusMessages'][0],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type GetBasketSuccess = paths['/internal/Basket/GetBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetBasketUrl = '/internal/Basket/GetBasket';

        
        export function GetBasket(): Promise<GetBasketSuccess> {
            return fetchJSON<GetBasketSuccess>(`/internal/Basket/GetBasket`)
        }
    
        
     
    
        

        

        export type _BasketFooterSuccess = paths['/internal/Basket/_BasketFooter']['get']['responses']['200']['content']['text/html'];

        export const _BasketFooterUrl = '/internal/Basket/_BasketFooter';

        
        export function _BasketFooter(): Promise<_BasketFooterSuccess> {
            return fetchHTML(`/internal/Basket/_BasketFooter`)
        }
    
        
     
    
        

        

        export type _ShippingOptionsSuccess = paths['/internal/Basket/_ShippingOptions']['get']['responses']['200']['content']['text/plain'];

        export const _ShippingOptionsUrl = '/internal/Basket/_ShippingOptions';

        
        export function _ShippingOptions(): Promise<_ShippingOptionsSuccess> {
            return fetchJSON<_ShippingOptionsSuccess>(`/internal/Basket/_ShippingOptions`)
        }
    
        
     
    
        

        export type DeleteFromBasketParams = paths['/internal/Basket/DeleteFromBasket']['post']['parameters']['query'];

        export type DeleteFromBasketSuccess = paths['/internal/Basket/DeleteFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const DeleteFromBasketUrl = '/internal/Basket/DeleteFromBasket';

        
        export function DeleteFromBasket(params: DeleteFromBasketParams): Promise<DeleteFromBasketSuccess> {
            return postQuery<DeleteFromBasketSuccess>(`/internal/Basket/DeleteFromBasket${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type DeleteAllFromBasketSuccess = paths['/internal/Basket/DeleteAllFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const DeleteAllFromBasketUrl = '/internal/Basket/DeleteAllFromBasket';

        
        export function DeleteAllFromBasket(): Promise<DeleteAllFromBasketSuccess> {
            return postQuery<DeleteAllFromBasketSuccess>(`/internal/Basket/DeleteAllFromBasket`)
        }
    
        
     
    
        

        export type DeleteGiftFromBasketParams = paths['/internal/Basket/DeleteGiftFromBasket']['post']['parameters']['query'];

        

        export const DeleteGiftFromBasketUrl = '/internal/Basket/DeleteGiftFromBasket';

        
        export function DeleteGiftFromBasket(params: DeleteGiftFromBasketParams): Promise<unknown> {
            return postQuery<unknown>(`/internal/Basket/DeleteGiftFromBasket${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddToBasketParams = paths['/internal/Basket/AddToBasket']['post']['parameters']['query'];

        export type AddToBasketSuccess = paths['/internal/Basket/AddToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddToBasketUrl = '/internal/Basket/AddToBasket';

        
        export function AddToBasket(params: AddToBasketParams): Promise<AddToBasketSuccess> {
            return postQuery<AddToBasketSuccess>(`/internal/Basket/AddToBasket${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddMultipleToBasketParams = paths['/internal/Basket/AddMultipleToBasket']['post']['parameters']['query'];

        export type AddMultipleToBasketSuccess = paths['/internal/Basket/AddMultipleToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddMultipleToBasketUrl = '/internal/Basket/AddMultipleToBasket';

        
        export function AddMultipleToBasket(params: AddMultipleToBasketParams): Promise<AddMultipleToBasketSuccess> {
            return postQuery<AddMultipleToBasketSuccess>(`/internal/Basket/AddMultipleToBasket${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddOrUpdateQuantityParams = paths['/internal/Basket/AddOrUpdateQuantity']['post']['parameters']['query'];

        export type AddOrUpdateQuantitySuccess = paths['/internal/Basket/AddOrUpdateQuantity']['post']['responses']['200']['content']['text/plain'];

        export const AddOrUpdateQuantityUrl = '/internal/Basket/AddOrUpdateQuantity';

        
        export function AddOrUpdateQuantity(params: AddOrUpdateQuantityParams): Promise<AddOrUpdateQuantitySuccess> {
            return postQuery<AddOrUpdateQuantitySuccess>(`/internal/Basket/AddOrUpdateQuantity${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateQuantityParams = paths['/internal/Basket/UpdateQuantity']['post']['parameters']['query'];

        export type UpdateQuantitySuccess = paths['/internal/Basket/UpdateQuantity']['post']['responses']['200']['content']['text/plain'];

        export const UpdateQuantityUrl = '/internal/Basket/UpdateQuantity';

        
        export function UpdateQuantity(params: UpdateQuantityParams): Promise<UpdateQuantitySuccess> {
            return postQuery<UpdateQuantitySuccess>(`/internal/Basket/UpdateQuantity${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateFrequencyParams = paths['/internal/Basket/UpdateFrequency']['post']['parameters']['query'];

        export type UpdateFrequencySuccess = paths['/internal/Basket/UpdateFrequency']['post']['responses']['200']['content']['text/plain'];

        export const UpdateFrequencyUrl = '/internal/Basket/UpdateFrequency';

        
        export function UpdateFrequency(params: UpdateFrequencyParams): Promise<UpdateFrequencySuccess> {
            return postQuery<UpdateFrequencySuccess>(`/internal/Basket/UpdateFrequency${formatParams(params)}`)
        }
    
        
     
    
        

        export type ToggleSwapCertificatesParams = paths['/internal/Basket/ToggleSwapCertificates']['post']['parameters']['query'];

        export type ToggleSwapCertificatesSuccess = paths['/internal/Basket/ToggleSwapCertificates']['post']['responses']['200']['content']['text/plain'];

        export const ToggleSwapCertificatesUrl = '/internal/Basket/ToggleSwapCertificates';

        
        export function ToggleSwapCertificates(params: ToggleSwapCertificatesParams): Promise<ToggleSwapCertificatesSuccess> {
            return postQuery<ToggleSwapCertificatesSuccess>(`/internal/Basket/ToggleSwapCertificates${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateVoucherCodeParams = paths['/internal/Basket/UpdateVoucherCode']['post']['parameters']['query'];

        export type UpdateVoucherCodeSuccess = paths['/internal/Basket/UpdateVoucherCode']['post']['responses']['200']['content']['text/plain'];

        export const UpdateVoucherCodeUrl = '/internal/Basket/UpdateVoucherCode';

        
        export function UpdateVoucherCode(params: UpdateVoucherCodeParams): Promise<UpdateVoucherCodeSuccess> {
            return postQuery<UpdateVoucherCodeSuccess>(`/internal/Basket/UpdateVoucherCode${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type RemoveVoucherSuccess = paths['/internal/Basket/RemoveVoucher']['post']['responses']['200']['content']['text/plain'];

        export const RemoveVoucherUrl = '/internal/Basket/RemoveVoucher';

        
        export function RemoveVoucher(): Promise<RemoveVoucherSuccess> {
            return postQuery<RemoveVoucherSuccess>(`/internal/Basket/RemoveVoucher`)
        }
    
        
     
    
        

        export type _BasketAddMinimumBuyParams = paths['/internal/Basket/_BasketAddMinimumBuy']['get']['parameters']['query'];

        export type _BasketAddMinimumBuySuccess = paths['/internal/Basket/_BasketAddMinimumBuy']['get']['responses']['200']['content']['text/html'];

        export const _BasketAddMinimumBuyUrl = '/internal/Basket/_BasketAddMinimumBuy';

        
        export function _BasketAddMinimumBuy(params: _BasketAddMinimumBuyParams): Promise<_BasketAddMinimumBuySuccess> {
            return fetchHTML(`/internal/Basket/_BasketAddMinimumBuy${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddDigitalProductParams = paths['/internal/Basket/AddDigitalProduct']['post']['parameters']['query'];

        export type AddDigitalProductSuccess = paths['/internal/Basket/AddDigitalProduct']['post']['responses']['200']['content']['text/plain'];

        export const AddDigitalProductUrl = '/internal/Basket/AddDigitalProduct';

        
        export function AddDigitalProduct(params: AddDigitalProductParams): Promise<AddDigitalProductSuccess> {
            return postQuery<AddDigitalProductSuccess>(`/internal/Basket/AddDigitalProduct${formatParams(params)}`)
        }
    
        
     
    
        

        export type RemovePrizeCertificateParams = paths['/internal/Basket/RemovePrizeCertificate']['post']['parameters']['query'];

        export type RemovePrizeCertificateSuccess = paths['/internal/Basket/RemovePrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const RemovePrizeCertificateUrl = '/internal/Basket/RemovePrizeCertificate';

        
        export function RemovePrizeCertificate(params: RemovePrizeCertificateParams): Promise<RemovePrizeCertificateSuccess> {
            return postQuery<RemovePrizeCertificateSuccess>(`/internal/Basket/RemovePrizeCertificate${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddPrizeCertificateParams = paths['/internal/Basket/AddPrizeCertificate']['post']['parameters']['query'];

        export type AddPrizeCertificateSuccess = paths['/internal/Basket/AddPrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const AddPrizeCertificateUrl = '/internal/Basket/AddPrizeCertificate';

        
        export function AddPrizeCertificate(params: AddPrizeCertificateParams): Promise<AddPrizeCertificateSuccess> {
            return postQuery<AddPrizeCertificateSuccess>(`/internal/Basket/AddPrizeCertificate${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type GetSampleSelectionSuccess = paths['/internal/Basket/GetSampleSelection']['get']['responses']['200']['content']['text/plain'];

        export const GetSampleSelectionUrl = '/internal/Basket/GetSampleSelection';

        
        export function GetSampleSelection(): Promise<GetSampleSelectionSuccess> {
            return fetchJSON<GetSampleSelectionSuccess>(`/internal/Basket/GetSampleSelection`)
        }
    
        
     
    
        

        export type ActionWillAffectSampleParams = paths['/internal/Basket/ActionWillAffectSample']['get']['parameters']['query'];

        export type ActionWillAffectSampleSuccess = paths['/internal/Basket/ActionWillAffectSample']['get']['responses']['200']['content']['text/plain'];

        export const ActionWillAffectSampleUrl = '/internal/Basket/ActionWillAffectSample';

        
        export function ActionWillAffectSample(params: ActionWillAffectSampleParams): Promise<ActionWillAffectSampleSuccess> {
            return fetchJSON<ActionWillAffectSampleSuccess>(`/internal/Basket/ActionWillAffectSample${formatParams(params)}`)
        }
    
        
     
    
        

        export type AddSampleToBasketParams = paths['/internal/Basket/AddSampleToBasket']['post']['parameters']['query'];

        export type AddSampleToBasketSuccess = paths['/internal/Basket/AddSampleToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddSampleToBasketUrl = '/internal/Basket/AddSampleToBasket';

        
        export function AddSampleToBasket(params: AddSampleToBasketParams): Promise<AddSampleToBasketSuccess> {
            return postQuery<AddSampleToBasketSuccess>(`/internal/Basket/AddSampleToBasket${formatParams(params)}`)
        }
    
        
     
    
        

        export type RemoveSampleFromBasketParams = paths['/internal/Basket/RemoveSampleFromBasket']['post']['parameters']['query'];

        export type RemoveSampleFromBasketSuccess = paths['/internal/Basket/RemoveSampleFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const RemoveSampleFromBasketUrl = '/internal/Basket/RemoveSampleFromBasket';

        
        export function RemoveSampleFromBasket(params: RemoveSampleFromBasketParams): Promise<RemoveSampleFromBasketSuccess> {
            return postQuery<RemoveSampleFromBasketSuccess>(`/internal/Basket/RemoveSampleFromBasket${formatParams(params)}`)
        }
    
        
     
    
        

        export type ReplaceSampleInBasketParams = paths['/internal/Basket/ReplaceSampleInBasket']['post']['parameters']['query'];

        export type ReplaceSampleInBasketSuccess = paths['/internal/Basket/ReplaceSampleInBasket']['post']['responses']['200']['content']['text/plain'];

        export const ReplaceSampleInBasketUrl = '/internal/Basket/ReplaceSampleInBasket';

        
        export function ReplaceSampleInBasket(params: ReplaceSampleInBasketParams): Promise<ReplaceSampleInBasketSuccess> {
            return postQuery<ReplaceSampleInBasketSuccess>(`/internal/Basket/ReplaceSampleInBasket${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type GetPointInfoModelSuccess = paths['/internal/Basket/GetPointInfoModel']['get']['responses']['200']['content']['text/plain'];

        export const GetPointInfoModelUrl = '/internal/Basket/GetPointInfoModel';

        
        export function GetPointInfoModel(): Promise<GetPointInfoModelSuccess> {
            return fetchJSON<GetPointInfoModelSuccess>(`/internal/Basket/GetPointInfoModel`)
        }
    
        
     
    
        

        

        export type DonationModelSuccess = paths['/internal/ModelOnly/DonationModel']['get']['responses']['200']['content']['text/plain'];

        export const DonationModelUrl = '/internal/ModelOnly/DonationModel';

        
        export function DonationModel(): Promise<DonationModelSuccess> {
            return fetchJSON<DonationModelSuccess>(`/internal/ModelOnly/DonationModel`)
        }
    
        
     
    
        

        

        export type BasketLineSubscriptionNudgeSuccess = paths['/internal/ModelOnly/BasketLineSubscriptionNudge']['get']['responses']['200']['content']['text/plain'];

        export const BasketLineSubscriptionNudgeUrl = '/internal/ModelOnly/BasketLineSubscriptionNudge';

        
        export function BasketLineSubscriptionNudge(): Promise<BasketLineSubscriptionNudgeSuccess> {
            return fetchJSON<BasketLineSubscriptionNudgeSuccess>(`/internal/ModelOnly/BasketLineSubscriptionNudge`)
        }
    
        
     
    
        

        

        export type BasketMessageDiscountViewModelSuccess = paths['/internal/ModelOnly/BasketMessageDiscountViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BasketMessageDiscountViewModelUrl = '/internal/ModelOnly/BasketMessageDiscountViewModel';

        
        export function BasketMessageDiscountViewModel(): Promise<BasketMessageDiscountViewModelSuccess> {
            return fetchJSON<BasketMessageDiscountViewModelSuccess>(`/internal/ModelOnly/BasketMessageDiscountViewModel`)
        }
    
        
     
    
        

        

        export type BasketShippingSupplierOptionSuccess = paths['/internal/ModelOnly/BasketShippingSupplierOption']['get']['responses']['200']['content']['text/plain'];

        export const BasketShippingSupplierOptionUrl = '/internal/ModelOnly/BasketShippingSupplierOption';

        
        export function BasketShippingSupplierOption(): Promise<BasketShippingSupplierOptionSuccess> {
            return fetchJSON<BasketShippingSupplierOptionSuccess>(`/internal/ModelOnly/BasketShippingSupplierOption`)
        }
    
        
     
    
        

        

        export type ShippingSummaryModelSuccess = paths['/internal/ModelOnly/ShippingSummaryModel']['get']['responses']['200']['content']['text/plain'];

        export const ShippingSummaryModelUrl = '/internal/ModelOnly/ShippingSummaryModel';

        
        export function ShippingSummaryModel(): Promise<ShippingSummaryModelSuccess> {
            return fetchJSON<ShippingSummaryModelSuccess>(`/internal/ModelOnly/ShippingSummaryModel`)
        }
    
        
     
    
    