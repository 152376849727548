import type React from 'react';
import { forwardRef } from 'react';
import styled, { css, type RuleSet } from 'styled-components';
import { colors } from 'variables';
import { rem, em } from 'modules/helpers/style';
import {
    BorderRadiusMedium,
    ColorBrandBlue1,
    ColorNordicGrey4,
    ColorNeutralWhite,
    ColorClubMatasPlus1
} from 'autogen/design-tokens/tokens';
import type { ILinkButtonProps } from 'modules/react-button/linkButton';
import type { IIconButtonProps } from 'modules/react-button/withIcon';
import { ButtonStyle, type ButtonSize } from './enums';
import UAEventButton, { type IUaEventButton } from './uaEventButton';

export * from './enums';

export interface IButtonProps {
    buttonSize?: ButtonSize;
    buttonStyle?: ButtonStyle;
    fontWeightNormal?: true;
}

export interface IDefaultButtonProps extends IButtonProps, IUaEventButton {}

const fontsSize = { sm: 12, md: 14, lg: 16 };
const minHeight = { sm: 32, md: 40, lg: 48 };
const padding = {
    sm: { top: 8, sides: 10, bottom: 8 },
    md: { top: 13, sides: 15, bottom: 12 },
    lg: { top: 16, sides: 15, bottom: 16 }
};

const buttonFontSize = {
    sm: rem(fontsSize.sm),
    md: rem(fontsSize.md),
    lg: rem(fontsSize.lg)
};

const buttonHeight = {
    sm: rem(minHeight.sm),
    md: rem(minHeight.md),
    lg: rem(minHeight.lg)
};

const buttonPadding = {
    sm: `${rem(padding.sm.top, fontsSize.sm)} ${rem(padding.sm.sides, fontsSize.sm)}`,
    md: `${rem(padding.md.top, fontsSize.md)} ${rem(padding.md.sides, fontsSize.md)} ${rem(
        padding.md.bottom,
        fontsSize.md
    )}`,
    lg: `${rem(padding.lg.top, fontsSize.lg)} ${rem(padding.lg.sides, fontsSize.lg)}`
};

const buttonPaddingWithIcon = {
    sm: `${rem(7, fontsSize.sm)} ${rem(10, fontsSize.sm)}`,
    md: `${rem(12, fontsSize.md)} ${rem(15, fontsSize.md)} ${rem(11, fontsSize.md)}`,
    lg: `${rem(15, fontsSize.lg)} ${rem(15, fontsSize.lg)}`
};

const backgroundColor = {
    primary: ColorBrandBlue1,
    secondary: ColorNordicGrey4,
    secondaryNegative: ColorNeutralWhite,
    septenary: colors.color6,
    ghost: colors.negative,
    ghostNegative: colors.transparent,
    clubmatas: colors.clubmatas1,
    live: colors.liveButton,
    disabled: colors.transparent,
    matasPlus: ColorClubMatasPlus1,
    brandNegative: colors.negative,
    transparent: 'transparent'
};

const fontColor = {
    primary: colors.negative,
    secondary: colors.positive,
    secondaryNegative: colors.positive,
    septenary: colors.negative,
    ghost: colors.positive,
    ghostNegative: colors.negative,
    clubmatas: colors.negative,
    live: colors.clubmatasFont,
    disabled: colors.currentColor,
    matasPlus: colors.negative,
    brandNegative: colors.brand1,
    transparent: colors.positive
};

const borderColor = {
    primary: ColorBrandBlue1,
    secondary: ColorNordicGrey4,
    secondaryNegative: ColorNeutralWhite,
    septenary: colors.color6,
    ghost: colors.borderQuinary,
    ghostNegative: colors.negative,
    clubmatas: colors.clubmatas1,
    live: colors.liveButton,
    disabled: colors.currentColor,
    matasPlus: colors.matasPlus,
    brandNegative: colors.borderSenary,
    transparent: 'transparent'
};

const defaultSize = 'md';
const defaultStyle = 'ghost';

const additionalDisabledStyling = css`
    &:hover,
    &:focus {
        color: ${fontColor.disabled};
        border-color: ${borderColor.disabled};
    }
`;

export const buttonStyling = css<IDefaultButtonProps & IIconButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: ${({ buttonSize }): string => buttonHeight[buttonSize] || buttonHeight[defaultSize]};
    font-size: ${({ buttonSize }): string => buttonFontSize[buttonSize] || buttonFontSize[defaultSize]};
    border: ${({ buttonStyle }) =>
        buttonStyle === ButtonStyle.ghost
            ? css`
                  ${em(1)} solid ${borderColor[buttonStyle] || borderColor[defaultStyle]}
              `
            : 0};
    border-radius: ${BorderRadiusMedium};
    background-color: ${({ buttonStyle }): string => backgroundColor[buttonStyle] || backgroundColor[defaultStyle]};
    color: ${({ buttonStyle }): string => fontColor[buttonStyle] || fontColor[defaultStyle]};
    padding: ${({ buttonSize, icon }): string =>
        (icon ? buttonPaddingWithIcon[buttonSize] : buttonPadding[buttonSize]) ||
        (icon ? buttonPaddingWithIcon[defaultSize] : buttonPadding[defaultSize])};
    ${({ fontWeightNormal }): string => !fontWeightNormal && 'font-weight: 700;'}
    line-height: 1em;

    ${({ buttonStyle, disabled }): RuleSet =>
        (disabled || buttonStyle === ButtonStyle.disabled) && additionalDisabledStyling};

    &:hover,
    &:active,
    &:focus {
        color: ${({ buttonStyle }): string => fontColor[buttonStyle] || fontColor[defaultStyle]};
    }
`;

export const linkButtonStyling = css<ILinkButtonProps>`
    border: 0;
    word-break: break-word;
    background: none;
    text-decoration: underline;
    font-size: ${({ buttonSize }): string => buttonFontSize[buttonSize] || buttonFontSize[defaultSize]};

    &:focus,
    &:hover {
        outline: none;
    }
`;

const Button = styled(UAEventButton)<IDefaultButtonProps>`
    ${({ buttonStyle }): RuleSet<any> => {
        switch (buttonStyle) {
            case ButtonStyle.link:
                return css`
                    ${linkButtonStyling}
                `;
            default:
                return css`
                    ${buttonStyling}
                `;
        }
    }}
`;

export const ReactButton = forwardRef(
    (props: IDefaultButtonProps | IIconButtonProps, ref: React.Ref<HTMLButtonElement>): JSX.Element => {
        const { children, ...restProps } = props;

        return (
            <Button ref={ref} {...restProps}>
                {children}
            </Button>
        );
    }
);

export default ReactButton;
export const buttonStylingValues = { fontsSize, minHeight, padding };
