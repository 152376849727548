import React, { useContext } from 'react';
import styled from 'styled-components';
import Spinner from 'modules/spinners';
import MainContext from 'components/QuickSearch/components/context';
import LayoutDefault, { LayoutTabs, LayoutColumns, LayoutNoResults } from '../layouts/dialog';
import { layoutEnum } from '../enums';

const LoadingSpinner = styled(Spinner)`
    margin: 20px auto 30px;
    transform: scale(1.2);
`;

export default props => {
    const mainContext = useContext(MainContext);
    const { layout } = mainContext;
    const {
        searchResult,
        loadingSpinnerActive,
        activeTab,
        setActiveTabCallback,
        sectionCoordsCallback,
        dialogTouchCallback
    } = props;

    if (loadingSpinnerActive || (!searchResult && layout !== layoutEnum.noResult)) {
        return <LoadingSpinner color="#000" />;
    }

    if (layout === layoutEnum.noResult) {
        return <LayoutNoResults />;
    }

    const { articles, brands, featuredBrands, brandFacets, products, suggestions, latestSearchQueries, nextQueries } =
        searchResult;

    switch (layout) {
        case layoutEnum.row:
            return (
                <LayoutDefault
                    suggestions={suggestions}
                    latestSearchQueries={latestSearchQueries}
                    dialogTouchCallback={dialogTouchCallback}
                    nextQueries={nextQueries}
                />
            );
        case layoutEnum.column:
            return (
                <LayoutColumns
                    articles={articles}
                    featuredBrands={featuredBrands}
                    products={products}
                    suggestions={suggestions}
                    sectionCoordsCallback={sectionCoordsCallback}
                    nextQueries={nextQueries}
                />
            );
        case layoutEnum.tab:
            return (
                <LayoutTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTabCallback}
                    articles={articles}
                    brands={brands}
                    featuredBrands={featuredBrands}
                    brandFacets={brandFacets}
                    products={products}
                    suggestions={suggestions}
                    dialogTouchCallback={dialogTouchCallback}
                    nextQueries={nextQueries}
                />
            );
        default:
            // row layout - fallback
            return <LayoutNoResults />;
    }
};
