import React from 'react';
import styled, { css } from 'styled-components';
import { ColorAdvice2, Spacing12, Spacing16, Spacing6, Spacing8 } from 'autogen/design-tokens/tokens';
import getTypeStyling from 'helpers/getTypeStyling';
import { CardSize, ICardPartial } from '../shared/types';

const CardHeaderSizes = {
    xs: css`
        padding: ${Spacing6} ${Spacing8};
    `,
    sm: css`
        padding: ${Spacing6} ${Spacing12};
    `,
    md: css`
        padding: ${Spacing8} ${Spacing16};
    `,
    lg: css`
        padding: ${Spacing12} ${Spacing16};
    `,
    xl: css`
        padding: ${Spacing16};
    `
};

const StyledCardHeader = styled.div<{ size?: ICardPartial['size'] }>`
    display: flex;
    justify-content: center;
    background-color: ${ColorAdvice2};

    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: CardSize) => CardHeaderSizes[resolvedSize])(size as CardSize)
            : getTypeStyling((resolvedSize: CardSize) => CardHeaderSizes[resolvedSize])(theme.size)}
`;

export default function CardHeader({ children, ...restProps }: ICardPartial) {
    return <StyledCardHeader {...restProps}>{children}</StyledCardHeader>;
}
