
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './myprofile';

        export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type PlaceholderLinkType = components['schemas']['PlaceholderLinkType'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type LinkKey = components['schemas']['LinkKey'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type LinkElementModel = components['schemas']['LinkElementModel'];
                    export type LinkElementModelMaybe = components['schemas']['LinkElementModelMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type PageViewModel = components['schemas']['PageViewModel'];
                    export type PageViewModelMaybe = components['schemas']['PageViewModelMaybe'];
                    export type PagingViewModel = components['schemas']['PagingViewModel'];
                    export type MatasPlusCalculatorBannerSize = components['schemas']['MatasPlusCalculatorBannerSize'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type TextHyperlinkMaybe = components['schemas']['TextHyperlinkMaybe'];
                    export type SavedModel = components['schemas']['SavedModel'];
                    export type SignupModel = components['schemas']['SignupModel'];
                    export type DetailsModel = components['schemas']['DetailsModel'];
                    export type DetailsToggleModel = components['schemas']['DetailsToggleModel'];
                    export type ReadMoreModel = components['schemas']['ReadMoreModel'];
                    export type ReadMoreModelMaybe = components['schemas']['ReadMoreModelMaybe'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type MatasPlusCalculatorBannerModel = components['schemas']['MatasPlusCalculatorBannerModel'];
                    export type MatasPlusSavingsValueType = components['schemas']['MatasPlusSavingsValueType'];
                    export type MatasPlusSavingsValueItem = components['schemas']['MatasPlusSavingsValueItem'];
                    export type MatasPlusSavingsBannerModel = components['schemas']['MatasPlusSavingsBannerModel'];
                    export type HyperlinkMaybe = components['schemas']['HyperlinkMaybe'];
                    export type ResponsiveImageLinkModel = components['schemas']['ResponsiveImageLinkModel'];
                    export type BannerModel = components['schemas']['BannerModel'];
                    export type CardListItemBase = components['schemas']['CardListItemBase'];
                    export type DummyCardModel = components['schemas']['DummyCardModel'];
                    export type ActiveBrandClubListModel = components['schemas']['ActiveBrandClubListModel'];
                    export type BrandClubListModel = components['schemas']['BrandClubListModel'];
                    export type FavoritesModel = components['schemas']['FavoritesModel'];
                    export type PointStatsModel = components['schemas']['PointStatsModel'];
                    export type MemberBenefitsModel = components['schemas']['MemberBenefitsModel'];
                    export type MatasPlusSavingsBannerModelMaybe = components['schemas']['MatasPlusSavingsBannerModelMaybe'];
                    export type HeaderModel = components['schemas']['HeaderModel'];
                    export type ImageModel = components['schemas']['ImageModel'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type MyProfileOrderPartModel = components['schemas']['MyProfileOrderPartModel'];
                    export type MyProfileOrderModel = components['schemas']['MyProfileOrderModel'];
                    export type OrdersModel = components['schemas']['OrdersModel'];
                    export type PersonalOfferModel = components['schemas']['PersonalOfferModel'];
                    export type PersonalOffersModel = components['schemas']['PersonalOffersModel'];
                    export type ProductListModel = components['schemas']['ProductListModel'];
                    export type RecommendationsModel = components['schemas']['RecommendationsModel'];
                    export type ProductReviewRatingValueModel = components['schemas']['ProductReviewRatingValueModel'];
                    export type ProductReviewRatingSelectModel = components['schemas']['ProductReviewRatingSelectModel'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameModel = components['schemas']['ProductNameModel'];
                    export type VideoPlatform = components['schemas']['VideoPlatform'];
                    export type ProductVideoType = components['schemas']['ProductVideoType'];
                    export type IProductVideo = components['schemas']['IProductVideo'];
                    export type ProductVideoModel = components['schemas']['ProductVideoModel'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ISupplier = components['schemas']['ISupplier'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type IPrimaryProductGroupInfo = components['schemas']['IPrimaryProductGroupInfo'];
                    export type IPrimaryProductGroupInfoMaybe = components['schemas']['IPrimaryProductGroupInfoMaybe'];
                    export type ListDisplayType = components['schemas']['ListDisplayType'];
                    export type IMedium = components['schemas']['IMedium'];
                    export type IMediumMaybe = components['schemas']['IMediumMaybe'];
                    export type IBrandSimple = components['schemas']['IBrandSimple'];
                    export type DeliveryMinMax = components['schemas']['DeliveryMinMax'];
                    export type ISystemProductStock = components['schemas']['ISystemProductStock'];
                    export type SynonymGroup = components['schemas']['SynonymGroup'];
                    export type ISynonym = components['schemas']['ISynonym'];
                    export type ISerieSimple = components['schemas']['ISerieSimple'];
                    export type ISerieSimpleMaybe = components['schemas']['ISerieSimpleMaybe'];
                    export type MetaRobotSetting = components['schemas']['MetaRobotSetting'];
                    export type IPageMetadata = components['schemas']['IPageMetadata'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type ProductNameRenderer = components['schemas']['ProductNameRenderer'];
                    export type ProductType = components['schemas']['ProductType'];
                    export type ProductBundleType = components['schemas']['ProductBundleType'];
                    export type DigitalProductType = components['schemas']['DigitalProductType'];
                    export type PriceLabelVisibilityFlags = components['schemas']['PriceLabelVisibilityFlags'];
                    export type IPriceLabel = components['schemas']['IPriceLabel'];
                    export type IPriceLabelMaybe = components['schemas']['IPriceLabelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type IProgressiveDiscountStep = components['schemas']['IProgressiveDiscountStep'];
                    export type IProgressiveDiscountPriceInfo = components['schemas']['IProgressiveDiscountPriceInfo'];
                    export type IProgressiveDiscountPriceInfoMaybe = components['schemas']['IProgressiveDiscountPriceInfoMaybe'];
                    export type IPriceInfo = components['schemas']['IPriceInfo'];
                    export type IDiscountInfo = components['schemas']['IDiscountInfo'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type ISubscriptionInfo = components['schemas']['ISubscriptionInfo'];
                    export type ISubscriptionInfoMaybe = components['schemas']['ISubscriptionInfoMaybe'];
                    export type IUnitInfo = components['schemas']['IUnitInfo'];
                    export type ReviewRating = components['schemas']['ReviewRating'];
                    export type IProductReviewRatingStatistic = components['schemas']['IProductReviewRatingStatistic'];
                    export type IProductReviewStatistics = components['schemas']['IProductReviewStatistics'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type IOtcInfo = components['schemas']['IOtcInfo'];
                    export type IProductVariant = components['schemas']['IProductVariant'];
                    export type ProductActions = components['schemas']['ProductActions'];
                    export type ProductShopStatus = components['schemas']['ProductShopStatus'];
                    export type GwpOfferType = components['schemas']['GwpOfferType'];
                    export type IGwpOffer = components['schemas']['IGwpOffer'];
                    export type IGwpOfferMaybe = components['schemas']['IGwpOfferMaybe'];
                    export type IAttributeString = components['schemas']['IAttributeString'];
                    export type Dimensions = components['schemas']['Dimensions'];
                    export type IProductPhysicalProperties = components['schemas']['IProductPhysicalProperties'];
                    export type HazardSymbolTypes = components['schemas']['HazardSymbolTypes'];
                    export type SampleType = components['schemas']['SampleType'];
                    export type ISampleInfo = components['schemas']['ISampleInfo'];
                    export type ISampleInfoMaybe = components['schemas']['ISampleInfoMaybe'];
                    export type ProductHighlightType = components['schemas']['ProductHighlightType'];
                    export type IProductHighlight = components['schemas']['IProductHighlight'];
                    export type IProductKeyIngredient = components['schemas']['IProductKeyIngredient'];
                    export type IProductTip = components['schemas']['IProductTip'];
                    export type IProductTipMaybe = components['schemas']['IProductTipMaybe'];
                    export type ProductFragranceType = components['schemas']['ProductFragranceType'];
                    export type IProductFragrance = components['schemas']['IProductFragrance'];
                    export type ProductDoseType = components['schemas']['ProductDoseType'];
                    export type QuantityInterval = components['schemas']['QuantityInterval'];
                    export type IProductDose = components['schemas']['IProductDose'];
                    export type ProductMicronutrientType = components['schemas']['ProductMicronutrientType'];
                    export type IProductMicronutrient = components['schemas']['IProductMicronutrient'];
                    export type IProductNutrient = components['schemas']['IProductNutrient'];
                    export type IProductNutrientMaybe = components['schemas']['IProductNutrientMaybe'];
                    export type BlacklistFlags = components['schemas']['BlacklistFlags'];
                    export type MediaType = components['schemas']['MediaType'];
                    export type IProductCustomMediaContent = components['schemas']['IProductCustomMediaContent'];
                    export type IProductMakeupInfo = components['schemas']['IProductMakeupInfo'];
                    export type IProductDetailed = components['schemas']['IProductDetailed'];
                    export type ReviewRequestModel = components['schemas']['ReviewRequestModel'];
                    export type ReviewRequestsModel = components['schemas']['ReviewRequestsModel'];
                    export type SubscriptionsModel = components['schemas']['SubscriptionsModel'];
                    export type MatasPlusUnboxingBoxType = components['schemas']['MatasPlusUnboxingBoxType'];
                    export type ActiveTimeSpan = components['schemas']['ActiveTimeSpan'];
                    export type MatasPlusUnboxingOfferModel = components['schemas']['MatasPlusUnboxingOfferModel'];
                    export type UnboxingOffersModel = components['schemas']['UnboxingOffersModel'];
                    export type MatasPlusNudgeMessage = components['schemas']['MatasPlusNudgeMessage'];
                    export type FrontPageSectionIcon = components['schemas']['FrontPageSectionIcon'];
                    export type FrontPageSectionHeader = components['schemas']['FrontPageSectionHeader'];
                    export type FrontPageSectionHeaderMaybe = components['schemas']['FrontPageSectionHeaderMaybe'];
                    export type FrontPageSection = components['schemas']['FrontPageSection'];
                    export type FrontPageSectionMaybe = components['schemas']['FrontPageSectionMaybe'];
                    export type ResponsiveImageLinkModelMaybe = components['schemas']['ResponsiveImageLinkModelMaybe'];
                    export type FavoriteViewModel = components['schemas']['FavoriteViewModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type MyProfileSettingPageViewModel = components['schemas']['MyProfileSettingPageViewModel'];
                    export type LocalizedPageUrlModel = components['schemas']['LocalizedPageUrlModel'];
                    export type PageMetadataModel = components['schemas']['PageMetadataModel'];
                    export type MyProfileHeaderViewModel = components['schemas']['MyProfileHeaderViewModel'];
                    export type MyProfileHeaderViewModelMaybe = components['schemas']['MyProfileHeaderViewModelMaybe'];
                    export type MenuStyling = components['schemas']['MenuStyling'];
                    export type MenuSectionHeadlineStyling = components['schemas']['MenuSectionHeadlineStyling'];
                    export type MenuSectionStyling = components['schemas']['MenuSectionStyling'];
                    export type MenuItemStyling = components['schemas']['MenuItemStyling'];
                    export type FrontendCustomMenuAction = components['schemas']['FrontendCustomMenuAction'];
                    export type MenuItemCustomActionViewModel = components['schemas']['MenuItemCustomActionViewModel'];
                    export type MenuItemCustomActionWithUrlViewModel = components['schemas']['MenuItemCustomActionWithUrlViewModel'];
                    export type MenuItemLinkViewModel = components['schemas']['MenuItemLinkViewModel'];
                    export type MenuItemLoginOrMyProfileViewModel = components['schemas']['MenuItemLoginOrMyProfileViewModel'];
                    export type MyProfileMenuItemSubTextViewModel = components['schemas']['MyProfileMenuItemSubTextViewModel'];
                    export type MyProfileMenuItemSubTextViewModelMaybe = components['schemas']['MyProfileMenuItemSubTextViewModelMaybe'];
                    export type LoginLevel = components['schemas']['LoginLevel'];
                    export type MenuItemMyProfileViewModel = components['schemas']['MenuItemMyProfileViewModel'];
                    export type MenuItemSubMenuViewModel = components['schemas']['MenuItemSubMenuViewModel'];
                    export type MenuItemViewModel = components['schemas']['MenuItemViewModel'];
                    export type MenuSectionViewModel = components['schemas']['MenuSectionViewModel'];
                    export type MenuHeaderViewModel = components['schemas']['MenuHeaderViewModel'];
                    export type MenuViewModel = components['schemas']['MenuViewModel'];
                    export type PageStyleType = components['schemas']['PageStyleType'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessage = components['schemas']['UserMessage'];
                    export type UserMessageMaybe = components['schemas']['UserMessageMaybe'];
                    export type MyProfileLayoutViewModel = components['schemas']['MyProfileLayoutViewModel'];
                    export type MatasPlusSubscriptionType = components['schemas']['MatasPlusSubscriptionType'];
                    export type MatasPlusChangeSubscriptionTypeModal = components['schemas']['MatasPlusChangeSubscriptionTypeModal'];
                    export type MatasPlusChangeSubscriptionTypeModalMaybe = components['schemas']['MatasPlusChangeSubscriptionTypeModalMaybe'];
                    export type MatasPlusNudgeMessageMaybe = components['schemas']['MatasPlusNudgeMessageMaybe'];
                    export type MatasPlusResubscribeNudgeMessage = components['schemas']['MatasPlusResubscribeNudgeMessage'];
                    export type MatasPlusResubscribeNudgeMessageMaybe = components['schemas']['MatasPlusResubscribeNudgeMessageMaybe'];
                    export type MyProfileFrontPageViewModel = components['schemas']['MyProfileFrontPageViewModel'];
                    

        export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function ListItemitemDiscriminator<T>(
    model: components['schemas']['ListItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}export function ListRowItemitemDiscriminator<T>(
    model: components['schemas']['ListRowItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}export function ReviewRequestModelprimaryImageDiscriminator<T>(
    model: components['schemas']['ReviewRequestModel']['primaryImage'],
    funcProductVideoModel: (m: components['schemas']['ProductVideoModel']) => T,
    funcProductImage: (m: components['schemas']['ProductImage']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ProductVideoModel': return funcProductVideoModel(model as components['schemas']['ProductVideoModel']);
        case 'ProductImage': return funcProductImage(model as components['schemas']['ProductImage']);

        default:
            return defaultFunc();
    }
}export function FrontPageSectioncontentDiscriminator<T>(
    model: components['schemas']['FrontPageSection']['content'],
    funcMatasPlusCalculatorBannerModel: (m: components['schemas']['MatasPlusCalculatorBannerModel']) => T,
    funcMatasPlusSavingsBannerModel: (m: components['schemas']['MatasPlusSavingsBannerModel']) => T,
    funcBannerModel: (m: components['schemas']['BannerModel']) => T,
    funcBrandClubListModel: (m: components['schemas']['BrandClubListModel']) => T,
    funcFavoritesModel: (m: components['schemas']['FavoritesModel']) => T,
    funcHeaderModel: (m: components['schemas']['HeaderModel']) => T,
    funcImageModel: (m: components['schemas']['ImageModel']) => T,
    funcOrdersModel: (m: components['schemas']['OrdersModel']) => T,
    funcPersonalOffersModel: (m: components['schemas']['PersonalOffersModel']) => T,
    funcProductListModel: (m: components['schemas']['ProductListModel']) => T,
    funcRecommendationsModel: (m: components['schemas']['RecommendationsModel']) => T,
    funcReviewRequestsModel: (m: components['schemas']['ReviewRequestsModel']) => T,
    funcSubscriptionsModel: (m: components['schemas']['SubscriptionsModel']) => T,
    funcUnboxingOffersModel: (m: components['schemas']['UnboxingOffersModel']) => T,
    funcMatasPlusNudgeMessage: (m: components['schemas']['MatasPlusNudgeMessage']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MatasPlusCalculatorBannerModel': return funcMatasPlusCalculatorBannerModel(model as components['schemas']['MatasPlusCalculatorBannerModel']);
        case 'MatasPlusSavingsBannerModel': return funcMatasPlusSavingsBannerModel(model as components['schemas']['MatasPlusSavingsBannerModel']);
        case 'BannerModel': return funcBannerModel(model as components['schemas']['BannerModel']);
        case 'BrandClubListModel': return funcBrandClubListModel(model as components['schemas']['BrandClubListModel']);
        case 'FavoritesModel': return funcFavoritesModel(model as components['schemas']['FavoritesModel']);
        case 'HeaderModel': return funcHeaderModel(model as components['schemas']['HeaderModel']);
        case 'ImageModel': return funcImageModel(model as components['schemas']['ImageModel']);
        case 'OrdersModel': return funcOrdersModel(model as components['schemas']['OrdersModel']);
        case 'PersonalOffersModel': return funcPersonalOffersModel(model as components['schemas']['PersonalOffersModel']);
        case 'ProductListModel': return funcProductListModel(model as components['schemas']['ProductListModel']);
        case 'RecommendationsModel': return funcRecommendationsModel(model as components['schemas']['RecommendationsModel']);
        case 'ReviewRequestsModel': return funcReviewRequestsModel(model as components['schemas']['ReviewRequestsModel']);
        case 'SubscriptionsModel': return funcSubscriptionsModel(model as components['schemas']['SubscriptionsModel']);
        case 'UnboxingOffersModel': return funcUnboxingOffersModel(model as components['schemas']['UnboxingOffersModel']);
        case 'MatasPlusNudgeMessage': return funcMatasPlusNudgeMessage(model as components['schemas']['MatasPlusNudgeMessage']);

        default:
            return defaultFunc();
    }
}export function FrontPageSectionMaybecontentDiscriminator<T>(
    model: components['schemas']['FrontPageSectionMaybe']['content'],
    funcMatasPlusCalculatorBannerModel: (m: components['schemas']['MatasPlusCalculatorBannerModel']) => T,
    funcMatasPlusSavingsBannerModel: (m: components['schemas']['MatasPlusSavingsBannerModel']) => T,
    funcBannerModel: (m: components['schemas']['BannerModel']) => T,
    funcBrandClubListModel: (m: components['schemas']['BrandClubListModel']) => T,
    funcFavoritesModel: (m: components['schemas']['FavoritesModel']) => T,
    funcHeaderModel: (m: components['schemas']['HeaderModel']) => T,
    funcImageModel: (m: components['schemas']['ImageModel']) => T,
    funcOrdersModel: (m: components['schemas']['OrdersModel']) => T,
    funcPersonalOffersModel: (m: components['schemas']['PersonalOffersModel']) => T,
    funcProductListModel: (m: components['schemas']['ProductListModel']) => T,
    funcRecommendationsModel: (m: components['schemas']['RecommendationsModel']) => T,
    funcReviewRequestsModel: (m: components['schemas']['ReviewRequestsModel']) => T,
    funcSubscriptionsModel: (m: components['schemas']['SubscriptionsModel']) => T,
    funcUnboxingOffersModel: (m: components['schemas']['UnboxingOffersModel']) => T,
    funcMatasPlusNudgeMessage: (m: components['schemas']['MatasPlusNudgeMessage']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MatasPlusCalculatorBannerModel': return funcMatasPlusCalculatorBannerModel(model as components['schemas']['MatasPlusCalculatorBannerModel']);
        case 'MatasPlusSavingsBannerModel': return funcMatasPlusSavingsBannerModel(model as components['schemas']['MatasPlusSavingsBannerModel']);
        case 'BannerModel': return funcBannerModel(model as components['schemas']['BannerModel']);
        case 'BrandClubListModel': return funcBrandClubListModel(model as components['schemas']['BrandClubListModel']);
        case 'FavoritesModel': return funcFavoritesModel(model as components['schemas']['FavoritesModel']);
        case 'HeaderModel': return funcHeaderModel(model as components['schemas']['HeaderModel']);
        case 'ImageModel': return funcImageModel(model as components['schemas']['ImageModel']);
        case 'OrdersModel': return funcOrdersModel(model as components['schemas']['OrdersModel']);
        case 'PersonalOffersModel': return funcPersonalOffersModel(model as components['schemas']['PersonalOffersModel']);
        case 'ProductListModel': return funcProductListModel(model as components['schemas']['ProductListModel']);
        case 'RecommendationsModel': return funcRecommendationsModel(model as components['schemas']['RecommendationsModel']);
        case 'ReviewRequestsModel': return funcReviewRequestsModel(model as components['schemas']['ReviewRequestsModel']);
        case 'SubscriptionsModel': return funcSubscriptionsModel(model as components['schemas']['SubscriptionsModel']);
        case 'UnboxingOffersModel': return funcUnboxingOffersModel(model as components['schemas']['UnboxingOffersModel']);
        case 'MatasPlusNudgeMessage': return funcMatasPlusNudgeMessage(model as components['schemas']['MatasPlusNudgeMessage']);

        default:
            return defaultFunc();
    }
}export function FavoriteViewModelitemsDiscriminator<T>(
    model: components['schemas']['FavoriteViewModel']['items'][0],
    funcListItem: (m: components['schemas']['ListItem']) => T,
    funcListRowItem: (m: components['schemas']['ListRowItem']) => T,
    funcProductListItemModel: (m: components['schemas']['ProductListItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ListItem': return funcListItem(model as components['schemas']['ListItem']);
        case 'ListRowItem': return funcListRowItem(model as components['schemas']['ListRowItem']);
        case 'ProductListItemModel': return funcProductListItemModel(model as components['schemas']['ProductListItemModel']);

        default:
            return defaultFunc();
    }
}export function BasketInfoBoxbuttonsDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}export function MyProfileSettingPageViewModelinfoBoxesDiscriminator<T>(
    model: components['schemas']['MyProfileSettingPageViewModel']['infoBoxes'][0],
    funcCheckoutGiftCardInfo: (m: components['schemas']['CheckoutGiftCardInfo']) => T,
    funcCheckoutPaymentTypeList: (m: components['schemas']['CheckoutPaymentTypeList']) => T,
    funcBasketInfoBox: (m: components['schemas']['BasketInfoBox']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutGiftCardInfo': return funcCheckoutGiftCardInfo(model as components['schemas']['CheckoutGiftCardInfo']);
        case 'CheckoutPaymentTypeList': return funcCheckoutPaymentTypeList(model as components['schemas']['CheckoutPaymentTypeList']);
        case 'BasketInfoBox': return funcBasketInfoBox(model as components['schemas']['BasketInfoBox']);

        default:
            return defaultFunc();
    }
}export function MenuItemViewModelcontentDiscriminator<T>(
    model: components['schemas']['MenuItemViewModel']['content'],
    funcMenuItemCustomActionViewModel: (m: components['schemas']['MenuItemCustomActionViewModel']) => T,
    funcMenuItemCustomActionWithUrlViewModel: (m: components['schemas']['MenuItemCustomActionWithUrlViewModel']) => T,
    funcMenuItemLinkViewModel: (m: components['schemas']['MenuItemLinkViewModel']) => T,
    funcMenuItemLoginOrMyProfileViewModel: (m: components['schemas']['MenuItemLoginOrMyProfileViewModel']) => T,
    funcMenuItemMyProfileViewModel: (m: components['schemas']['MenuItemMyProfileViewModel']) => T,
    funcMenuItemSubMenuViewModel: (m: components['schemas']['MenuItemSubMenuViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MenuItemCustomActionViewModel': return funcMenuItemCustomActionViewModel(model as components['schemas']['MenuItemCustomActionViewModel']);
        case 'MenuItemCustomActionWithUrlViewModel': return funcMenuItemCustomActionWithUrlViewModel(model as components['schemas']['MenuItemCustomActionWithUrlViewModel']);
        case 'MenuItemLinkViewModel': return funcMenuItemLinkViewModel(model as components['schemas']['MenuItemLinkViewModel']);
        case 'MenuItemLoginOrMyProfileViewModel': return funcMenuItemLoginOrMyProfileViewModel(model as components['schemas']['MenuItemLoginOrMyProfileViewModel']);
        case 'MenuItemMyProfileViewModel': return funcMenuItemMyProfileViewModel(model as components['schemas']['MenuItemMyProfileViewModel']);
        case 'MenuItemSubMenuViewModel': return funcMenuItemSubMenuViewModel(model as components['schemas']['MenuItemSubMenuViewModel']);

        default:
            return defaultFunc();
    }
}export function MenuSectionViewModelitemsDiscriminator<T>(
    model: components['schemas']['MenuSectionViewModel']['items'][0],
    funcMenuItemViewModel: (m: components['schemas']['MenuItemViewModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'MenuItemViewModel': return funcMenuItemViewModel(model as components['schemas']['MenuItemViewModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);

        default:
            return defaultFunc();
    }
}

        
        

        export type _NewsParams = paths['/internal/myprofile/BrandClub/_News']['get']['parameters']['query'];

        export type _NewsSuccess = paths['/internal/myprofile/BrandClub/_News']['get']['responses']['200']['content']['text/plain'];

        export const _NewsUrl = '/internal/myprofile/BrandClub/_News';

        
        export function _News(params: _NewsParams): Promise<_NewsSuccess> {
            return fetchJSON<_NewsSuccess>(`/internal/myprofile/BrandClub/_News${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetParams = paths['/internal/Favorite/Get']['get']['parameters']['query'];

        export type GetSuccess = paths['/internal/Favorite/Get']['get']['responses']['200']['content']['text/plain'];

        export const GetUrl = '/internal/Favorite/Get';

        
        export function Get(params: GetParams): Promise<GetSuccess> {
            return fetchJSON<GetSuccess>(`/internal/Favorite/Get${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type MyProfileSettingPageViewModelSuccess = paths['/internal/ModelOnly/MyProfileSettingPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileSettingPageViewModelUrl = '/internal/ModelOnly/MyProfileSettingPageViewModel';

        
        export function MyProfileSettingPageViewModel(): Promise<MyProfileSettingPageViewModelSuccess> {
            return fetchJSON<MyProfileSettingPageViewModelSuccess>(`/internal/ModelOnly/MyProfileSettingPageViewModel`)
        }
    
        
     
    
        

        

        export type HeaderSuccess = paths['/internal/myprofile/MyProfile/Header']['get']['responses']['200']['content']['text/plain'];

        export const HeaderUrl = '/internal/myprofile/MyProfile/Header';

        
        export function Header(): Promise<HeaderSuccess> {
            return fetchJSON<HeaderSuccess>(`/internal/myprofile/MyProfile/Header`)
        }
    
        
     
    
        

        

        export type MyProfileLayoutViewModelSuccess = paths['/internal/myprofile/MyProfile/MyProfileLayoutViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileLayoutViewModelUrl = '/internal/myprofile/MyProfile/MyProfileLayoutViewModel';

        
        export function MyProfileLayoutViewModel(): Promise<MyProfileLayoutViewModelSuccess> {
            return fetchJSON<MyProfileLayoutViewModelSuccess>(`/internal/myprofile/MyProfile/MyProfileLayoutViewModel`)
        }
    
        
     
    
        

        

        export type MyProfileFrontPageViewModelSuccess = paths['/internal/myprofile/MyProfile/MyProfileFrontPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileFrontPageViewModelUrl = '/internal/myprofile/MyProfile/MyProfileFrontPageViewModel';

        
        export function MyProfileFrontPageViewModel(): Promise<MyProfileFrontPageViewModelSuccess> {
            return fetchJSON<MyProfileFrontPageViewModelSuccess>(`/internal/myprofile/MyProfile/MyProfileFrontPageViewModel`)
        }
    
        
     
    
    