import { GetQuantitySuccess } from 'autogen/swagger/quantity';
import requestContext from 'modules/requestContext';
import { AppOperatingSystem } from 'recoilState/atoms/requestData';

const context = requestContext;

export default function updateAppQuantities(quantities: GetQuantitySuccess) {
    try {
        if (context.clubMatasAppOS === AppOperatingSystem.Android) {
            (window as any).AndroidInterface.updateQuantities(JSON.stringify(quantities));
        }

        if (context.clubMatasAppOS === AppOperatingSystem.IOS) {
            (window as any).webkit.messageHandlers.updateQuantities.postMessage(JSON.stringify(quantities));
        }
    } catch (err) {
        console.error(err);
    }
}
