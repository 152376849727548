/* eslint-disable camelcase */
import type { PageType } from 'autogen/sharedEnums';
import type { TrackingAction } from 'modules/tracking/UAEventTracking';
import type { Themes } from 'types/tokens';

export interface JavascriptRequestDataModel {
    userContextModel: UserContextViewModel;
    requestContextModel: RequestContextViewModel;
    featureToggles: JavascriptFeatureToggles;
    websiteSettings: JavascriptWebsiteSettings;
    websiteTime: JavascriptWebsiteTime;
    segmentation: JavascriptSegmentation;
    javascriptPageContext: JavascriptPageContext;
}

export interface JavascriptWebsiteSettings {
    enhancedEcommerceTrackingEnabled: boolean;
    liveChat: JavascriptWebsiteSettingsLiveChat;
}

export interface JavascriptWebsiteSettingsLiveChat {
    customerServiceChatDisabledText: string;
    IsCustomerServiceChatEnabled: boolean;
    advisorChatDisabledText: string;
    isAdvisorChatEnabled: boolean;
    isChatEnabled: boolean;
}

export interface JavascriptWebsiteTime {
    load: string;
}

export interface JavascriptPageContext {
    brandContext?: string;
    pageType?: PageType;
    designThemeName: Themes;
}

export interface JavascriptFeatureToggles {
    debugViewEnabled: boolean;
    powerstepEnabled: boolean;
    visualSearchEnabled: boolean;
    dawaAutocompleteEnabled: boolean;
    bookingEarlyStageEnabled: boolean;
}

export interface JavascriptSegmentation {
    name: string;
    recoilName: Segmentation;
    culture: string;
    countryCode: string;
    countryName: string;
    zipCodeLength: number;
    mobileLength: number;
    mobilePrefix: number;
    primaryWebsiteUrl: string;
    seoCulture: string;
}

export interface RequestContextViewModel {
    isClubMatasApp: boolean;
    clubMatasAppOS?: AppOperatingSystem | null;
    clubMatasAppOSVersion?: Version;
    clubMatasAppDeviceName?: string | null;
    clubMatasAppVersion?: Version;
    isClubMatasAppApplePaySupport: boolean;
    isClubMatasLightPdp: boolean;
    supportsNativeBackArrow: boolean;
    supportsNativeTitleBar: boolean;
    posRequest?: PosRequest;
    referrer?: string;
    posRequestAuthenticated?: boolean;
}

export interface PosRequest {
    isPosRequest: boolean;
    posHost: string;
    receiptId?: string;
    storeId?: number;
    loginStatus?: 'Success' | 'ErrorInLogin' | 'ServiceUnavailable';
    isCheckout: boolean;
}

export interface UserContextViewModel {
    permissionRequired: boolean;
    userHasSeenProductPage: boolean;
    userMessage: UserMessage | null;
    isMatasPlusMember: boolean;
    isClubMatasMember: boolean;
    age: number | null;
    clubMatasMemberCardNumber: string | null;
    gender: GenderType | null;
    isAuthenticated: boolean;
    loginDirectLink: {
        loginModal?: { url: string; trackingAction: TrackingAction };
        loginUrl?: string;
    };
}

export interface UserMessage {
    messageType: UserMessageType;
    isRouteMessage: boolean;
    headline: string;
    content: string | null;
    linkText: string | null;
    url: string | null;
    renderValidationSummary: boolean;
}

export enum GenderType {
    Male = 1,
    Female = 2,
    Other = 3
}

export enum UserMessageType {
    ProductReview_Received = 1,
    ProductReview_Received_OptedOut = 2,
    Subscription_Cancelled = 3,
    Subscription_Updated = 4,
    Payment_Error = 5,
    General = 6,
    Signoff_Success = 7,
    Login_Unavailable = 8,
    Alert_Success = 9,
    Alert_Danger = 10,
    Subscription_ConfirmUpdate = 11,
    CreditCardDeleted = 12
}

export enum AppOperatingSystem {
    Unknown = 0,
    Android = 1,
    IOS = 2
}

export interface Version {
    build: number;
    major: number;
    majorRevision: number;
    minor: number;
    minorRevision: number;
    revision: number;
}

export type Segmentation = 'matas-dk' | 'matas-se' | 'matas-no';
