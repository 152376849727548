/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as EditModal from './trans-website-booking-myprofile-bookingdetailsview-futurebooking-editmodal';
export * as CancelModal from './trans-website-booking-myprofile-bookingdetailsview-futurebooking-cancelmodal';
export * as CancelModalEvents from './trans-website-booking-myprofile-bookingdetailsview-futurebooking-cancelmodalevents';

export const HeadlinePrefix = 'Website.Booking.MyProfile.BookingDetailsView.FutureBooking.HeadlinePrefix';
export const ButtonText = 'Website.Booking.MyProfile.BookingDetailsView.FutureBooking.ButtonText';
export const DisclaimerPaidEvent = 'Website.Booking.MyProfile.BookingDetailsView.FutureBooking.DisclaimerPaidEvent';
export const EditButton = 'Website.Booking.MyProfile.BookingDetailsView.FutureBooking.EditButton';
export const GoToDetailsButton = 'Website.Booking.MyProfile.BookingDetailsView.FutureBooking.GoToDetailsButton';
