import {
    BorderRadiusSmall,
    ColorNeutralBlack,
    ColorNordicGrey4,
    FontLetterSpacing04,
    FontWeightNormal,
    Spacing2,
    Spacing4,
    Spacing6,
    Spacing8
} from 'autogen/design-tokens/tokens';
import React, { type HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';
import { handleSizesMediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { ISplash } from 'components/shared/Splash/shared/types';
import { Typography } from '../typography';
import type { ITag } from './shared/types';
import type { OverlineFontSizes } from '../typography/types';
import { omit } from 'lodash-es';

type TagFontSizes = { [key: string]: OverlineFontSizes };

const fontSize: TagFontSizes = {
    md: 10,
    xl: 14
};

const sizeStyling = {
    md: css`
        padding: ${Spacing2} ${Spacing6};
    `,
    xl: css`
        padding: ${Spacing4} ${Spacing8};
    `
};

const Wrapper = styled.div<ITagTransientProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: ${FontLetterSpacing04};
    line-height: 1;
    border-radius: ${BorderRadiusSmall};
    background-color: ${({ $backgroundColor }) => $backgroundColor ?? ColorNordicGrey4};
    color: ${({ $textColor }) => $textColor ?? ColorNeutralBlack};
    ${({ $size }) =>
        $size && (typeof $size === 'object' ? handleSizesMediaQueryStyling($size, sizeStyling) : sizeStyling[$size])}

    ${({ $removeBackground }) =>
        $removeBackground &&
        css`
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        `}
`;

interface ITagTransientProps {
    $backgroundColor: ITag['backgroundColor'];
    $textColor: ITag['textColor'];
    $size: ITag['size'];
    $removeBackground: ITag['removeBackground'];
}

type ToTransientProps = {
    stylingProps: ITagTransientProps;
    filteredRestProps: HTMLAttributes<HTMLDivElement>;
};

function toTransientProps(props: ITag): ToTransientProps {
    const { backgroundColor, textColor, size = 'md', removeBackground } = props;

    const stylingProps = {
        $backgroundColor: backgroundColor,
        $textColor: textColor,
        $size: size,
        $removeBackground: removeBackground
    };

    const filteredRestProps = omit(props, ['backgroundColor', 'textColor', 'size', 'removeBackground']);

    return {
        stylingProps,
        filteredRestProps
    };
}

const StyledOverline = styled(Typography.Overline)`
    && {
        font-weight: ${FontWeightNormal};
        display: grid;
        grid-template-columns: auto;

        ${breakpoint.up(sizes.sm)} {
            display: flex;
            flex-wrap: wrap;
        }
    }
`;

function sizeMapper(size: NonNullable<ISplash['size']>): OverlineFontSizes {
    let object: OverlineFontSizes = {};

    if (typeof size !== 'object') {
        object = fontSize[size];
    } else {
        Object.entries(size).forEach(([key, value]) => {
            if (fontSize[value]) {
                object[key] = fontSize[value];
            }
        });
    }

    return object;
}

export default function Tag(props: ITag): JSX.Element {
    const { size = 'md', ...restProps } = props;
    const { children } = restProps;
    const { stylingProps, filteredRestProps } = toTransientProps(props);

    const mappedSize: OverlineFontSizes = sizeMapper(size);

    return (
        <Wrapper {...stylingProps} {...filteredRestProps}>
            <StyledOverline size={mappedSize}>{children}</StyledOverline>
        </Wrapper>
    );
}
