/* eslint-disable import/prefer-default-export */
import { GetBasketQueryKey } from 'autogen/swagger/basket/hooks';
import { refreshBasketQuantityGlobally } from 'hooks/basket/useBasketQuantity';
import { IndexQueryKey as GetMiniBasketQueryKey } from 'autogen/swagger/minibasket/hooks';
import queryClient from 'setup/react-query/queryClient';
import {
    ProductDeliveryInfoViewModelQueryKey,
    ProductDeliveryToStoreInfoViewModelQueryKey
} from 'autogen/swagger/product/hooks';
import { GetExpectedDeliveryInformationForProductPageQueryKey } from 'autogen/swagger/expecteddelivery/hooks';

export function updateBasket() {
    queryClient.invalidateQueries([GetBasketQueryKey]);
    queryClient.invalidateQueries([GetMiniBasketQueryKey]);
    queryClient.invalidateQueries([ProductDeliveryInfoViewModelQueryKey]);
    queryClient.invalidateQueries([ProductDeliveryToStoreInfoViewModelQueryKey]);
    refreshBasketQuantityGlobally();
}
