import React, { type HTMLAttributes } from 'react';
import styled, { css, type RuleSet } from 'styled-components';
import type { BreakPointsType } from 'variables';
import { type MappedBreakpointStyling, mediaQueryStyling } from 'helpers/mediaQueryStyling';

import Text from './Text';
import {
    BodyThemeLarge,
    BodyThemeLargeSemiBold,
    BodyThemeMedium,
    BodyThemeMediumSemiBold,
    BodyThemeSmall,
    BodyThemeSmallSemiBold
} from '../styles/body';
import type { IText } from '../types';

const StyledText = styled(Text)<{ styling?: RuleSet | null }>`
    ${({ styling }) =>
        styling &&
        css`
            ${styling}
        `}
`;

type BodyTypes = 'p' | 'span' | 'div' | 'a';
export type ThemeOptions = 'large' | 'largeSemiBold' | 'medium' | 'mediumSemiBold' | 'small' | 'smallSemiBold';
type breakPointObject = BreakPointsType<ThemeOptions>;
export type themeTypes = ThemeOptions | breakPointObject;

const themeStyle: Record<ThemeOptions, RuleSet> = {
    large: BodyThemeLarge,
    largeSemiBold: BodyThemeLargeSemiBold,
    medium: BodyThemeMedium,
    mediumSemiBold: BodyThemeMediumSemiBold,
    small: BodyThemeSmall,
    smallSemiBold: BodyThemeSmallSemiBold
};

type BreakpointTheme = MappedBreakpointStyling<breakPointObject, typeof themeStyle>;

function handleTheme(theme: themeTypes): RuleSet | null {
    if (typeof theme !== 'object') {
        return themeStyle[theme];
    }

    const breakpointTheme = Object.keys(theme).reduce<BreakpointTheme>((acc, key) => {
        acc[key] = themeStyle[theme[key]];
        return acc;
    }, {});

    return mediaQueryStyling(breakpointTheme);
}

export interface IBody extends Omit<IText, 'size'>, HTMLAttributes<HTMLSpanElement> {
    as?: BodyTypes;
    forwardedAs?: BodyTypes;
    themeTypes?: themeTypes;
}

export default function Body(props: IBody): JSX.Element {
    const { as = 'p', themeTypes = 'medium', children, ...restProps } = props;
    const styling = handleTheme(themeTypes);

    return (
        <StyledText forwardedAs={as} styling={styling} {...restProps}>
            {children}
        </StyledText>
    );
}
