import { ColorNordicGrey5 } from 'autogen/design-tokens/tokens';
import { ButtonWithIcon } from 'components/Button';
import IButtonWithIcon from 'components/Button/shared/types/buttonWithIcon';
import { rem } from 'modules/helpers/style';
import React from 'react';
import styled from 'styled-components';

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    position: relative;
    height: auto;
    background-color: transparent;
    padding: 0;
    flex: 1;
    outline: none;

    & * {
        z-index: 1;
    }

    .hover-effect {
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: ${ColorNordicGrey5};
        opacity: 0;
        border-radius: 4px;
        z-index: 0;
        pointer-events: none;
    }

    &:focus {
        .hover-effect {
            outline-offset: -1px;
            outline: 1px solid black;
        }
    }

    &:hover {
        .hover-effect {
            opacity: 1;
        }
    }
`;

const SessionMenuItem = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButtonWithIcon>(
    (
        {
            icon,
            children,
            variant = null,
            iconProperties = { position: 'top', spacing: rem(8), size: rem(20) },
            size = { sm: 'sm', lg: 'md' },
            as = 'a',
            ...restProps
        },
        ref
    ) => (
        <StyledButtonWithIcon
            ref={ref}
            size={size}
            variant={variant}
            icon={icon}
            forwardedAs={as}
            iconProperties={iconProperties}
            {...restProps}
        >
            {children}
            <span className="hover-effect" />
        </StyledButtonWithIcon>
    )
);

export default SessionMenuItem;
