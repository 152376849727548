import React from 'react';
import styled, { css } from 'styled-components';
import { IButtonProps, buttonStyling } from './defaultButton';

export * from './enums';

const LinkButtonElement = styled.a<ILinkButtonProps>`
    ${({ buttonStyle }) =>
        buttonStyle
            ? css`
                  ${buttonStyling}
                  text-align: center;

                  &:hover,
                  &:focus {
                      text-decoration: none;
                  }
              `
            : css`
                  display: inline-block;
                  text-decoration: underline;
                  text-align: center;
              `};
`;

export interface ILinkButtonProps extends IButtonProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const LinkButton = React.forwardRef((props: ILinkButtonProps, ref: any): JSX.Element => {
    const { children, ...rest } = props;

    return (
        <LinkButtonElement ref={ref} {...rest}>
            {children}
        </LinkButtonElement>
    );
});

export default LinkButton;
