import { hashHistory } from 'modules/helpers/history';
import UAEventTracking from 'modules/tracking/UAEventTracking';

export default (): void => {
    UAEventTracking({
        eventCategory: 'visualSearch',
        eventAction: 'open',
        eventLabel: 'offComponentButton'
    });
    hashHistory.push({ pathname: 'visualsearch' });
};
