import React, { useEffect, useState } from 'react';
import { MatasPlusPowerStep, MatasPlusPowerStepAdvantage } from 'autogen/swagger/powerstep';
import { em, rem } from 'modules/helpers/style';
import ReactButton, { ButtonStyle } from 'modules/react-button';
import Spinner from 'modules/shared/circleSpinner';
import { createRoot } from 'react-dom/client';
import styled, { css } from 'styled-components';
import { breakpoint, colors, sizes } from 'variables';
import { growlGeneralExceptionError } from 'modules/growl';
import { POST } from 'modules/helpers/fetch';
import {
    ColorBrandBlue1,
    ColorClubMatasPlus5,
    ColorBrandBlue3,
    FontLeadParagraphFamily,
    FontSize14,
    FontSize12,
    FontSize16
} from 'autogen/design-tokens/tokens';
import logo from 'components/matasPlus/assets/logos/logo-horizontal.svg';
import Powerstep from 'components/matasPlus/assets/powerstep.jpg';
import coinsSvg from 'components/matasPlus/assets/coins.svg';
import surpriseSvg from 'components/matasPlus/assets/surprise.svg';
import truckSvg from 'components/matasPlus/assets/truck.svg';
import fetch from 'cross-fetch';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;

    color: ${ColorBrandBlue1};
    background-color: ${'#e8d7b7'}; // ColorClubMatasPlus4

    ${breakpoint.up(sizes.md)} {
        display: grid;
        grid-template-areas:
            'HeadlineContainer Logo Sisters'
            'HeadlineContainer Advantages Sisters'
            'AddToBasketButton Advantages Sisters'
            'InfoContainer Advantages Sisters';
        text-align: left;
        gap: 0 ${rem(32)};
        padding: 40px 0 0 0;
        grid-template-columns: repeat(3, 1fr);
        background-image: url(${Powerstep});
        background-size: cover;
        background-position: right;
    }
`;

const Logo = styled.img`
    width: ${rem(160)};
    margin-bottom: ${rem(16)};

    ${breakpoint.up(sizes.md)} {
        margin: 0 auto;
    }
`;

const HeadlineContainer = styled.div`
    margin-bottom: ${rem(16)};

    ${breakpoint.up(sizes.md)} {
        grid-area: HeadlineContainer;
        padding-left: 40px;
        margin-bottom: ${rem(16)};
    }
`;

const Headline = styled.div`
    font-size: ${rem(24)};
    line-height: ${em(34, 24)};
    font-family: ${FontLeadParagraphFamily};

    ${breakpoint.up(sizes.lg)} {
        padding-right: ${rem(25)};
    }
`;

const SubTitle = styled.div`
    font-size: ${FontSize16};
`;

const Advantages = styled.div`
    margin-bottom: ${rem(20)};

    ${breakpoint.up(sizes.md)} {
        grid-area: Advantages;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${rem(40)};
    }
`;

const Advantage = styled.div<{ hasAdditionalText: boolean }>`
    font-size: ${FontSize14};
    background-color: ${ColorClubMatasPlus5};
    padding: ${rem(4)} ${rem(12)};
    margin-bottom: ${rem(8)};
    width: ${rem(250)};
    max-width: 100%;
    text-align: left;

    ${({ hasAdditionalText }) =>
        hasAdditionalText
            ? css`
                  display: grid;
                  grid-template-areas:
                      'AdvantageIcon AdvantageText'
                      'AdvantageIcon AdvantageAdditionalText';
                  grid-template-columns: ${rem(30)} auto;
                  gap: 0 ${rem(12)};
              `
            : css`
                  display: flex;
                  align-items: center;
              `}
`;

const AdvantageIcon = styled.img`
    grid-area: AdvantageIcon;
    width: ${rem(30)};
    height: ${rem(30)};
    margin-right: ${rem(12)};
`;

const AdvantageText = styled.span`
    font-weight: bold;
    grid-area: AdvantageText;
`;

const AdvantageAdditionalText = styled.span`
    grid-area: AdvantageAdditionalText;
`;

const InfoContainer = styled.div`
    font-size: ${FontSize12};

    ${breakpoint.up(sizes.md)} {
        grid-area: InfoContainer;
        margin-bottom: ${rem(40)};
        padding-left: 40px;
    }
`;

const PriceContainer = styled.div``;

const ReadMore = styled.a`
    font-weight: bold;
    padding-left: ${rem(4)};
    text-decoration: underline;
    color: currentColor;
`;

const Disclaimer = styled.div`
    color: ${ColorBrandBlue3};
`;

const AddToBasketButton = styled(ReactButton)`
    width: ${rem(400)};
    max-width: 100%;
    margin-bottom: ${rem(15)};

    ${breakpoint.up(sizes.md)} {
        grid-area: AddToBasketButton;
        width: ${rem(180)};
        margin-left: 40px;
    }
`;

function advantageIcon(advantageType: MatasPlusPowerStepAdvantage['advantageType']): string {
    switch (advantageType) {
        case 'FreeShipping':
            return truckSvg;
        case 'MonthlyOffer':
            return surpriseSvg;
        case 'PointMultiplier':
            return coinsSvg;
        default:
            return '';
    }
}

export default function PowerStep(props: { url: string }): JSX.Element {
    const { url } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MatasPlusPowerStep>(null);
    const { mainTitle, addToBasketLink, monthlyPriceText, disclaimer, basketUrl, advantages, subTitle, readMoreLink } =
        data || {};

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then(setData);
    }, [url]);

    async function handleClick(): Promise<void> {
        const spinnerTimer = setTimeout(() => {
            setLoading(true);
        }, 500);
        try {
            const [json, { default: track }] = await Promise.all([
                POST(addToBasketLink.link.url).then((res) => res.json()),
                import('modules/tracking/dataLayer')
            ]);

            if (json.dataLayerDto) {
                track(json.dataLayerDto);
            }
            window.location.href = basketUrl;
        } catch (err) {
            growlGeneralExceptionError();
        } finally {
            clearTimeout(spinnerTimer);
            if (loading) {
                setLoading(false);
            }
        }
    }

    if (!data) {
        return (
            <Wrapper>
                <Spinner size={50} color={colors.matasPlus} />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Logo src={logo} alt="Club Matas Plus" />
            <HeadlineContainer>
                <Headline>{mainTitle}</Headline>
                <SubTitle>{subTitle}</SubTitle>
            </HeadlineContainer>
            <Advantages>
                {advantages.map(({ advantageType, text, additionalText }) => (
                    <Advantage hasAdditionalText={!!additionalText} key={advantageType}>
                        <AdvantageIcon src={advantageIcon(advantageType)} alt="" />
                        <AdvantageText>{text}</AdvantageText>
                        {additionalText && <AdvantageAdditionalText>{additionalText}</AdvantageAdditionalText>}
                    </Advantage>
                ))}
            </Advantages>
            <AddToBasketButton
                buttonStyle={ButtonStyle.matasPlus}
                onClick={handleClick}
                type="button"
                uaEvent={{ eventCategory: 'matasPlus', eventAction: 'powerStepAddToBasket' }}
            >
                {loading ? <Spinner size={20} /> : addToBasketLink.text}
            </AddToBasketButton>
            <InfoContainer>
                <PriceContainer>
                    {monthlyPriceText}
                    <ReadMore href={readMoreLink.link.url} target={readMoreLink.link.target}>
                        {readMoreLink.text}
                    </ReadMore>
                </PriceContainer>
                <Disclaimer>{disclaimer}</Disclaimer>
            </InfoContainer>
        </Wrapper>
    );
}

export function initPowerStep(): void {
    const container: HTMLDivElement | null = document.querySelector('#js-matasPlusPowerStep');

    if (!container) {
        return;
    }

    const { url } = container.dataset;

    if (!url) return;

    const root = createRoot(container);

    root.render(<PowerStep url={url} />);
}
