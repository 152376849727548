export default function serializeForm(form: HTMLFormElement): Record<string, string> {
    const obj = {};
    const formData = new FormData(form);

    Array.from(formData.keys()).forEach((key) => {
        obj[key] = formData.get(key);
    });

    return obj;
}
