
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './pricelabelinfo';

        export type PriceLabelInfoModel = components['schemas']['PriceLabelInfoModel'];
                    

        

        
        

        

        export type GetForFixedLowPriceSuccess = paths['/internal/PriceLabelInfo/GetForFixedLowPrice']['get']['responses']['200']['content']['text/plain'];

        export const GetForFixedLowPriceUrl = '/internal/PriceLabelInfo/GetForFixedLowPrice';

        
        export function GetForFixedLowPrice(): Promise<GetForFixedLowPriceSuccess> {
            return fetchJSON<GetForFixedLowPriceSuccess>(`/internal/PriceLabelInfo/GetForFixedLowPrice`)
        }
    
        
     
    
    