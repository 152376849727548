import React from 'react';
import SessionMenuItem from 'components/SessionMenu/components/SessionMenuItem';
import { useTranslation } from 'react-i18next';
import { FindStore as FindStoreTrans } from 'autogen/translation-keys/trans-website-shared';
import MapMarker from 'icons/MapMarker.svg?react';
import { HeaderItemText } from './shared/styles';

export default function FindStore({ findStoreUrl, ...restProps }: { findStoreUrl: string }) {
    const { t } = useTranslation();

    return (
        <SessionMenuItem {...restProps} icon={<MapMarker aria-hidden />} href={findStoreUrl}>
            <HeaderItemText>{t(FindStoreTrans)}</HeaderItemText>
        </SessionMenuItem>
    );
}
