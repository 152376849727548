
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './pos';

        export type PosAction = components['schemas']['PosAction'];
                    export type PosClubMatasMember = components['schemas']['PosClubMatasMember'];
                    export type RegisterReceiptRequest = components['schemas']['RegisterReceiptRequest'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ReceiptHeader = components['schemas']['ReceiptHeader'];
                    export type ReceiptClubMatas = components['schemas']['ReceiptClubMatas'];
                    export type ReceiptExchangeSticker = components['schemas']['ReceiptExchangeSticker'];
                    export type ReceiptDiscount = components['schemas']['ReceiptDiscount'];
                    export type ReceiptLine = components['schemas']['ReceiptLine'];
                    export type ReceiptPayment = components['schemas']['ReceiptPayment'];
                    export type Receipt = components['schemas']['Receipt'];
                    export type ReceiptContainer = components['schemas']['ReceiptContainer'];
                    export type Vat = components['schemas']['Vat'];
                    export type BasketItem = components['schemas']['BasketItem'];
                    export type Details = components['schemas']['Details'];
                    export type UnclaimedGifts = components['schemas']['UnclaimedGifts'];
                    export type Summary = components['schemas']['Summary'];
                    export type FiftyTwoBasket = components['schemas']['FiftyTwoBasket'];
                    export type InitializeBasketRequest = components['schemas']['InitializeBasketRequest'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusViewModel = components['schemas']['FavoriteStatusViewModel'];
                    export type FavoriteStatusViewModelMaybe = components['schemas']['FavoriteStatusViewModelMaybe'];
                    export type BasketLineModifiedResultViewModel = components['schemas']['BasketLineModifiedResultViewModel'];
                    export type ModifiedBasketViewModel = components['schemas']['ModifiedBasketViewModel'];
                    export type RegisterBasketResponse = components['schemas']['RegisterBasketResponse'];
                    export type LogLevelEnum = components['schemas']['LogLevelEnum'];
                    export type LogEventRequest = components['schemas']['LogEventRequest'];
                    export type PosFrontpageViewModel = components['schemas']['PosFrontpageViewModel'];
                    export type PosPaymentViewModel = components['schemas']['PosPaymentViewModel'];
                    export type ReceiptContainerRequest = components['schemas']['ReceiptContainerRequest'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductNameRenderer = components['schemas']['ProductNameRenderer'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type ProductResponse = components['schemas']['ProductResponse'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type SubscriptionResponse = components['schemas']['SubscriptionResponse'];
                    

        

        
        export type RegisterReceiptRequestBody = paths['/internal/pos/Init/RegisterReceipt']['post']['requestBody']['content']['application/json'];

        

        export type RegisterReceiptSuccess = paths['/internal/pos/Init/RegisterReceipt']['post']['responses']['200']['content']['text/plain'];

        export const RegisterReceiptUrl = '/internal/pos/Init/RegisterReceipt';

        
        export function RegisterReceipt(requestBody: RegisterReceiptRequestBody): Promise<RegisterReceiptSuccess> {
            return postJSON<RegisterReceiptSuccess>(`/internal/pos/Init/RegisterReceipt`, requestBody)
        }
    
        
     
    
        export type RegisterBasketRequestBody = paths['/internal/pos/Init/RegisterBasket']['post']['requestBody']['content']['application/json'];

        export type RegisterBasketParams = paths['/internal/pos/Init/RegisterBasket']['post']['parameters']['query'];

        export type RegisterBasketSuccess = paths['/internal/pos/Init/RegisterBasket']['post']['responses']['200']['content']['text/plain'];

        export const RegisterBasketUrl = '/internal/pos/Init/RegisterBasket';

        
        export function RegisterBasket(params: RegisterBasketParams, requestBody: RegisterBasketRequestBody): Promise<RegisterBasketSuccess> {
            return postJSON<RegisterBasketSuccess>(`/internal/pos/Init/RegisterBasket${formatParams(params)}`, requestBody)
        }
    
        
     
    
        export type LogPosEventRequestBody = paths['/internal/pos/Log/LogPosEvent']['post']['requestBody']['content']['application/json'];

        

        

        export const LogPosEventUrl = '/internal/pos/Log/LogPosEvent';

        
        export function LogPosEvent(requestBody: LogPosEventRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/pos/Log/LogPosEvent`, requestBody)
        }
    
        
     
    
        

        

        export type PosFrontpageViewModelSuccess = paths['/internal/pos/PosFrontpage/PosFrontpageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PosFrontpageViewModelUrl = '/internal/pos/PosFrontpage/PosFrontpageViewModel';

        
        export function PosFrontpageViewModel(): Promise<PosFrontpageViewModelSuccess> {
            return fetchJSON<PosFrontpageViewModelSuccess>(`/internal/pos/PosFrontpage/PosFrontpageViewModel`)
        }
    
        
     
    
        

        

        export type GetOrderNumberSuccess = paths['/internal/PosPayment/GetOrderNumber']['get']['responses']['200']['content']['text/plain'];

        export const GetOrderNumberUrl = '/internal/PosPayment/GetOrderNumber';

        
        export function GetOrderNumber(): Promise<GetOrderNumberSuccess> {
            return fetchJSON<GetOrderNumberSuccess>(`/internal/PosPayment/GetOrderNumber`)
        }
    
        
     
    
        

        

        export type CreatePendingSubscriptionSuccess = paths['/internal/pos/Subscription/CreatePendingSubscription']['post']['responses']['200']['content']['text/plain'];

        export const CreatePendingSubscriptionUrl = '/internal/pos/Subscription/CreatePendingSubscription';

        
        export function CreatePendingSubscription(): Promise<CreatePendingSubscriptionSuccess> {
            return postQuery<CreatePendingSubscriptionSuccess>(`/internal/pos/Subscription/CreatePendingSubscription`)
        }
    
        
     
    
        export type GetPosSubscriptionInformationRequestBody = paths['/internal/pos/Subscription/GetPosSubscriptionInformation']['post']['requestBody']['content']['application/json'];

        

        export type GetPosSubscriptionInformationSuccess = paths['/internal/pos/Subscription/GetPosSubscriptionInformation']['post']['responses']['200']['content']['text/plain'];

        export const GetPosSubscriptionInformationUrl = '/internal/pos/Subscription/GetPosSubscriptionInformation';

        
        export function GetPosSubscriptionInformation(requestBody: GetPosSubscriptionInformationRequestBody): Promise<GetPosSubscriptionInformationSuccess> {
            return postJSON<GetPosSubscriptionInformationSuccess>(`/internal/pos/Subscription/GetPosSubscriptionInformation`, requestBody)
        }
    
        
     
    
    