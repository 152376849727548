import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import type { BasketShippingPriceSummary } from 'autogen/swagger/checkout';
import Layout from 'components/Layout';
import styled from 'styled-components';
import DeliveryPreviewButton from 'components/basket/DeliveryPreview/DeliveryPreviewButton';
import { Spacing2, Spacing8 } from 'autogen/design-tokens/tokens';
import format from 'helpers/translations';

type ShippingSummaryProps = Record<string, any> & { model: BasketShippingPriceSummary };

const TextContainer = styled.div`
    flex: 1;
`;

const Text = styled(Typography.Body)`
    display: inline-block;
`;

const ButtonContainer = styled.span`
    display: inline-block;
    white-space: nowrap;
`;

const StyledPrice = styled(Price)`
    text-align: right;
    text-wrap: balance;
`;

export default function ShippingSummary({ model: { headline, price }, ...restProps }: ShippingSummaryProps) {
    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <TextContainer>
                <Text as="span" themeTypes="medium">
                    {format(headline)}&nbsp;
                </Text>
                <ButtonContainer>
                    <span> · </span>
                    <DeliveryPreviewButton />
                </ButtonContainer>
            </TextContainer>

            <StyledPrice price={price} />
        </Layout.Flex.Row>
    );
}
