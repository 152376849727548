import htmlModal from 'modules/modal/htmlModal';
import { colors } from 'variables';
import UserContext from './userContext';

export default () => {
    if (UserContext.userMessage && UserContext.userMessage.isRouteMessage) {
        const theme = {};
        if (document.body.classList.contains('myprofile') && theme.backgroundColor === undefined) {
            theme.backgroundColor = colors.myProfileBackground;
        }
        const modal = htmlModal({ layout: { theme } });

        modal({
            url: UserContext.userMessage.url.value,
            onAfterContent: ({ wrapper, close }) => {
                wrapper.addEventListener('click', e => {
                    const { target: t } = e;
                    const closeButton = t.closest('.js-modalClose');
                    if (closeButton) {
                        close();
                    }
                });
            }
        });
    }
};
