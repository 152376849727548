import React from 'react';
import ILink from 'shared/interfaces/link';
import styled from 'styled-components';

export const StyledLink = styled.a<ILink>`
    text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export default function Link(props: ILink): JSX.Element {
    const { url, children, ...restProps } = props;
    return (
        <StyledLink href={url} {...restProps}>
            {children}
        </StyledLink>
    );
}
