
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './booking';

        export type BookingType = components['schemas']['BookingType'];
                    export type ConsultationCategoryModel = components['schemas']['ConsultationCategoryModel'];
                    export type StoreSimpleModel = components['schemas']['StoreSimpleModel'];
                    export type StoreSimpleModelMaybe = components['schemas']['StoreSimpleModelMaybe'];
                    export type BookingStatus = components['schemas']['BookingStatus'];
                    export type Location = components['schemas']['Location'];
                    export type BookingAddress = components['schemas']['BookingAddress'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type MyProfileBookingModel = components['schemas']['MyProfileBookingModel'];
                    export type MyProfileBookingListViewModel = components['schemas']['MyProfileBookingListViewModel'];
                    export type PagedMyProfileBookingListViewModel = components['schemas']['PagedMyProfileBookingListViewModel'];
                    export type BookingFeedbackStatus = components['schemas']['BookingFeedbackStatus'];
                    export type AddressModel = components['schemas']['AddressModel'];
                    export type TicketModel = components['schemas']['TicketModel'];
                    export type BookingInfoBoxModel = components['schemas']['BookingInfoBoxModel'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type PlaceholderLinkType = components['schemas']['PlaceholderLinkType'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type LinkKey = components['schemas']['LinkKey'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type LinkElementModel = components['schemas']['LinkElementModel'];
                    export type LinkElementModelMaybe = components['schemas']['LinkElementModelMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type MyProfileBookingDetailedModel = components['schemas']['MyProfileBookingDetailedModel'];
                    export type MyProfileBookingDetailsViewModel = components['schemas']['MyProfileBookingDetailsViewModel'];
                    export type BookingOperationResultModel = components['schemas']['BookingOperationResultModel'];
                    export type BookingFeedbackRating = components['schemas']['BookingFeedbackRating'];
                    export type BookingFeedbackCreateRequestModel = components['schemas']['BookingFeedbackCreateRequestModel'];
                    export type BookingProductNewsItemModel = components['schemas']['BookingProductNewsItemModel'];
                    export type MyProfileBookingViewModel = components['schemas']['MyProfileBookingViewModel'];
                    export type StoreModel = components['schemas']['StoreModel'];
                    export type BookingAddressMaybe = components['schemas']['BookingAddressMaybe'];
                    export type TimeSlotDetailed = components['schemas']['TimeSlotDetailed'];
                    export type DailyBookingSlotsModel = components['schemas']['DailyBookingSlotsModel'];
                    export type DailyStoreBookingSlotsListModel = components['schemas']['DailyStoreBookingSlotsListModel'];
                    export type BookingSlotsListByLocationViewModel = components['schemas']['BookingSlotsListByLocationViewModel'];
                    export type ClubMatasUser = components['schemas']['ClubMatasUser'];
                    export type BookingCategoryModel = components['schemas']['BookingCategoryModel'];
                    export type AddressModelMaybe = components['schemas']['AddressModelMaybe'];
                    export type BookingModel = components['schemas']['BookingModel'];
                    export type ExternalPartnerModel = components['schemas']['ExternalPartnerModel'];
                    export type ExternalPartnerModelMaybe = components['schemas']['ExternalPartnerModelMaybe'];
                    export type ConsultationProductModel = components['schemas']['ConsultationProductModel'];
                    export type EventTimeModel = components['schemas']['EventTimeModel'];
                    export type EventTimeModelMaybe = components['schemas']['EventTimeModelMaybe'];
                    export type BookingOverviewModel = components['schemas']['BookingOverviewModel'];
                    export type TotalTicketPriceModel = components['schemas']['TotalTicketPriceModel'];
                    export type BookingReceiptModel = components['schemas']['BookingReceiptModel'];
                    export type BookRequestModel = components['schemas']['BookRequestModel'];
                    export type BookingPosJournalModel = components['schemas']['BookingPosJournalModel'];
                    export type ConsultationCategoryFilterModel = components['schemas']['ConsultationCategoryFilterModel'];
                    export type ExternalPartnerFilterModel = components['schemas']['ExternalPartnerFilterModel'];
                    export type AvailableBookingSlotModel = components['schemas']['AvailableBookingSlotModel'];
                    export type AvailableBookingSlotModelMaybe = components['schemas']['AvailableBookingSlotModelMaybe'];
                    export type ConsultationProductListModel = components['schemas']['ConsultationProductListModel'];
                    export type ConsultationProductListViewModel = components['schemas']['ConsultationProductListViewModel'];
                    export type ConsultationProductRecommendationType = components['schemas']['ConsultationProductRecommendationType'];
                    export type ConsultationProductRecommendationModel = components['schemas']['ConsultationProductRecommendationModel'];
                    export type ConsultationProductStoreInfo = components['schemas']['ConsultationProductStoreInfo'];
                    export type ConsultationProductPageModel = components['schemas']['ConsultationProductPageModel'];
                    export type StoreViewModel = components['schemas']['StoreViewModel'];
                    export type StoreViewModelMaybe = components['schemas']['StoreViewModelMaybe'];
                    export type ConsultationProductListPageContentViewModel = components['schemas']['ConsultationProductListPageContentViewModel'];
                    export type StoreModelMaybe = components['schemas']['StoreModelMaybe'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type StoreWithDistanceModel = components['schemas']['StoreWithDistanceModel'];
                    export type StoreListViewModel = components['schemas']['StoreListViewModel'];
                    

        export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function ListItemitemDiscriminator<T>(
    model: components['schemas']['ListItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}export function ListRowItemitemDiscriminator<T>(
    model: components['schemas']['ListRowItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}export function MyProfileBookingDetailedModelproductRecommendationsDiscriminator<T>(
    model: components['schemas']['MyProfileBookingDetailedModel']['productRecommendations'][0],
    funcListItem: (m: components['schemas']['ListItem']) => T,
    funcListRowItem: (m: components['schemas']['ListRowItem']) => T,
    funcProductListItemModel: (m: components['schemas']['ProductListItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'ListItem': return funcListItem(model as components['schemas']['ListItem']);
        case 'ListRowItem': return funcListRowItem(model as components['schemas']['ListRowItem']);
        case 'ProductListItemModel': return funcProductListItemModel(model as components['schemas']['ProductListItemModel']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type GetFutureBookingsSuccess = paths['/internal/myprofile/Booking/GetFutureBookings']['get']['responses']['200']['content']['text/plain'];

        export const GetFutureBookingsUrl = '/internal/myprofile/Booking/GetFutureBookings';

        
        export function GetFutureBookings(): Promise<GetFutureBookingsSuccess> {
            return fetchJSON<GetFutureBookingsSuccess>(`/internal/myprofile/Booking/GetFutureBookings`)
        }
    
        
     
    
        

        export type GetPreviousBookingsParams = paths['/internal/myprofile/Booking/GetPreviousBookings']['get']['parameters']['query'];

        export type GetPreviousBookingsSuccess = paths['/internal/myprofile/Booking/GetPreviousBookings']['get']['responses']['200']['content']['text/plain'];

        export const GetPreviousBookingsUrl = '/internal/myprofile/Booking/GetPreviousBookings';

        
        export function GetPreviousBookings(params: GetPreviousBookingsParams): Promise<GetPreviousBookingsSuccess> {
            return fetchJSON<GetPreviousBookingsSuccess>(`/internal/myprofile/Booking/GetPreviousBookings${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetBookingDetailsParams = paths['/internal/myprofile/Booking/GetBookingDetails']['get']['parameters']['query'];

        export type GetBookingDetailsSuccess = paths['/internal/myprofile/Booking/GetBookingDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetBookingDetailsUrl = '/internal/myprofile/Booking/GetBookingDetails';

        
        export function GetBookingDetails(params: GetBookingDetailsParams): Promise<GetBookingDetailsSuccess> {
            return fetchJSON<GetBookingDetailsSuccess>(`/internal/myprofile/Booking/GetBookingDetails${formatParams(params)}`)
        }
    
        
     
    
        

        export type CancelBookingParams = paths['/internal/myprofile/Booking/CancelBooking']['post']['parameters']['query'];

        export type CancelBookingSuccess = paths['/internal/myprofile/Booking/CancelBooking']['post']['responses']['200']['content']['text/plain'];

        export const CancelBookingUrl = '/internal/myprofile/Booking/CancelBooking';

        
        export function CancelBooking(params: CancelBookingParams): Promise<CancelBookingSuccess> {
            return postQuery<CancelBookingSuccess>(`/internal/myprofile/Booking/CancelBooking${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateBookingParams = paths['/internal/myprofile/Booking/UpdateBooking']['post']['parameters']['query'];

        export type UpdateBookingSuccess = paths['/internal/myprofile/Booking/UpdateBooking']['post']['responses']['200']['content']['text/plain'];

        export const UpdateBookingUrl = '/internal/myprofile/Booking/UpdateBooking';

        
        export function UpdateBooking(params: UpdateBookingParams): Promise<UpdateBookingSuccess> {
            return postQuery<UpdateBookingSuccess>(`/internal/myprofile/Booking/UpdateBooking${formatParams(params)}`)
        }
    
        
     
    
        export type CreateFeedbackRequestBody = paths['/internal/myprofile/Booking/CreateFeedback']['post']['requestBody']['content']['application/json'];

        

        export type CreateFeedbackSuccess = paths['/internal/myprofile/Booking/CreateFeedback']['post']['responses']['200']['content']['text/plain'];

        export const CreateFeedbackUrl = '/internal/myprofile/Booking/CreateFeedback';

        
        export function CreateFeedback(requestBody: CreateFeedbackRequestBody): Promise<CreateFeedbackSuccess> {
            return postJSON<CreateFeedbackSuccess>(`/internal/myprofile/Booking/CreateFeedback`, requestBody)
        }
    
        
     
    
        

        export type GetTicketListPdfParams = paths['/internal/myprofile/Booking/GetTicketListPdf']['get']['parameters']['query'];

        export type GetTicketListPdfSuccess = paths['/internal/myprofile/Booking/GetTicketListPdf']['get']['responses']['200']['content']['text/plain'];

        export const GetTicketListPdfUrl = '/internal/myprofile/Booking/GetTicketListPdf';

        
        export function GetTicketListPdf(params: GetTicketListPdfParams): Promise<GetTicketListPdfSuccess> {
            return fetchJSON<GetTicketListPdfSuccess>(`/internal/myprofile/Booking/GetTicketListPdf${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetTicketPdfParams = paths['/internal/myprofile/Booking/GetTicketPdf']['get']['parameters']['query'];

        export type GetTicketPdfSuccess = paths['/internal/myprofile/Booking/GetTicketPdf']['get']['responses']['200']['content']['text/plain'];

        export const GetTicketPdfUrl = '/internal/myprofile/Booking/GetTicketPdf';

        
        export function GetTicketPdf(params: GetTicketPdfParams): Promise<GetTicketPdfSuccess> {
            return fetchJSON<GetTicketPdfSuccess>(`/internal/myprofile/Booking/GetTicketPdf${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type MyProfileBookingModelSuccess = paths['/internal/Booking/MyProfileBookingModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileBookingModelUrl = '/internal/Booking/MyProfileBookingModel';

        
        export function MyProfileBookingModel(): Promise<MyProfileBookingModelSuccess> {
            return fetchJSON<MyProfileBookingModelSuccess>(`/internal/Booking/MyProfileBookingModel`)
        }
    
        
     
    
        

        export type SearchBookingSlotsParams = paths['/internal/booking/ConsultationBooking/SearchBookingSlots']['get']['parameters']['query'];

        export type SearchBookingSlotsSuccess = paths['/internal/booking/ConsultationBooking/SearchBookingSlots']['get']['responses']['200']['content']['text/plain'];

        export const SearchBookingSlotsUrl = '/internal/booking/ConsultationBooking/SearchBookingSlots';

        
        export function SearchBookingSlots(params: SearchBookingSlotsParams): Promise<SearchBookingSlotsSuccess> {
            return fetchJSON<SearchBookingSlotsSuccess>(`/internal/booking/ConsultationBooking/SearchBookingSlots${formatParams(params)}`)
        }
    
        
     
    
        

        export type SearchBookingSlotByLocationParams = paths['/internal/booking/ConsultationBooking/SearchBookingSlotByLocation']['get']['parameters']['query'];

        export type SearchBookingSlotByLocationSuccess = paths['/internal/booking/ConsultationBooking/SearchBookingSlotByLocation']['get']['responses']['200']['content']['text/plain'];

        export const SearchBookingSlotByLocationUrl = '/internal/booking/ConsultationBooking/SearchBookingSlotByLocation';

        
        export function SearchBookingSlotByLocation(params: SearchBookingSlotByLocationParams): Promise<SearchBookingSlotByLocationSuccess> {
            return fetchJSON<SearchBookingSlotByLocationSuccess>(`/internal/booking/ConsultationBooking/SearchBookingSlotByLocation${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetBookingOverviewParams = paths['/internal/booking/ConsultationBooking/GetBookingOverview']['get']['parameters']['query'];

        export type GetBookingOverviewSuccess = paths['/internal/booking/ConsultationBooking/GetBookingOverview']['get']['responses']['200']['content']['text/plain'];

        export const GetBookingOverviewUrl = '/internal/booking/ConsultationBooking/GetBookingOverview';

        
        export function GetBookingOverview(params: GetBookingOverviewParams): Promise<GetBookingOverviewSuccess> {
            return fetchJSON<GetBookingOverviewSuccess>(`/internal/booking/ConsultationBooking/GetBookingOverview${formatParams(params)}`)
        }
    
        
     
    
        

        export type BookParams = paths['/internal/booking/ConsultationBooking/Book']['post']['parameters']['query'];

        export type BookSuccess = paths['/internal/booking/ConsultationBooking/Book']['post']['responses']['200']['content']['text/plain'];

        export const BookUrl = '/internal/booking/ConsultationBooking/Book';

        
        export function Book(params: BookParams): Promise<BookSuccess> {
            return postQuery<BookSuccess>(`/internal/booking/ConsultationBooking/Book${formatParams(params)}`)
        }
    
        
     
    
        

        

        

        export const GetBookingEarlyStageUrl = '/internal/booking/ConsultationBooking/GetBookingEarlyStage';

        
        export function GetBookingEarlyStage(): Promise<unknown> {
            return fetchJSON<unknown>(`/internal/booking/ConsultationBooking/GetBookingEarlyStage`)
        }
    
        
     
    
        

        export type GetTotalTicketPriceParams = paths['/internal/booking/ConsultationBooking/GetTotalTicketPrice']['get']['parameters']['query'];

        export type GetTotalTicketPriceSuccess = paths['/internal/booking/ConsultationBooking/GetTotalTicketPrice']['get']['responses']['200']['content']['text/plain'];

        export const GetTotalTicketPriceUrl = '/internal/booking/ConsultationBooking/GetTotalTicketPrice';

        
        export function GetTotalTicketPrice(params: GetTotalTicketPriceParams): Promise<GetTotalTicketPriceSuccess> {
            return fetchJSON<GetTotalTicketPriceSuccess>(`/internal/booking/ConsultationBooking/GetTotalTicketPrice${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type BookingReceiptModelSuccess = paths['/internal/ConsultationBooking/BookingReceiptModel']['get']['responses']['200']['content']['text/plain'];

        export const BookingReceiptModelUrl = '/internal/ConsultationBooking/BookingReceiptModel';

        
        export function BookingReceiptModel(): Promise<BookingReceiptModelSuccess> {
            return fetchJSON<BookingReceiptModelSuccess>(`/internal/ConsultationBooking/BookingReceiptModel`)
        }
    
        
     
    
        

        

        export type BookRequestModelSuccess = paths['/internal/ConsultationBooking/BookRequestModel']['get']['responses']['200']['content']['text/plain'];

        export const BookRequestModelUrl = '/internal/ConsultationBooking/BookRequestModel';

        
        export function BookRequestModel(): Promise<BookRequestModelSuccess> {
            return fetchJSON<BookRequestModelSuccess>(`/internal/ConsultationBooking/BookRequestModel`)
        }
    
        
     
    
        

        

        export type BookingPosJournalModelSuccess = paths['/internal/ConsultationBooking/BookingPosJournalModel']['get']['responses']['200']['content']['text/plain'];

        export const BookingPosJournalModelUrl = '/internal/ConsultationBooking/BookingPosJournalModel';

        
        export function BookingPosJournalModel(): Promise<BookingPosJournalModelSuccess> {
            return fetchJSON<BookingPosJournalModelSuccess>(`/internal/ConsultationBooking/BookingPosJournalModel`)
        }
    
        
     
    
        

        export type ListConsultationProductsParams = paths['/internal/booking/ConsultationProduct/ListConsultationProducts']['get']['parameters']['query'];

        export type ListConsultationProductsSuccess = paths['/internal/booking/ConsultationProduct/ListConsultationProducts']['get']['responses']['200']['content']['text/plain'];

        export const ListConsultationProductsUrl = '/internal/booking/ConsultationProduct/ListConsultationProducts';

        
        export function ListConsultationProducts(params: ListConsultationProductsParams): Promise<ListConsultationProductsSuccess> {
            return fetchJSON<ListConsultationProductsSuccess>(`/internal/booking/ConsultationProduct/ListConsultationProducts${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type ConsultationProductPageModelSuccess = paths['/internal/ConsultationProduct/ConsultationProductPageModel']['get']['responses']['200']['content']['text/plain'];

        export const ConsultationProductPageModelUrl = '/internal/ConsultationProduct/ConsultationProductPageModel';

        
        export function ConsultationProductPageModel(): Promise<ConsultationProductPageModelSuccess> {
            return fetchJSON<ConsultationProductPageModelSuccess>(`/internal/ConsultationProduct/ConsultationProductPageModel`)
        }
    
        
     
    
        

        

        export type ConsultationProductListPageContentViewModelSuccess = paths['/internal/ConsultationProduct/ConsultationProductListPageContentViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ConsultationProductListPageContentViewModelUrl = '/internal/ConsultationProduct/ConsultationProductListPageContentViewModel';

        
        export function ConsultationProductListPageContentViewModel(): Promise<ConsultationProductListPageContentViewModelSuccess> {
            return fetchJSON<ConsultationProductListPageContentViewModelSuccess>(`/internal/ConsultationProduct/ConsultationProductListPageContentViewModel`)
        }
    
        
     
    
        

        export type ListStoresParams = paths['/internal/booking/Store/ListStores']['get']['parameters']['query'];

        export type ListStoresSuccess = paths['/internal/booking/Store/ListStores']['get']['responses']['200']['content']['text/plain'];

        export const ListStoresUrl = '/internal/booking/Store/ListStores';

        
        export function ListStores(params: ListStoresParams): Promise<ListStoresSuccess> {
            return fetchJSON<ListStoresSuccess>(`/internal/booking/Store/ListStores${formatParams(params)}`)
        }
    
        
     
    
    