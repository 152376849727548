/* eslint-disable */
/// This file is auto generated - do not edit manually
export const NoBookingsHeading = 'Website.Booking.MyProfile.BookingList.NoBookingsHeading';
export const FutureBookingsHeading = 'Website.Booking.MyProfile.BookingList.FutureBookingsHeading';
export const FutureBookingsHeadlineLink = 'Website.Booking.MyProfile.BookingList.FutureBookingsHeadlineLink';
export const PreviousBookingsHeading = 'Website.Booking.MyProfile.BookingList.PreviousBookingsHeading';
export const NoFutureBookingsHeading = 'Website.Booking.MyProfile.BookingList.NoFutureBookingsHeading';
export const LinkToConsultationAndEvents = 'Website.Booking.MyProfile.BookingList.LinkToConsultationAndEvents';
export const LoadMore = 'Website.Booking.MyProfile.BookingList.LoadMore';
export const NoBookingDescription = 'Website.Booking.MyProfile.BookingList.NoBookingDescription';
export const NoFutureBookingsDescription = 'Website.Booking.MyProfile.BookingList.NoFutureBookingsDescription';
export const CountdownText = 'Website.Booking.MyProfile.BookingList.CountdownText';
export const CountdownExpiredNotAttendedText = 'Website.Booking.MyProfile.BookingList.CountdownExpiredNotAttendedText';
export const CountdownExpiredAttendedText = 'Website.Booking.MyProfile.BookingList.CountdownExpiredAttendedText';
