import { ColorNeutralWhite, ColorNordicGrey4, Spacing16 } from 'autogen/design-tokens/tokens';
import { MenuViewModel } from 'autogen/swagger/menu';
import Menu from 'components/generic/menu';
import { customScrollbar } from 'modules/helpers/mixins';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
    display: grid;
    grid-auto-rows: min-content;
    padding-block: ${Spacing16};
    position: relative;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: ${ColorNeutralWhite};
    height: 100%;

    ul:last-of-type {
        &:after {
            display: none;
        }
    }

    ${customScrollbar};
`;

const GreyMenu = styled(StyledMenu)`
    background-color: ${ColorNordicGrey4};
`;

const WhiteMenu = styled(StyledMenu)`
    background-color: ${ColorNeutralWhite};
`;

/**
 * MenuStyleRenderer handles the styling variations of Menu that can come from MenuViewModel
 */
export default function MenuStyleRenderer({
    styling,
    children
}: {
    styling: MenuViewModel['styling'];
    children: ReactNode;
}) {
    switch (styling) {
        case 'White':
            return <WhiteMenu shouldFocus>{children}</WhiteMenu>;
        default:
            return <GreyMenu shouldFocus>{children}</GreyMenu>;
    }
}
