
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './myprofilematasplus';

        export type MatasPlusSubscriptionType = components['schemas']['MatasPlusSubscriptionType'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type CancelViewModel = components['schemas']['CancelViewModel'];
                    export type MatasPlusAdvantageType = components['schemas']['MatasPlusAdvantageType'];
                    export type MatasPlusAdvantage = components['schemas']['MatasPlusAdvantage'];
                    export type MatasPlusInfoLineModel = components['schemas']['MatasPlusInfoLineModel'];
                    export type SubscriptionTypeInfoModel = components['schemas']['SubscriptionTypeInfoModel'];
                    export type MatasPlusSubscriptionInfoModel = components['schemas']['MatasPlusSubscriptionInfoModel'];
                    export type SubscriptionInfo = components['schemas']['SubscriptionInfo'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type ConfirmModal = components['schemas']['ConfirmModal'];
                    export type MatasPlusCancelSubscriptionInfo = components['schemas']['MatasPlusCancelSubscriptionInfo'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type PaymentCardOptionModelMaybe = components['schemas']['PaymentCardOptionModelMaybe'];
                    export type MatasPlusCreditCardInfoModel = components['schemas']['MatasPlusCreditCardInfoModel'];
                    export type CreditCardInfo = components['schemas']['CreditCardInfo'];
                    export type MatasPlusSavingsValueType = components['schemas']['MatasPlusSavingsValueType'];
                    export type MatasPlusSavingsValueItem = components['schemas']['MatasPlusSavingsValueItem'];
                    export type MatasPlusSavingsBannerModel = components['schemas']['MatasPlusSavingsBannerModel'];
                    export type MatasPlusSavingsBannerModelMaybe = components['schemas']['MatasPlusSavingsBannerModelMaybe'];
                    export type MatasPlusNudgeMessage = components['schemas']['MatasPlusNudgeMessage'];
                    export type MatasPlusNudgeMessageMaybe = components['schemas']['MatasPlusNudgeMessageMaybe'];
                    export type MatasPlusChangeSubscriptionTypeModal = components['schemas']['MatasPlusChangeSubscriptionTypeModal'];
                    export type MatasPlusChangeSubscriptionTypeModalMaybe = components['schemas']['MatasPlusChangeSubscriptionTypeModalMaybe'];
                    export type MatasPlusPageModel = components['schemas']['MatasPlusPageModel'];
                    export type MatasPlusPendingConfirmCancellationViewModel = components['schemas']['MatasPlusPendingConfirmCancellationViewModel'];
                    

        export function BasketInfoBoxbuttonsDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}

        
        

        export type ChangeSubscriptionTypeParams = paths['/internal/myprofile/MatasPlus/ChangeSubscriptionType']['post']['parameters']['query'];

        export type ChangeSubscriptionTypeSuccess = paths['/internal/myprofile/MatasPlus/ChangeSubscriptionType']['post']['responses']['200']['content']['text/plain'];

        export const ChangeSubscriptionTypeUrl = '/internal/myprofile/MatasPlus/ChangeSubscriptionType';

        
        export function ChangeSubscriptionType(params: ChangeSubscriptionTypeParams): Promise<ChangeSubscriptionTypeSuccess> {
            return postQuery<ChangeSubscriptionTypeSuccess>(`/internal/myprofile/MatasPlus/ChangeSubscriptionType${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type CancelSubscriptionSuccess = paths['/internal/myprofile/MatasPlus/CancelSubscription']['get']['responses']['200']['content']['text/plain'];

        export const CancelSubscriptionUrl = '/internal/myprofile/MatasPlus/CancelSubscription';

        
        export function CancelSubscription(): Promise<CancelSubscriptionSuccess> {
            return fetchJSON<CancelSubscriptionSuccess>(`/internal/myprofile/MatasPlus/CancelSubscription`)
        }
    
        
     
    
        

        export type ConfirmCancelParams = paths['/internal/myprofile/MatasPlus/ConfirmCancel']['get']['parameters']['query'];

        export type ConfirmCancelSuccess = paths['/internal/myprofile/MatasPlus/ConfirmCancel']['get']['responses']['200']['content']['text/plain'];

        export const ConfirmCancelUrl = '/internal/myprofile/MatasPlus/ConfirmCancel';

        
        export function ConfirmCancel(params: ConfirmCancelParams): Promise<ConfirmCancelSuccess> {
            return fetchJSON<ConfirmCancelSuccess>(`/internal/myprofile/MatasPlus/ConfirmCancel${formatParams(params)}`)
        }
    
        
     
    
        

        

        

        export const UndoCancelSubscriptionUrl = '/internal/myprofile/MatasPlus/UndoCancelSubscription';

        
        export function UndoCancelSubscription(): Promise<unknown> {
            return postQuery<unknown>(`/internal/myprofile/MatasPlus/UndoCancelSubscription`)
        }
    
        
     
    
        

        

        export type GetMatasPlusPageModelSuccess = paths['/internal/myprofile/MatasPlus/GetMatasPlusPageModel']['get']['responses']['200']['content']['text/plain'];

        export const GetMatasPlusPageModelUrl = '/internal/myprofile/MatasPlus/GetMatasPlusPageModel';

        
        export function GetMatasPlusPageModel(): Promise<GetMatasPlusPageModelSuccess> {
            return fetchJSON<GetMatasPlusPageModelSuccess>(`/internal/myprofile/MatasPlus/GetMatasPlusPageModel`)
        }
    
        
     
    
        

        

        export type GetConfirmCancellationModalSuccess = paths['/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmCancellationModalUrl = '/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal';

        
        export function GetConfirmCancellationModal(): Promise<GetConfirmCancellationModalSuccess> {
            return fetchJSON<GetConfirmCancellationModalSuccess>(`/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal`)
        }
    
        
     
    
    