import { useEffect, useState } from 'react';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';
import type { TooltipModelMaybe } from 'autogen/swagger/menu';
import TooltipNotificationAtom from 'components/shared/TooltipNotification/shared/recoil/atom';
import { useRecoilValue } from 'recoil';
import MyProfileTooltip from 'components/shared/TooltipNotification/MyProfileTooltip';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import { useTranslation } from 'react-i18next';
import { LogIn, MyProfile as MyProfileTrans } from 'autogen/translation-keys/trans-website-shared';
import SessionMenuItem from 'components/SessionMenu/components/SessionMenuItem';
import type { MyProfileViewModel } from 'autogen/swagger/layout';
import useLoginLink from 'hooks/user/useLoginLink';
import Default from 'modules/modal/layouts';
import loadHtmlModalFunc from 'modules/modal/helpers';
import { bindComponents } from 'modules/modalLink';
import Person from 'icons/Person.svg?react';
import PersonWithNudge from 'icons/PersonWithNudge.svg?react';
import { firstCharacterToLower } from 'modules/helpers/strings';
import { GA4Tracking } from 'modules/tracking/GA4';
import { HeaderItemText } from './shared/styles';
import { useDynamicStorage } from 'hooks/storage';
import styled from 'styled-components';
import { ColorSystemNegative2 } from 'autogen/design-tokens/tokens';

const IconWithNudgeWrapper = styled(Person)`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: -2px;
        right: -1px;
        

        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: ${ColorSystemNegative2};       
    }   
`;

function useLoginUrlTemplate(loginUrlTemplate: string): string {
    const [loginUrl, setLoginUrl] = useState(loginUrlTemplate);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect((): void => {
        const returnUrl = window.encodeURIComponent(window.location.href.substring(window.location.origin.length));

        setLoginUrl(`${loginUrl}&returnurl=${returnUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loginUrl;
}

export default function MyProfile({
    model: { myProfileUrl, loginRequest },
    tooltip
}: {
    model: MyProfileViewModel;
    tooltip?: TooltipModelMaybe;
}) {
    const { t } = useTranslation();
    const isOpen = tooltip ? useRecoilValue(TooltipNotificationAtom(tooltip.tooltipType)) : false;

    const isAuthenticated = useIsAuthenticated();
    const { loginModal } = useLoginLink();
    const { loginUrl } = loginRequest;
    const loginUrlTemplate = useLoginUrlTemplate(loginUrl ?? '');
    const [showNudgingStorage, setShowNudgingStorage] = useDynamicStorage(
        'sessionStorage',
        'shouldShowLoginNudgeKey',
        !isAuthenticated
    );
    const [showNudging, setShowNudging] = useState(false);

    if (!loginUrl && loginModal) {
        return (
            <SessionMenuItem
                icon={<Person aria-hidden />}
                as="button"
                onClick={async () => {
                    const htmlModal = await loadHtmlModalFunc();
                    const modal = htmlModal({ layout: { component: <Default /> } });
                    modal({ url: loginModal.url, onAfterContent: bindComponents });

                    UAEventTracking({
                        eventCategory: 'header',
                        eventAction: firstCharacterToLower(loginModal.trackingAction) as TrackingAction
                    });

                    GA4Tracking({
                        eventName: 'open_sso_modal',
                        context: 'header',
                        category: 'login'
                    });
                }}
            >
                <HeaderItemText>{t(LogIn)}</HeaderItemText>
            </SessionMenuItem>
        );
    }

    useEffect(() => {
        if (!isAuthenticated && showNudgingStorage) {
            setShowNudging(true);
        }
    }, [isAuthenticated, showNudgingStorage]);

    if (!isAuthenticated) {
        return (
            <MyProfileTooltip model={tooltip}>
                <SessionMenuItem
                    icon={showNudging ? <PersonWithNudge /> : <Person />}
                    href={loginUrlTemplate}
                    onClick={() => {
                        setShowNudgingStorage(false);

                        UAEventTracking({
                            eventCategory: 'header',
                            eventAction: 'goToLogin',
                            eventLabel: isOpen ? 'TooltipActive' : undefined
                        });

                        GA4Tracking({
                            eventName: 'open_sso_modal',
                            context: 'header',
                            category: 'login'
                        });
                    }}
                >
                    <HeaderItemText>{t(LogIn)}</HeaderItemText>
                </SessionMenuItem>
            </MyProfileTooltip>
        );
    }

    return (
        <SessionMenuItem
            icon={<Person />}
            href={myProfileUrl}
            onClick={() => {
                UAEventTracking({
                    eventCategory: 'header',
                    eventAction: 'goToMyProfile'
                });

                GA4Tracking({
                    eventName: 'go_to_my_matas',
                    context: 'header',
                    category: 'navigation',
                    eventParams: [{ param_name: 'link_type', param_value: 'internal' }]
                });
            }}
        >
            <HeaderItemText>{t(MyProfileTrans)}</HeaderItemText>
        </SessionMenuItem>
    );
}
