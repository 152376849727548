import { ConsultationProductListModel } from 'autogen/swagger/booking';
import {
    Consultation,
    Virtual,
    Event
} from 'autogen/translation-keys/trans-website-booking-consultationlist-consultationitem';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/prefer-default-export
export function useGetBookingTypeTrans({
    isVirtual,
    bookingType
}: Pick<ConsultationProductListModel, 'bookingType' | 'isVirtual'>) {
    const { t } = useTranslation();

    if (isVirtual) {
        return t(Virtual);
    }

    switch (bookingType) {
        case 'Single':
            return t(Consultation);
        case 'Event':
        default:
            return t(Event);
    }
}
