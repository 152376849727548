/*

The main JS file.

*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import polyfill from 'polyfills/index';
import { fetchModule, loadModule, $loadClass, loadClass } from 'modules/helpers/moduleLoaders';
import clickTracking from 'modules/tracking/clickTracking';
import anchorScroll from 'modules/anchorScroll';
import HandleEpaperSession from 'views/epaper/modules/handleEpaperSession';
import GetQuantities from 'modules/getQuantities';
import { setRoutesData } from 'modules/routes';
import FeatureToggles, { setFeatureToggles } from 'modules/featureToggles';
import UserContext, { setUserContext } from 'modules/userContext';
import { setRequestContext } from 'modules/requestContext';
import WebsiteSettings, { setWebsiteSettings } from 'modules/websiteSettings';
import mediator from 'modules/mediator';
import 'modules/serverSideTracking';
import googleAnalytics from 'modules/ga';
import { dataLayer } from 'modules/tracking/dataLayer';
import { AddAndRemoveFromBasket } from 'modules/addAndRemoveFromBasket';
import AddBundleToBasket from 'modules/addBundleToBasket';
import { Growl } from 'modules/growl';
import { LoadingSpinner } from 'modules/loadingSpinner';
import { setWebsiteTime } from 'modules/websiteTime';
import i18nSetup from 'setup/i18next';
import { openModalFromQuery } from 'modules/modalLink';
import { BrowserRouter } from 'react-router-dom';
import queryClient from 'setup/react-query/queryClient';
import { WrapAllComponents } from '../esm/setup/recoil';
import loadZendesk from '../esm/components/chat/zendesk';
import * as Components from './components';

import('tippy.js/dist/tippy.css');

window.React = React;
window.ReactDOM = ReactDOM;

i18nSetup({
    da: {
        translation: window.Translations
    }
});

class Site {
    constructor(global) {
        this.global = global;
        this.cookieName = 'cookieClose';

        setRoutesData(this.global.Routes);
        setFeatureToggles(this.global.FeatureToggles);
        setUserContext(this.global.UserContextModel);
        setRequestContext(this.global.RequestContextModel);
        setWebsiteSettings(this.global.WebsiteSettings);
        setWebsiteTime(this.global.WebsiteTime);
        polyfill(this.global.javascriptRequestDataModel.segmentation.culture);
    }

    initRecoil() {
        window.Components = WrapAllComponents(
            Components,
            this.global.javascriptRequestDataModel,
            BrowserRouter,
            queryClient
        );
    }

    init() {
        const { userMessage: userContextMessage } = UserContext;

        loadModule('#jsReactQuickSearch', () => import(/* webpackChunkName: "quicksearch" */ './app'));

        // mediator.subscribe needs to load before mediator publish
        mediator.subscribe('growl', (obj) => {
            const notify = new Growl(obj);
            notify.init();
        });

        loadModule(
            '.js-customizeProduct',
            () => import(/* webpackChunkName: "customizeProduct" */ 'components/customizeProduct'),
            {
                onClick: true
            }
        );

        const getQuantities = new GetQuantities();
        getQuantities.init();

        $loadClass('.header', () => import(/* webpackChunkName: "header" */ 'modules/header'));

        $loadClass('.js-secondaryMenu', () => import(/* webpackChunkName: "secondaryMenu" */ 'modules/secondaryMenu'));

        $loadClass(
            '[data-js="productReviews"]',
            () => import(/* webpackChunkName: "productReviews" */ 'modules/productReviews'),
            { idle: true }
        );
        loadModule('.js-productRegime', () => import(/* webpackChunkName: "productRegime" */ 'modules/productRegime'), {
            idle: true
        });
        fetchModule('.js-hyphen', () => import(/* webpackChunkName: "hyphen" */ 'modules/hyphen'), {
            idle: true
        }).then(({ Module: hyphen, nodes }) => {
            hyphen(nodes);
        });

        fetchModule(
            '.js-search-result-paging',
            () => import(/* webpackChunkName: "searchResultPaging" */ 'modules/searchResult/paging')
        ).then(({ Module: searchResultPaging, nodes }) => {
            nodes.forEach((node) => {
                searchResultPaging(node);
            });
        });

        fetchModule('body.epaper', () => import(/* webpackChunkName: "epaper" */ 'views/epaper/index')).then(
            ({ Module: epaper }) => {
                epaper();
            }
        );

        // Opens modal on click event
        loadModule(
            '[data-js-modal-url], [data-js*="genericLoginModal"], [data-js-modal-html]',
            () => import(/* webpackChunkName: "modalLink" */ 'modules/modalLink'),
            { onClick: true }
        );

        // Opens modal from URL query parameter
        openModalFromQuery('[data-js-modal-url], [data-js*="genericLoginModal"], [data-js-modal-html]');

        $loadClass(
            '[data-js="productPage"]',
            () => import(/* webpackChunkName: "productPage" */ 'modules/productPage')
        );

        // Sliders START
        if (document.getElementsByClassName('js-sliderProduct').length !== 0) {
            (async () => {
                const { default: ProductSlider } = await import(
                    /* webpackChunkName: "productSlider" */ 'modules/slider/productSlider'
                );
                ProductSlider.bind();
            })();
        }

        $loadClass(
            '.js-sliderCarusel',
            () => import(/* webpackChunkName: "caruselSlider" */ 'modules/slider/caruselSlider'),
            {
                idle: true
            }
        );

        $loadClass(
            '.js-sliderPDPDesktop',
            () =>
                import(/* webpackChunkName: "productImageSliderDesktop" */ 'modules/slider/productImageSliderDesktop'),
            {
                idle: true
            }
        );

        $loadClass(
            '.js-sliderPDPMobile',
            () => import(/* webpackChunkName: "productImageSliderMobile" */ 'modules/slider/productImageSliderMobile'),
            {
                idle: true
            }
        );

        // Sliders End

        loadClass('[data-js="submenu"]', () => import(/* webpackChunkName: "subMenu" */ 'modules/submenu'), {
            idle: true
        });

        $loadClass('.js-selection', () => import(/* webpackChunkName: "selection" */ 'modules/selection'), {
            idle: true
        });

        loadClass('[data-js="brand-list"]', () => import(/* webpackChunkName: "brandList" */ 'modules/brandList'));

        $loadClass('.js-tooltip', () => import(/* webpackChunkName: "tooltip" */ 'modules/tooltip'), { idle: true });

        loadClass('.js-inputForm', () => import(/* webpackChunkName: "inputForm" */ 'modules/form/input-form'));

        fetchModule('#basketpage', () => import(/* webpackChunkName: "Basket" */ 'views/basket/index')).then(
            ({ Module: Basket, nodes }) => {
                Basket($(nodes));
            }
        );

        loadModule('.js-wrapping', () => import(/* webpackChunkName: "wrapping" */ 'views/basket/wrapping'), {
            onClick: true
        });

        loadModule(
            '.js-swapCertificate',
            () => import(/* webpackChunkName: "swapCertificate" */ 'views/basket/wrapping/swapCertificate'),
            { onClick: true }
        );

        $loadClass(
            '[data-js="product-row:wrap"]',
            () => import(/* webpackChunkName: "productRow" */ 'modules/productRow'),
            { idle: true }
        );

        $loadClass(
            '[data-js=product-reviews-form]',
            () => import(/* webpackChunkName: "productReviewCreate" */ 'modules/productReviewCreate'),
            { idle: true }
        );

        $loadClass(
            '[data-js="btnCart"]',
            () => import(/* webpackChunkName: "minibasketDialog" */ 'modules/minibasketDialog'),
            { idle: true }
        );

        loadModule(
            '[data-js-addtofavourite]',
            () => import(/* webpackChunkName: "addToFavorite" */ 'modules/addToFavorite'),
            { onClick: true }
        );

        loadClass(
            '.js-rating-selection',
            () => import(/* webpackChunkName: "ratingSelection" */ 'modules/ratingSelection'),
            { idle: true }
        );

        loadClass('[data-js-tabmenu-id]', () => import(/* webpackChunkName: "tabMenu" */ 'modules/tabMenu'));

        if (UserContext.permissionRequired) {
            (async () => {
                const { default: PermissionCompliance } = await import(
                    /* webpackChunkName: "permissionCompliance" */ 'modules/permissionCompliance'
                );
                const permissionCompliance = new PermissionCompliance();
                permissionCompliance.init();
            })();
        }

        loadClass('.js-form-submit', () => import(/* webpackChunkName: "formSubmit" */ 'modules/formSubmit'));

        $loadClass(
            '[data-js="prizeCertificate"]',
            () => import(/* webpackChunkName: "prizeCertificate" */ 'modules/prizeCertificate')
        );

        fetchModule('#js-myProfile', () => import(/* webpackChunkName: "myProfile" */ 'views/myprofile/index')).then(
            ({ Module: myProfile, nodes }) => {
                myProfile($(nodes));
            }
        );

        loadClass(
            '[data-js="youtube:block"]',
            () => import(/* webpackChunkName: "youtube" */ 'views/page/block/youtube')
        );

        loadClass('[data-js="vimeo:block"]', () => import(/* webpackChunkName: "vimeo" */ 'views/page/block/vimeo'));

        loadModule('[data-js-vimeo-id]', () => import(/* webpackChunkName: "vimeoInfo" */ 'modules/vimeoInfo'), {
            idle: true
        });

        loadModule(
            '.js-productPageInlineVideo',
            () => import(/* webpackChunkName: "inlineVideo" */ 'components/video/inlineVideo'),
            { onClick: true }
        );

        (async () => {
            const cmsArticle = document.querySelector('.cms-article');
            if (!cmsArticle) return;
            const parallaxElements = cmsArticle.querySelectorAll(
                '[data-parallaxbehaviour="FasterThanScroll"], [data-parallaxbehaviour="SlowerThanScroll"], [data-parallaxbehaviour="Fixed"]'
            );
            if (parallaxElements.length > 0) {
                await import(/* webpackChunkName: "cms" */ 'hesehus-js-parallax');

                parallaxElements.forEach((item) => {
                    $(item).hParallax();
                });
            }
        })();

        loadClass('[data-js="gwp-list"]', () => import(/* webpackChunkName: "chooseGift" */ 'modules/chooseGift'));

        if (userContextMessage?.url?.value.length > 0) {
            (async () => {
                const { default: userMessage } = await import(
                    /* webpackChunkName: "userMessage" */ 'modules/userMessage'
                );
                userMessage();
            })();
        }

        $loadClass(
            '[data-js-async-product-recommendations]',
            () => import(/* webpackChunkName: "AjaxProductRecommendations" */ 'modules/ajaxProductRecommendations'),
            { idle: false }
        );

        loadModule('.js-asideMenu', () => import(/* webpackChunkName: "asideMenu" */ 'modules/asideMenu'));

        loadModule(
            '.js-questionnaire',
            () => import(/* webpackChunkName: "questionnaire" */ './modules/questionnaire')
        );

        loadModule(
            '.js-questionnaireStart',
            () => import(/* webpackChunkName: "questionnaire_consent" */ './modules/questionnaire/consent.js')
        );

        loadModule(
            '.js-questionnaireRecommendation',
            () => import(/* webpackChunkName: "questionnaire_recommendation" */ 'modules/questionnaire/recommendation')
        );

        loadClass('.js-delete-element', () => import(/* webpackChunkName: "deleteElement" */ 'modules/deleteElement'));

        if (new URLSearchParams(window.location.search).get('openSamples') === 'True') {
            import(/* webpackChunkName: "openSamplesModal" */ 'components/samples/components/samplesPicker').then(
                ({ openSamplesModal }) => {
                    openSamplesModal('page load open');
                }
            );
        }

        loadClass(
            '[data-js*="InputWithLabel"]',
            () => import(/* webpackChunkName: "input_w_placeholder" */ 'modules/input-w-placeholder'),
            {
                idle: true
            }
        );

        loadClass('.js-breadcrumb', () => import(/* webpackChunkName: "breadcrumb" */ 'modules/breadcrumb'), {
            idle: true
        });

        fetchModule('.js-history-back', () => import(/* webpackChunkName: "goBack" */ 'modules/goBackButton'), {
            idle: true
        }).then(({ Module: goBackButton, nodes }) => goBackButton(nodes));

        $loadClass('[data-js=toTop]', () => import(/* webpackChunkName: "toTop" */ 'modules/toTop'), { idle: true });

        loadClass(
            '[data-js-product-notification]',
            () => import(/* webpackChunkName: "productNotification" */ 'modules/productNotification'),
            {
                idle: true
            }
        );

        loadModule('[data-js-lineclamp]', () => import(/* webpackChunkName: "lineClamp" */ 'modules/lineClamp'), {
            idle: true
        });

        $loadClass(
            '.js-gift-card-balance',
            () => import(/* webpackChunkName: "giftCardBalance" */ 'modules/giftCardBalance')
        );

        fetchModule('.offer-counter', () => import(/* webpackChunkName: "offerCounter" */ 'modules/offerCounter'), {
            idle: true
        }).then(({ Module: OfferCounter }) => {
            const offerCounter = new OfferCounter();
            offerCounter.init();
        });

        loadClass(
            '[data-js-personal-offer="wrapper"]',
            () => import(/* webpackChunkName: "personalOffer" */ 'modules/personalOffer'),
            {
                idle: true
            }
        );

        loadClass('.js-readMore', () => import(/* webpackChunkName: "readmore" */ 'modules/readmore'));

        loadClass(
            '[data-js="paymentToggleOrder"]',
            () => import(/* webpackChunkName: "payment" */ 'views/checkout/payment'),
            { idle: true }
        );

        fetchModule(
            '[data-js*="simpleToggle:btn"]',
            () => import(/* webpackChunkName: "simpleToggle" */ 'modules/simpleToggle'),
            { idle: true }
        ).then(({ Module: SimpleToggle }) => SimpleToggle.bind());

        fetchModule('.js-loadBambuser', () => import(/* webpackChunkName: "bambuser" */ 'modules/bambuser'), {
            idle: true
        }).then(({ Module: Bambuser }) => Bambuser());

        if (FeatureToggles.DebugViewEnabled) {
            loadClass(
                '[data-js="debugProduct"]',
                () => import(/* webpackChunkName: "debugProduct" */ 'modules/debugProduct')
            );
        }

        loadModule(
            '.js-explanationOpenModal',
            () => import(/* webpackChunkName: "explanationModal" */ 'modules/visualSearch/explanationModal'),
            {
                idle: true
            }
        );

        loadModule('.js-gwpModalButton', () => import(/* webpackChunkName: "openGWPModal" */ 'modules/openGWPModal'), {
            idle: true
        });

        loadModule(
            '.js-liveEventBanner',
            () => import(/* webpackChunkName: "liveEventBanner" */ 'modules/liveEvents/liveEventBanner'),
            {
                idle: true
            }
        );

        loadModule(
            '.js-findInStoreLink',
            () => import(/* webpackChunkName: "findInStore" */ 'modules/findStore/findInStore'),
            { idle: true }
        );

        loadModule(
            '.js-autocompleteContainer',
            () => import(/* webpackChunkName: "dawaAutocomplete" */ 'modules/dawaAutocomplete'),
            { idle: true }
        );

        loadModule(
            '.js-highlight-countdown-container',
            () => import(/* webpackChunkName: "highlightCountdown" */ 'modules/highlightCountdown'),
            { idle: false }
        );

        loadModule(
            '.js-editPaymentCard',
            () => import(/* webpackChunkName: "editPaymentCardModal" */ 'components/editPaymentCardModal'),
            { onClick: true }
        );

        loadModule(
            '.js-progressiveDiscountCheckoutModal',
            () =>
                import(
                    /* webpackChunkName: "progressiveDiscountOpenCheckoutModal" */ 'modules/progressiveDiscount/openCheckoutModal'
                ),
            { onClick: true }
        );

        loadModule(
            '.js-beautytrendboxNotifyModal',
            () => import(/* webpackChunkName: "btbNotifyModal" */ 'components/beautyTrendBox/notifyModal'),
            { onClick: true }
        );

        fetchModule(
            '.js-beautytrendboxNotifyModal',
            () => import(/* webpackChunkName: "btbWatchParam" */ 'components/beautyTrendBox/watchParam')
        ).then(({ Module: watchOpenParam }) => watchOpenParam());

        loadModule(
            '.js-customButtonSurvey',
            () => import(/* webpackChunkName: "" */ 'components/surveySystem/modules/surveyModal'),
            { onClick: true }
        );

        fetchModule(
            '.js-adformClickTracker',
            () => import(/* webpackChunkName: "adformClickTracking" */ 'modules/tracking/adformClickTracking')
        ).then(({ Module: adformClickTracking, nodes }) => {
            adformClickTracking(nodes);
        });

        const loadingspinner = new LoadingSpinner();
        loadingspinner.init();

        const handleEpaperSession = new HandleEpaperSession();
        handleEpaperSession.checkIfInSession();

        anchorScroll();

        $('#presscloudiframe').each(() => {
            // This script function is loaded in placeholder from CMS, so you can't find the function in the code
            iFrameResize({
                heightCalculationMethod: 'taggedElement',
                checkOrigin: false
            });
        });

        const addAndRemoveFromBasket = new AddAndRemoveFromBasket();
        addAndRemoveFromBasket.init();

        const addBundleToBasket = new AddBundleToBasket();
        addBundleToBasket.init();

        clickTracking();

        this.bindings();

        if ($('.js-pinterestPin').length) {
            this.loadScript('//assets.pinterest.com/js/pinit.js');
        }

        if (WebsiteSettings.LiveChat.CustomerServiceChatOnlyEnabled) {
            loadZendesk();
        }
    }

    bindings() {
        this.setupTracking();
    }

    loadScript(url, callback) {
        jQuery.ajax({
            url,
            dataType: 'script',
            success: callback,
            async: true,
            defer: true
        });
    }

    setupTracking() {
        googleAnalytics.setupTrackingId(this.global.Routes.Tracking.GoogleAnalyticsTrackingId);
        dataLayer.setupTracking();
    }
}

window.hesehusSite = Site;
