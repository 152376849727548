import styled from 'styled-components';
import { actionWrapperStyling } from '../shared/styles';
import type { ListItemType, Size } from '../shared/types';

const Anchor = styled.a<Pick<ListItemType, 'size'>>`
    ${actionWrapperStyling}

    &, &:hover, &:focus {
        text-decoration: none;
    }
`;

function AnchorWrapper(props: React.ComponentPropsWithoutRef<'a'> & { size?: Size }) {
    const { children, size, ...restProps } = props;

    return (
        <Anchor size={size} {...restProps} tabIndex={0}>
            {children}
        </Anchor>
    );
}

export default AnchorWrapper;
