import React from 'react';
import dynamic from 'helpers/dynamic';
import Spinner from 'modules/loadingSpinner';
import { growlGeneralExceptionError } from 'modules/growl';
import { Create } from 'autogen/swagger/survey';
import { postAdformClickTracking } from 'modules/tracking/adformClickTracking';

export const openSurveyModal = async (surveyId: number, returnUrl?: string): Promise<void> => {
    let modal;

    try {
        const { default: createModal } = await import(/* webpackChunkName: "modal" */ 'modules/modal');
        const { default: HeroHeader } = await import(
            /* webpackChunkName: "heroHeader" */ 'modules/modal/components/heroHeader'
        );
        const DrawerLayout = dynamic(() => import('modules/modal/layouts/drawerLayout'));

        const model = await Create({ surveyId, returnUrl: returnUrl || window.location.href });

        const { backgroundImage } = model;

        modal = createModal({
            layout: {
                component: <DrawerLayout />,
                customTheme: { maxWidth: '600px', height: '600px' },
                header: <HeroHeader image={backgroundImage} close={() => modal({ open: false })} />
            }
        });
        modal({ open: true, content: <Spinner /> });

        const Survey = dynamic(() => import(/* webpackChunkName: "survey" */ 'components/surveySystem/modules/survey'));
        await Survey;

        modal({
            content: <Survey model={model} />
        });
    } catch (err) {
        modal({ open: false });
        console.error(err);
        growlGeneralExceptionError();
    }
};

export function handleClick(maybeLink, adformClickTrackingUrl: string | undefined): void {
    const { linkElement, surveyId } = maybeLink || {};
    const { link } = linkElement || {};

    if (link && link.url) {
        if (adformClickTrackingUrl) {
            postAdformClickTracking(adformClickTrackingUrl);
        }

        const { url, target } = link || {};

        Object.assign(document.createElement('a'), { href: url, target }).click();
    } else if (surveyId) {
        openSurveyModal(surveyId);
    }
}
