import React from 'react';
import styled from 'styled-components';
import { BorderRadiusMedium, ColorNeutralWhite } from 'autogen/design-tokens/tokens';

const StyledCardShell = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: ${BorderRadiusMedium};
    overflow: hidden;
    width: 100%;
    background-color: ${({ theme }) => theme?.backgroundColor || ColorNeutralWhite};
`;

const CardShell = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
    ({ children, ...restProps }, ref) => (
        <StyledCardShell ref={ref} {...restProps}>
            {children}
        </StyledCardShell>
    )
);

export default CardShell;
