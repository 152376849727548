
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './summary';

        export type CheckoutTrackingInfo = components['schemas']['CheckoutTrackingInfo'];
                    export type CheckoutUserCommentInfo = components['schemas']['CheckoutUserCommentInfo'];
                    export type CheckoutOtcTerms = components['schemas']['CheckoutOtcTerms'];
                    export type CheckoutPermissionInfo = components['schemas']['CheckoutPermissionInfo'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type CheckoutBasketProgressiveDiscountViewModel = components['schemas']['CheckoutBasketProgressiveDiscountViewModel'];
                    export type CheckoutBasketProgressiveDiscountViewModelMaybe = components['schemas']['CheckoutBasketProgressiveDiscountViewModelMaybe'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type CheckoutBasketTriggeredDiscountViewModel = components['schemas']['CheckoutBasketTriggeredDiscountViewModel'];
                    export type CheckoutBasketPotentialDiscountViewModel = components['schemas']['CheckoutBasketPotentialDiscountViewModel'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type CheckoutBasketDiscountInfoViewModel = components['schemas']['CheckoutBasketDiscountInfoViewModel'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionInfoViewModelMaybe = components['schemas']['SubscriptionInfoViewModelMaybe'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type CheckoutBasketLineViewModel = components['schemas']['CheckoutBasketLineViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type SubscriptionInfoModel = components['schemas']['SubscriptionInfoModel'];
                    export type SubscriptionInfoModelMaybe = components['schemas']['SubscriptionInfoModelMaybe'];
                    export type CheckoutBasketBookingLineViewModel = components['schemas']['CheckoutBasketBookingLineViewModel'];
                    export type CheckoutBasketGiftLineViewModel = components['schemas']['CheckoutBasketGiftLineViewModel'];
                    export type CheckoutBasketSampleLineViewModel = components['schemas']['CheckoutBasketSampleLineViewModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type IBasketInfoBoxMaybe = components['schemas']['IBasketInfoBoxMaybe'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type CheckoutBasketGroupViewModel = components['schemas']['CheckoutBasketGroupViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type BasketGiftCardSummary = components['schemas']['BasketGiftCardSummary'];
                    export type BasketGiftCardSummaryMaybe = components['schemas']['BasketGiftCardSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type BasketSummaryViewModel = components['schemas']['BasketSummaryViewModel'];
                    export type CheckoutGroupedBasketViewModel = components['schemas']['CheckoutGroupedBasketViewModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type GoToPaymentButtonViewModel = components['schemas']['GoToPaymentButtonViewModel'];
                    export type CheckoutSummaryViewModel = components['schemas']['CheckoutSummaryViewModel'];
                    export type CheckoutPermissionInfoCore = components['schemas']['CheckoutPermissionInfoCore'];
                    export type CheckoutSummaryPostModel = components['schemas']['CheckoutSummaryPostModel'];
                    export type CheckoutGroupedBasketViewModelMaybe = components['schemas']['CheckoutGroupedBasketViewModelMaybe'];
                    export type GroupedBasketResultModel = components['schemas']['GroupedBasketResultModel'];
                    export type RedirectGroupedBasketResultModel = components['schemas']['RedirectGroupedBasketResultModel'];
                    

        export function BasketInfoBoxbuttonsDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function CheckoutSummaryViewModelinfoBoxesDiscriminator<T>(
    model: components['schemas']['CheckoutSummaryViewModel']['infoBoxes'][0],
    funcCheckoutGiftCardInfo: (m: components['schemas']['CheckoutGiftCardInfo']) => T,
    funcCheckoutPaymentTypeList: (m: components['schemas']['CheckoutPaymentTypeList']) => T,
    funcBasketInfoBox: (m: components['schemas']['BasketInfoBox']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutGiftCardInfo': return funcCheckoutGiftCardInfo(model as components['schemas']['CheckoutGiftCardInfo']);
        case 'CheckoutPaymentTypeList': return funcCheckoutPaymentTypeList(model as components['schemas']['CheckoutPaymentTypeList']);
        case 'BasketInfoBox': return funcBasketInfoBox(model as components['schemas']['BasketInfoBox']);

        default:
            return defaultFunc();
    }
}export function getGetGroupedBasketDiscriminator<T>(
    model: paths['/internal/checkout/Summary/GetGroupedBasket']['get']['responses']['200']['content']['text/plain'],
    funcGroupedBasketResultModel: (m: components['schemas']['GroupedBasketResultModel']) => T,
    funcRedirectGroupedBasketResultModel: (m: components['schemas']['RedirectGroupedBasketResultModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'GroupedBasketResultModel': return funcGroupedBasketResultModel(model as components['schemas']['GroupedBasketResultModel']);
        case 'RedirectGroupedBasketResultModel': return funcRedirectGroupedBasketResultModel(model as components['schemas']['RedirectGroupedBasketResultModel']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type CheckoutSummaryViewModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryViewModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryViewModelUrl = '/internal/ModelOnly/CheckoutSummaryViewModel';

        
        export function CheckoutSummaryViewModel(): Promise<CheckoutSummaryViewModelSuccess> {
            return fetchJSON<CheckoutSummaryViewModelSuccess>(`/internal/ModelOnly/CheckoutSummaryViewModel`)
        }
    
        
     
    
        

        

        export type CheckoutSummaryPostModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryPostModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryPostModelUrl = '/internal/ModelOnly/CheckoutSummaryPostModel';

        
        export function CheckoutSummaryPostModel(): Promise<CheckoutSummaryPostModelSuccess> {
            return fetchJSON<CheckoutSummaryPostModelSuccess>(`/internal/ModelOnly/CheckoutSummaryPostModel`)
        }
    
        
     
    
        

        

        export type GetGroupedBasketSuccess = paths['/internal/checkout/Summary/GetGroupedBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetGroupedBasketUrl = '/internal/checkout/Summary/GetGroupedBasket';

        
        export function GetGroupedBasket(): Promise<GetGroupedBasketSuccess> {
            return fetchJSON<GetGroupedBasketSuccess>(`/internal/checkout/Summary/GetGroupedBasket`)
        }
    
        
     
    
        

        export type UpdateHasUserCommentParams = paths['/internal/checkout/Summary/UpdateHasUserComment']['post']['parameters']['query'];

        

        export const UpdateHasUserCommentUrl = '/internal/checkout/Summary/UpdateHasUserComment';

        
        export function UpdateHasUserComment(params: UpdateHasUserCommentParams): Promise<unknown> {
            return postQuery<unknown>(`/internal/checkout/Summary/UpdateHasUserComment${formatParams(params)}`)
        }
    
        
     
    
        

        export type ActivateGiftCardParams = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['parameters']['query'];

        export type ActivateGiftCardSuccess = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivateGiftCardUrl = '/internal/checkout/Summary/ActivateGiftCard';

        
        export function ActivateGiftCard(params: ActivateGiftCardParams): Promise<ActivateGiftCardSuccess> {
            return postQuery<ActivateGiftCardSuccess>(`/internal/checkout/Summary/ActivateGiftCard${formatParams(params)}`)
        }
    
        
     
    
        

        export type RemoveGiftCardLineParams = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['parameters']['query'];

        export type RemoveGiftCardLineSuccess = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const RemoveGiftCardLineUrl = '/internal/checkout/Summary/RemoveGiftCardLine';

        
        export function RemoveGiftCardLine(params: RemoveGiftCardLineParams): Promise<RemoveGiftCardLineSuccess> {
            return postQuery<RemoveGiftCardLineSuccess>(`/internal/checkout/Summary/RemoveGiftCardLine${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type ActivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/ActivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivatePaymentByGiftCardUrl = '/internal/checkout/Summary/ActivatePaymentByGiftCard';

        
        export function ActivatePaymentByGiftCard(): Promise<ActivatePaymentByGiftCardSuccess> {
            return postQuery<ActivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/ActivatePaymentByGiftCard`)
        }
    
        
     
    
        

        

        export type DeactivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/DeactivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const DeactivatePaymentByGiftCardUrl = '/internal/checkout/Summary/DeactivatePaymentByGiftCard';

        
        export function DeactivatePaymentByGiftCard(): Promise<DeactivatePaymentByGiftCardSuccess> {
            return postQuery<DeactivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/DeactivatePaymentByGiftCard`)
        }
    
        
     
    
        

        

        export type AddGiftCardLineSuccess = paths['/internal/checkout/Summary/AddGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const AddGiftCardLineUrl = '/internal/checkout/Summary/AddGiftCardLine';

        
        export function AddGiftCardLine(): Promise<AddGiftCardLineSuccess> {
            return postQuery<AddGiftCardLineSuccess>(`/internal/checkout/Summary/AddGiftCardLine`)
        }
    
        
     
    
        

        

        export type GetGiftCardInfoSuccess = paths['/internal/checkout/Summary/GetGiftCardInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetGiftCardInfoUrl = '/internal/checkout/Summary/GetGiftCardInfo';

        
        export function GetGiftCardInfo(): Promise<GetGiftCardInfoSuccess> {
            return fetchJSON<GetGiftCardInfoSuccess>(`/internal/checkout/Summary/GetGiftCardInfo`)
        }
    
        
     
    
        

        

        export type GetUserCommentInfoSuccess = paths['/internal/checkout/Summary/GetUserCommentInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetUserCommentInfoUrl = '/internal/checkout/Summary/GetUserCommentInfo';

        
        export function GetUserCommentInfo(): Promise<GetUserCommentInfoSuccess> {
            return fetchJSON<GetUserCommentInfoSuccess>(`/internal/checkout/Summary/GetUserCommentInfo`)
        }
    
        
     
    
    