
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './survey';

        export type SurveyAnswerOptionStyle = components['schemas']['SurveyAnswerOptionStyle'];
                    export type SurveyAnswerOptionInputType = components['schemas']['SurveyAnswerOptionInputType'];
                    export type SurveyAnswerOptionDynamicDataViewModel = components['schemas']['SurveyAnswerOptionDynamicDataViewModel'];
                    export type SurveyAnswerOptionDynamicDataViewModelMaybe = components['schemas']['SurveyAnswerOptionDynamicDataViewModelMaybe'];
                    export type SurveyAnswerOptionViewModel = components['schemas']['SurveyAnswerOptionViewModel'];
                    export type SurveyQuestionViewModel = components['schemas']['SurveyQuestionViewModel'];
                    export type SurveyPageViewModel = components['schemas']['SurveyPageViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type SurveyResponseViewModel = components['schemas']['SurveyResponseViewModel'];
                    export type SurveyAnswerOptionResponseModel = components['schemas']['SurveyAnswerOptionResponseModel'];
                    export type SurveyQuestionResponseModel = components['schemas']['SurveyQuestionResponseModel'];
                    export type SurveyResponsePostModel = components['schemas']['SurveyResponsePostModel'];
                    export type SurveyResponseViewModelMaybe = components['schemas']['SurveyResponseViewModelMaybe'];
                    export type SurveyResponseUpdateResult = components['schemas']['SurveyResponseUpdateResult'];
                    

        

        
        

        export type IndexParams = paths['/internal/surveysystem/SurveyResponse/Index']['get']['parameters']['query'];

        export type IndexSuccess = paths['/internal/surveysystem/SurveyResponse/Index']['get']['responses']['200']['content']['text/plain'];

        export const IndexUrl = '/internal/surveysystem/SurveyResponse/Index';

        
        export function Index(params: IndexParams): Promise<IndexSuccess> {
            return fetchJSON<IndexSuccess>(`/internal/surveysystem/SurveyResponse/Index${formatParams(params)}`)
        }
    
        
     
    
        

        export type CreateParams = paths['/internal/surveysystem/SurveyResponse/Create']['post']['parameters']['query'];

        export type CreateSuccess = paths['/internal/surveysystem/SurveyResponse/Create']['post']['responses']['200']['content']['text/plain'];

        export const CreateUrl = '/internal/surveysystem/SurveyResponse/Create';

        
        export function Create(params: CreateParams): Promise<CreateSuccess> {
            return postQuery<CreateSuccess>(`/internal/surveysystem/SurveyResponse/Create${formatParams(params)}`)
        }
    
        
     
    
        export type UpdateRequestBody = paths['/internal/surveysystem/SurveyResponse/Update']['post']['requestBody']['content']['application/json'];

        

        export type UpdateSuccess = paths['/internal/surveysystem/SurveyResponse/Update']['post']['responses']['200']['content']['text/plain'];

        export const UpdateUrl = '/internal/surveysystem/SurveyResponse/Update';

        
        export function Update(requestBody: UpdateRequestBody): Promise<UpdateSuccess> {
            return postJSON<UpdateSuccess>(`/internal/surveysystem/SurveyResponse/Update`, requestBody)
        }
    
        
     
    
    