import { ColorNeutralWhite, ColorNordicGrey3, ColorNordicGrey1 } from 'autogen/design-tokens/tokens';
import { css, type RuleSet } from 'styled-components';
import { constants } from 'variables';

export function verticalEllipsis(lineClamp: number): RuleSet {
    return css`
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
    `;
}

export const container = css`
    padding: 0 ${constants.gridGutter};
`;

export function row(): RuleSet {
    return css`
        margin-left: -${constants.gridGutter};
        margin-right: -${constants.gridGutter};
    `;
}

export function col(): RuleSet {
    return css`
        padding-right: ${constants.gridGutter};
        padding-left: ${constants.gridGutter};
    `;
}

export function customScrollbar() {
    return css`
        overflow: auto;
        scrollbar-width: thin;
        overscroll-behavior: contain;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar {
            background: ${ColorNeutralWhite};
            border-left: 1px solid ${ColorNordicGrey3};
        }

        &::-webkit-scrollbar-thumb {
            background: ${ColorNordicGrey1};
        }
    `;
}

export function invisibleScrollbar() {
    return css`
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    `;
}

export function removeButtonStyling() {
    return css`
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        outline: inherit;
        text-align: inherit;
    `;
}

export function removeListStyling() {
    return css`
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    `;
}
