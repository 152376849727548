import type { ConnectedContentMenuItemModel } from 'autogen/swagger/menu';
import ArticleCard from 'components/ArticleCard';
import { openSurveyModal } from 'components/connectedContent/shared/callbacks';
import { useQuickView } from 'components/quickView';
import { PictureDeprecated } from 'components/generic/picture';
import QuickViewArticle from 'components/QuickViewArticle/QuickViewArticle';
import { rem } from 'modules/helpers/style';
import styled, { css } from 'styled-components';
import DefaultMenuItem from 'components/generic/menu/components/DefaultMenuItem';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { Spacing6 } from 'autogen/design-tokens/tokens';

const ImageContainer = styled.div`
    min-width: ${rem(80)};
    min-height: ${rem(72)};
    max-width: ${rem(80)};
    max-height: ${rem(72)};

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

const paddingOverwrite = css`
    padding-top: ${Spacing6};
    padding-bottom: ${Spacing6};
`;

const StyledAnchor = styled(DefaultMenuItem.Anchor)`
    a {
        ${paddingOverwrite}
    }
`;

const StyledButton = styled(DefaultMenuItem.Button)`
    button {
        ${paddingOverwrite}
    }
`;

export default function SideMenuArticleItem({ item }: { item: ConnectedContentMenuItemModel }) {
    const { link, heading, text = '', image, isQuickView } = item;

    const linkUrl = link?.linkElement?.link?.url;
    const { toggle } = useQuickView('inlineConnectedContent');

    if (isQuickView && linkUrl) {
        return (
            <>
                {linkUrl && <QuickViewArticle quickViewId="inlineConnectedContent" url={linkUrl} />}

                <StyledButton
                    buttonProps={{
                        onClick: () => {
                            toggle(true);
                            UAEventTracking({ eventCategory: 'sideMenu', eventAction: 'link', eventLabel: heading });
                        }
                    }}
                >
                    <ArticleCard
                        customTheme={{ layoutTheme: 'horizontalSmall' }}
                        textBox={{ title: text, overline: heading }}
                    >
                        <ImageContainer>
                            <PictureDeprecated {...image} />
                        </ImageContainer>
                    </ArticleCard>
                </StyledButton>
            </>
        );
    }

    if (link?.linkElement) {
        const { url, target } = link.linkElement.link;

        return (
            <StyledAnchor
                anchorProps={{ href: url, target }}
                onClick={() => UAEventTracking({ eventCategory: 'sideMenu', eventAction: 'link', eventLabel: heading })}
            >
                <ArticleCard
                    customTheme={{ layoutTheme: 'horizontalSmall' }}
                    textBox={{ title: text, overline: heading }}
                >
                    <ImageContainer>
                        <PictureDeprecated {...image} />
                    </ImageContainer>
                </ArticleCard>
            </StyledAnchor>
        );
    }

    if (link?.surveyId) {
        const { surveyId } = link;

        return (
            <StyledButton
                buttonProps={{
                    onClick: () => {
                        openSurveyModal(surveyId);
                        UAEventTracking({ eventCategory: 'sideMenu', eventAction: 'survey', eventLabel: heading });
                    }
                }}
            >
                <ArticleCard
                    customTheme={{ layoutTheme: 'horizontalSmall' }}
                    textBox={{ title: text, overline: heading }}
                >
                    <ImageContainer>
                        <PictureDeprecated {...image} />
                    </ImageContainer>
                </ArticleCard>
            </StyledButton>
        );
    }

    return null;
}
