import AnchorWrapper from 'components/List/components/AnchorWrapper';
import ButtonWrapper from 'components/List/components/ButtonWrapper';
import ListItem from 'components/List/components/ListItem';
import { ListItemType } from 'components/List/shared/types';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

export interface IAnchorMenuItem extends Omit<ListItemType, 'wrapperSlot'> {
    anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

export function AnchorMenuItem({ anchorProps, size, children, ...restProps }: IAnchorMenuItem) {
    return (
        <ListItem
            size={size}
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<AnchorWrapper size={size} {...anchorProps} />}
        >
            {children}
        </ListItem>
    );
}

export interface IButtonMenuItem extends Omit<ListItemType, 'wrapperSlot'> {
    buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

export function ButtonMenuItem({ buttonProps, size, children, ...restProps }: IButtonMenuItem) {
    return (
        <ListItem
            size={size}
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<ButtonWrapper size={size} {...buttonProps} />}
        >
            {children}
        </ListItem>
    );
}

export default Object.assign(ListItem, {
    Button: ButtonMenuItem,
    Anchor: AnchorMenuItem
});
