import React from 'react';
import styled from 'styled-components';
import { ColorNeutralGrey3 } from 'autogen/design-tokens/tokens';
import type { BasketSummaryViewModel } from 'autogen/swagger/basket';
import SummaryLine from './SummaryLines';
import { AdditionalGiftCardSummaryWithHeading } from './SummaryLines/AdditionalGiftCardSummaryRenderer';
import { useRecoilValue } from 'recoil';
import { requestDataState } from 'recoilState/atoms/requestDataState';

const ListItem = styled.li`
    border-bottom: 1px solid ${ColorNeutralGrey3};
    margin-bottom: 16px;
    padding-bottom: 16px;
    list-style: none;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    ${ListItem}:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export default function BasketSummary({ model }: { model: BasketSummaryViewModel }) {
    const { voucher, shipping, prizeCertificate, giftCard, discount, additional, total, subTotal } = model;

    const {
        featureToggles: { pointAsDiscountEnabled }
    } = useRecoilValue(requestDataState);

    return (
        <List>
            {pointAsDiscountEnabled && prizeCertificate && (
                <ListItem>
                    <SummaryLine.ActivePrizeCertificateRenderer model={prizeCertificate} />
                </ListItem>
            )}

            <ListItem>
                <SummaryLine.SubTotalSummary model={subTotal} />
            </ListItem>

            {discount && (
                <ListItem>
                    <SummaryLine.TotalDiscountSummary model={discount} />
                </ListItem>
            )}

            {additional && (
                <ListItem>
                    <SummaryLine.AdditionalSummaryRenderer model={additional} />
                </ListItem>
            )}
            {shipping && (
                <ListItem>
                    <SummaryLine.ShippingSummary model={shipping} />
                </ListItem>
            )}

            {voucher && (
                <ListItem>
                    <SummaryLine.VoucherSummaryRenderer model={voucher} />
                </ListItem>
            )}

            {!pointAsDiscountEnabled && prizeCertificate && (
                <ListItem>
                    <SummaryLine.ActivePrizeCertificateRenderer model={prizeCertificate} />
                </ListItem>
            )}

            <ListItem>
                <SummaryLine.TotalPriceSummary model={total} />
            </ListItem>

            {giftCard && (
                <ListItem>
                    <AdditionalGiftCardSummaryWithHeading model={giftCard} />
                </ListItem>
            )}
        </List>
    );
}
