import React from 'react';
import { useTranslation } from 'react-i18next';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { useRecoilValue } from 'recoil';
import TooltipNotificationAtom from 'components/shared/TooltipNotification/shared/recoil/atom';
import type { TooltipModelMaybe } from 'autogen/swagger/menu';
import SessionMenuItem from 'components/SessionMenu/components/SessionMenuItem';
import { Rebuy as RebuyTrans } from 'autogen/translation-keys/trans-website-shared-advisormenu';
import RebuyTooltip from 'components/shared/TooltipNotification/RebuyTooltip';
import ArrowLoop from 'icons/ArrowLoop.svg?react';
import { GA4Tracking } from 'modules/tracking/GA4';
import { HeaderItemText } from './shared/styles';

export default function Rebuy({ rebuyUrl, tooltip, ...restProps }: { rebuyUrl: string; tooltip?: TooltipModelMaybe }) {
    const { t } = useTranslation();
    const isOpen = tooltip ? useRecoilValue(TooltipNotificationAtom(tooltip.tooltipType)) : false;

    return (
        <RebuyTooltip model={tooltip}>
            <SessionMenuItem
                {...restProps}
                icon={<ArrowLoop aria-hidden />}
                href={rebuyUrl}
                onClick={() => {
                    UAEventTracking({
                        eventCategory: 'header',
                        eventAction: 'goToRebuyPage',
                        eventLabel: isOpen ? 'TooltipActive' : undefined
                    });

                    GA4Tracking({
                        eventName: 'go_to_rebuy_page',
                        context: 'header',
                        category: 'navigation',
                        eventParams: [{ param_name: 'link_type', param_value: 'internal' }]
                    });
                }}
            >
                <HeaderItemText>{t(RebuyTrans)}</HeaderItemText>
            </SessionMenuItem>
        </RebuyTooltip>
    );
}
