
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './wrapping';

        export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type SwapCertificateModel = components['schemas']['SwapCertificateModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type BasketLineWrapping = components['schemas']['BasketLineWrapping'];
                    export type BasketLineWrappingUpdateViewModel = components['schemas']['BasketLineWrappingUpdateViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type BasketLineGiveAsGiftUpdateViewModel = components['schemas']['BasketLineGiveAsGiftUpdateViewModel'];
                    

        

        
        

        export type IndexParams = paths['/internal/Wrapping/Index']['get']['parameters']['query'];

        export type IndexSuccess = paths['/internal/Wrapping/Index']['get']['responses']['200']['content']['text/plain'];

        export const IndexUrl = '/internal/Wrapping/Index';

        
        export function Index(params: IndexParams): Promise<IndexSuccess> {
            return fetchJSON<IndexSuccess>(`/internal/Wrapping/Index${formatParams(params)}`)
        }
    
        
     
    
        

        export type DeleteAllParams = paths['/internal/Wrapping/DeleteAll']['post']['parameters']['query'];

        export type DeleteAllSuccess = paths['/internal/Wrapping/DeleteAll']['post']['responses']['200']['content']['text/plain'];

        export const DeleteAllUrl = '/internal/Wrapping/DeleteAll';

        
        export function DeleteAll(params: DeleteAllParams): Promise<DeleteAllSuccess> {
            return postQuery<DeleteAllSuccess>(`/internal/Wrapping/DeleteAll${formatParams(params)}`)
        }
    
        
     
    
        export type _UpdateRequestBody = paths['/internal/Wrapping/_Update']['post']['requestBody']['content']['application/json'];

        

        export type _UpdateSuccess = paths['/internal/Wrapping/_Update']['post']['responses']['200']['content']['text/plain'];

        export const _UpdateUrl = '/internal/Wrapping/_Update';

        
        export function _Update(requestBody: _UpdateRequestBody): Promise<_UpdateSuccess> {
            return postJSON<_UpdateSuccess>(`/internal/Wrapping/_Update`, requestBody)
        }
    
        
     
    
    