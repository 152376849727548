
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './progressivediscount';

        export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type ProgressiveDiscountStepViewModel = components['schemas']['ProgressiveDiscountStepViewModel'];
                    export type ProgressiveDiscountDetailsViewModel = components['schemas']['ProgressiveDiscountDetailsViewModel'];
                    

        

        
        

        export type ProgressiveDiscountForProductParams = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountForProductSuccess = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountForProductUrl = '/internal/Discount/ProgressiveDiscountForProduct';

        
        export function ProgressiveDiscountForProduct(params: ProgressiveDiscountForProductParams): Promise<ProgressiveDiscountForProductSuccess> {
            return fetchJSON<ProgressiveDiscountForProductSuccess>(`/internal/Discount/ProgressiveDiscountForProduct${formatParams(params)}`)
        }
    
        
     
    
        

        export type ProgressiveDiscountDetailsForProductParams = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountDetailsForProductSuccess = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountDetailsForProductUrl = '/internal/Discount/ProgressiveDiscountDetailsForProduct';

        
        export function ProgressiveDiscountDetailsForProduct(params: ProgressiveDiscountDetailsForProductParams): Promise<ProgressiveDiscountDetailsForProductSuccess> {
            return fetchJSON<ProgressiveDiscountDetailsForProductSuccess>(`/internal/Discount/ProgressiveDiscountDetailsForProduct${formatParams(params)}`)
        }
    
        
     
    
    