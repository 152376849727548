
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './checkout';

        export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type ChoosablePrizeCertificateModel = components['schemas']['ChoosablePrizeCertificateModel'];
                    export type PointInfo = components['schemas']['PointInfo'];
                    export type BasketPointInfo = components['schemas']['BasketPointInfo'];
                    export type BasketPrizeCertificateModel = components['schemas']['BasketPrizeCertificateModel'];
                    export type OperationResult = components['schemas']['OperationResult'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type CheckoutBasketProgressiveDiscountViewModel = components['schemas']['CheckoutBasketProgressiveDiscountViewModel'];
                    export type CheckoutBasketProgressiveDiscountViewModelMaybe = components['schemas']['CheckoutBasketProgressiveDiscountViewModelMaybe'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type CheckoutBasketTriggeredDiscountViewModel = components['schemas']['CheckoutBasketTriggeredDiscountViewModel'];
                    export type CheckoutBasketPotentialDiscountViewModel = components['schemas']['CheckoutBasketPotentialDiscountViewModel'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type CheckoutBasketDiscountInfoViewModel = components['schemas']['CheckoutBasketDiscountInfoViewModel'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionInfoViewModelMaybe = components['schemas']['SubscriptionInfoViewModelMaybe'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type CheckoutBasketLineViewModel = components['schemas']['CheckoutBasketLineViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type SubscriptionInfoModel = components['schemas']['SubscriptionInfoModel'];
                    export type SubscriptionInfoModelMaybe = components['schemas']['SubscriptionInfoModelMaybe'];
                    export type CheckoutBasketBookingLineViewModel = components['schemas']['CheckoutBasketBookingLineViewModel'];
                    export type CheckoutBasketGiftLineViewModel = components['schemas']['CheckoutBasketGiftLineViewModel'];
                    export type CheckoutBasketSampleLineViewModel = components['schemas']['CheckoutBasketSampleLineViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type BasketGiftCardSummary = components['schemas']['BasketGiftCardSummary'];
                    export type BasketGiftCardSummaryMaybe = components['schemas']['BasketGiftCardSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type BasketSummaryViewModel = components['schemas']['BasketSummaryViewModel'];
                    export type CheckoutDeliveryBasketViewModel = components['schemas']['CheckoutDeliveryBasketViewModel'];
                    export type CityFromZipResult = components['schemas']['CityFromZipResult'];
                    export type CompanyAddress = components['schemas']['CompanyAddress'];
                    export type ParcelShop = components['schemas']['ParcelShop'];
                    export type PrivateAddress = components['schemas']['PrivateAddress'];
                    export type DeliveryType = components['schemas']['DeliveryType'];
                    export type DeliveryMethodTagType = components['schemas']['DeliveryMethodTagType'];
                    export type DeliveryMethodTag = components['schemas']['DeliveryMethodTag'];
                    export type DeliveryMethodTagMaybe = components['schemas']['DeliveryMethodTagMaybe'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type UpsaleDeliveryMethod = components['schemas']['UpsaleDeliveryMethod'];
                    export type DeliveryMethod = components['schemas']['DeliveryMethod'];
                    export type SelectedDeliveryMethod = components['schemas']['SelectedDeliveryMethod'];
                    export type DeliveryMethodCategory = components['schemas']['DeliveryMethodCategory'];
                    export type FixedDeliveryMessage = components['schemas']['FixedDeliveryMessage'];
                    export type FreeDeliveryWithClubMatasMessage = components['schemas']['FreeDeliveryWithClubMatasMessage'];
                    export type SubscriptionsMessage = components['schemas']['SubscriptionsMessage'];
                    export type DeliveryGroup = components['schemas']['DeliveryGroup'];
                    export type DeliveryGroupsModel = components['schemas']['DeliveryGroupsModel'];
                    export type InvoiceAddressPostModel = components['schemas']['InvoiceAddressPostModel'];
                    export type AddressSuggestion = components['schemas']['AddressSuggestion'];
                    export type AddressSuggestionMaybe = components['schemas']['AddressSuggestionMaybe'];
                    export type UpdateInvoiceResponse = components['schemas']['UpdateInvoiceResponse'];
                    export type UpdateAddressResponseMessage = components['schemas']['UpdateAddressResponseMessage'];
                    export type UpdatePrivateAddressResponse = components['schemas']['UpdatePrivateAddressResponse'];
                    export type PrivateAddressPostModel = components['schemas']['PrivateAddressPostModel'];
                    export type CompanyAddressPostModel = components['schemas']['CompanyAddressPostModel'];
                    export type UpdateCompanyAddressResponse = components['schemas']['UpdateCompanyAddressResponse'];
                    export type UpdateParcelShopResponse = components['schemas']['UpdateParcelShopResponse'];
                    export type SelectParcelShopPostModel = components['schemas']['SelectParcelShopPostModel'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type DeliveryParcelShopOption = components['schemas']['DeliveryParcelShopOption'];
                    export type ParcelShopSearchResponseModel = components['schemas']['ParcelShopSearchResponseModel'];
                    export type UpdateUpsaleResponse = components['schemas']['UpdateUpsaleResponse'];
                    export type ShippingType = components['schemas']['ShippingType'];
                    export type ShippingDeliveryType = components['schemas']['ShippingDeliveryType'];
                    export type ShippingManagementSystem = components['schemas']['ShippingManagementSystem'];
                    export type Dimensions = components['schemas']['Dimensions'];
                    export type IShippingBasketConstraints = components['schemas']['IShippingBasketConstraints'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type ShippingProductPrice = components['schemas']['ShippingProductPrice'];
                    export type ShippingPriceIntervalConstraints = components['schemas']['ShippingPriceIntervalConstraints'];
                    export type ShippingPriceInterval = components['schemas']['ShippingPriceInterval'];
                    export type Time = components['schemas']['Time'];
                    export type TimeInterval = components['schemas']['TimeInterval'];
                    export type IClosedIntervalOfZipCode = components['schemas']['IClosedIntervalOfZipCode'];
                    export type ZipCodeIntervalCollection = components['schemas']['ZipCodeIntervalCollection'];
                    export type IShippingZipCodeConstraints = components['schemas']['IShippingZipCodeConstraints'];
                    export type ShippingDailyLimits = components['schemas']['ShippingDailyLimits'];
                    export type ShippingActualLimit = components['schemas']['ShippingActualLimit'];
                    export type ShippingActualLimitMaybe = components['schemas']['ShippingActualLimitMaybe'];
                    export type ISingleShipping = components['schemas']['ISingleShipping'];
                    export type ShippingPriceIntervalMaybe = components['schemas']['ShippingPriceIntervalMaybe'];
                    export type IDeliveryInfo = components['schemas']['IDeliveryInfo'];
                    export type IDeliveryInfoMaybe = components['schemas']['IDeliveryInfoMaybe'];
                    export type DeliveryMinMax = components['schemas']['DeliveryMinMax'];
                    export type ShippingPickupDay = components['schemas']['ShippingPickupDay'];
                    export type ShippingPickupWeek = components['schemas']['ShippingPickupWeek'];
                    export type ShippingPickup = components['schemas']['ShippingPickup'];
                    export type ShippingPickupChange = components['schemas']['ShippingPickupChange'];
                    export type ShippingPickupAdditionalPackagingBuffer = components['schemas']['ShippingPickupAdditionalPackagingBuffer'];
                    export type ExpectedPickupInfo = components['schemas']['ExpectedPickupInfo'];
                    export type ExpectedPickupInfoMaybe = components['schemas']['ExpectedPickupInfoMaybe'];
                    export type SubscriptionScheduleDates = components['schemas']['SubscriptionScheduleDates'];
                    export type ShippingSubscriptionInfo = components['schemas']['ShippingSubscriptionInfo'];
                    export type FixedFreeShippingReason = components['schemas']['FixedFreeShippingReason'];
                    export type DeliveryTime = components['schemas']['DeliveryTime'];
                    export type InstaboxSupplierResponse = components['schemas']['InstaboxSupplierResponse'];
                    export type InstaboxSupplierResponseMaybe = components['schemas']['InstaboxSupplierResponseMaybe'];
                    export type ShippingDetailed = components['schemas']['ShippingDetailed'];
                    export type ShippingListItem = components['schemas']['ShippingListItem'];
                    export type ShippingKeyGroup = components['schemas']['ShippingKeyGroup'];
                    export type ShippingGroupInfo = components['schemas']['ShippingGroupInfo'];
                    export type ShippingInfoType = components['schemas']['ShippingInfoType'];
                    export type ShippingInfo = components['schemas']['ShippingInfo'];
                    export type ShippingInfoMaybe = components['schemas']['ShippingInfoMaybe'];
                    export type CheckoutShippingModel = components['schemas']['CheckoutShippingModel'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type BundleInfo = components['schemas']['BundleInfo'];
                    export type BundleInfoMaybe = components['schemas']['BundleInfoMaybe'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type MatasPlusSubscriptionType = components['schemas']['MatasPlusSubscriptionType'];
                    export type IProductBundleChild = components['schemas']['IProductBundleChild'];
                    export type BasketLine = components['schemas']['BasketLine'];
                    export type ISupplier = components['schemas']['ISupplier'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type IPrimaryProductGroupInfo = components['schemas']['IPrimaryProductGroupInfo'];
                    export type IPrimaryProductGroupInfoMaybe = components['schemas']['IPrimaryProductGroupInfoMaybe'];
                    export type ListDisplayType = components['schemas']['ListDisplayType'];
                    export type IMedium = components['schemas']['IMedium'];
                    export type IMediumMaybe = components['schemas']['IMediumMaybe'];
                    export type IBrandSimple = components['schemas']['IBrandSimple'];
                    export type ISystemProductStock = components['schemas']['ISystemProductStock'];
                    export type SynonymGroup = components['schemas']['SynonymGroup'];
                    export type ISynonym = components['schemas']['ISynonym'];
                    export type ISerieSimple = components['schemas']['ISerieSimple'];
                    export type ISerieSimpleMaybe = components['schemas']['ISerieSimpleMaybe'];
                    export type MetaRobotSetting = components['schemas']['MetaRobotSetting'];
                    export type IPageMetadata = components['schemas']['IPageMetadata'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type VideoPlatform = components['schemas']['VideoPlatform'];
                    export type ProductVideoType = components['schemas']['ProductVideoType'];
                    export type IProductVideo = components['schemas']['IProductVideo'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductNameRenderer = components['schemas']['ProductNameRenderer'];
                    export type ProductType = components['schemas']['ProductType'];
                    export type ProductBundleType = components['schemas']['ProductBundleType'];
                    export type DigitalProductType = components['schemas']['DigitalProductType'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelVisibilityFlags = components['schemas']['PriceLabelVisibilityFlags'];
                    export type IPriceLabel = components['schemas']['IPriceLabel'];
                    export type IPriceLabelMaybe = components['schemas']['IPriceLabelMaybe'];
                    export type IProgressiveDiscountStep = components['schemas']['IProgressiveDiscountStep'];
                    export type IProgressiveDiscountPriceInfo = components['schemas']['IProgressiveDiscountPriceInfo'];
                    export type IProgressiveDiscountPriceInfoMaybe = components['schemas']['IProgressiveDiscountPriceInfoMaybe'];
                    export type IPriceInfo = components['schemas']['IPriceInfo'];
                    export type IDiscountInfo = components['schemas']['IDiscountInfo'];
                    export type ISubscriptionInfo = components['schemas']['ISubscriptionInfo'];
                    export type ISubscriptionInfoMaybe = components['schemas']['ISubscriptionInfoMaybe'];
                    export type IUnitInfo = components['schemas']['IUnitInfo'];
                    export type ReviewRating = components['schemas']['ReviewRating'];
                    export type IProductReviewRatingStatistic = components['schemas']['IProductReviewRatingStatistic'];
                    export type IProductReviewStatistics = components['schemas']['IProductReviewStatistics'];
                    export type IOtcInfo = components['schemas']['IOtcInfo'];
                    export type IProductVariant = components['schemas']['IProductVariant'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type ProductActions = components['schemas']['ProductActions'];
                    export type ProductShopStatus = components['schemas']['ProductShopStatus'];
                    export type GwpOfferType = components['schemas']['GwpOfferType'];
                    export type IGwpOffer = components['schemas']['IGwpOffer'];
                    export type IGwpOfferMaybe = components['schemas']['IGwpOfferMaybe'];
                    export type IAttributeString = components['schemas']['IAttributeString'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type IProductPhysicalProperties = components['schemas']['IProductPhysicalProperties'];
                    export type HazardSymbolTypes = components['schemas']['HazardSymbolTypes'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type SampleType = components['schemas']['SampleType'];
                    export type ISampleInfo = components['schemas']['ISampleInfo'];
                    export type ISampleInfoMaybe = components['schemas']['ISampleInfoMaybe'];
                    export type ProductHighlightType = components['schemas']['ProductHighlightType'];
                    export type IProductHighlight = components['schemas']['IProductHighlight'];
                    export type IProductKeyIngredient = components['schemas']['IProductKeyIngredient'];
                    export type IProductTip = components['schemas']['IProductTip'];
                    export type IProductTipMaybe = components['schemas']['IProductTipMaybe'];
                    export type ProductFragranceType = components['schemas']['ProductFragranceType'];
                    export type IProductFragrance = components['schemas']['IProductFragrance'];
                    export type ProductDoseType = components['schemas']['ProductDoseType'];
                    export type QuantityInterval = components['schemas']['QuantityInterval'];
                    export type IProductDose = components['schemas']['IProductDose'];
                    export type ProductMicronutrientType = components['schemas']['ProductMicronutrientType'];
                    export type IProductMicronutrient = components['schemas']['IProductMicronutrient'];
                    export type IProductNutrient = components['schemas']['IProductNutrient'];
                    export type IProductNutrientMaybe = components['schemas']['IProductNutrientMaybe'];
                    export type BlacklistFlags = components['schemas']['BlacklistFlags'];
                    export type MediaType = components['schemas']['MediaType'];
                    export type IProductCustomMediaContent = components['schemas']['IProductCustomMediaContent'];
                    export type IProductMakeupInfo = components['schemas']['IProductMakeupInfo'];
                    export type IProductDetailed = components['schemas']['IProductDetailed'];
                    export type BasketLinePriceInfo = components['schemas']['BasketLinePriceInfo'];
                    export type CampaignType = components['schemas']['CampaignType'];
                    export type HighlightDiscountCore = components['schemas']['HighlightDiscountCore'];
                    export type HighlightDiscountCoreMaybe = components['schemas']['HighlightDiscountCoreMaybe'];
                    export type CustomSplash = components['schemas']['CustomSplash'];
                    export type CustomSplashMaybe = components['schemas']['CustomSplashMaybe'];
                    export type DiscountClubType = components['schemas']['DiscountClubType'];
                    export type DiscountCore = components['schemas']['DiscountCore'];
                    export type Discount = components['schemas']['Discount'];
                    export type DiscountMaybe = components['schemas']['DiscountMaybe'];
                    export type BasketCouponVoucher = components['schemas']['BasketCouponVoucher'];
                    export type BasketCouponVoucherMaybe = components['schemas']['BasketCouponVoucherMaybe'];
                    export type VoucherType = components['schemas']['VoucherType'];
                    export type VoucherFlowType = components['schemas']['VoucherFlowType'];
                    export type PersonalOfferImages = components['schemas']['PersonalOfferImages'];
                    export type PersonalOfferFutureTexts = components['schemas']['PersonalOfferFutureTexts'];
                    export type PersonalOfferFutureTextsMaybe = components['schemas']['PersonalOfferFutureTextsMaybe'];
                    export type ActiveTimeSpan = components['schemas']['ActiveTimeSpan'];
                    export type PersonalOffer = components['schemas']['PersonalOffer'];
                    export type ClubMatasMemberVoucher = components['schemas']['ClubMatasMemberVoucher'];
                    export type PersonalOfferDetailed = components['schemas']['PersonalOfferDetailed'];
                    export type PersonalOfferDetailedMaybe = components['schemas']['PersonalOfferDetailedMaybe'];
                    export type TriggeredDiscount = components['schemas']['TriggeredDiscount'];
                    export type PotentialDiscount = components['schemas']['PotentialDiscount'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepText = components['schemas']['ProgressiveDiscountBasketStepText'];
                    export type ProgressiveDiscountBasketStepTextMaybe = components['schemas']['ProgressiveDiscountBasketStepTextMaybe'];
                    export type ProgressiveDiscountBasketInfo = components['schemas']['ProgressiveDiscountBasketInfo'];
                    export type ProgressiveDiscountBasketInfoMaybe = components['schemas']['ProgressiveDiscountBasketInfoMaybe'];
                    export type BasketLineDiscountInfo = components['schemas']['BasketLineDiscountInfo'];
                    export type ReadyToPackBuffers = components['schemas']['ReadyToPackBuffers'];
                    export type OldDeliveryInfo = components['schemas']['OldDeliveryInfo'];
                    export type ProductBundleChildDetailed = components['schemas']['ProductBundleChildDetailed'];
                    export type BasketLineBooking = components['schemas']['BasketLineBooking'];
                    export type BasketLineBookingMaybe = components['schemas']['BasketLineBookingMaybe'];
                    export type ProductLinkedItemType = components['schemas']['ProductLinkedItemType'];
                    export type BasketLineLinkedItemPriceInfo = components['schemas']['BasketLineLinkedItemPriceInfo'];
                    export type BasketLineDetailed = components['schemas']['BasketLineDetailed'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type ZipCodeWithSource = components['schemas']['ZipCodeWithSource'];
                    export type DeliveryTexts = components['schemas']['DeliveryTexts'];
                    export type ExpectedDeliverySummaryModel = components['schemas']['ExpectedDeliverySummaryModel'];
                    export type ExpectedDeliverySummaryModelMaybe = components['schemas']['ExpectedDeliverySummaryModelMaybe'];
                    export type ProductStockStatusModel = components['schemas']['ProductStockStatusModel'];
                    export type ProductStockStatusModelMaybe = components['schemas']['ProductStockStatusModelMaybe'];
                    export type BasketLineSubscriptionNudgePriceInfo = components['schemas']['BasketLineSubscriptionNudgePriceInfo'];
                    export type BasketLineSubscriptionNudgeTexts = components['schemas']['BasketLineSubscriptionNudgeTexts'];
                    export type BasketLineSubscriptionNudge = components['schemas']['BasketLineSubscriptionNudge'];
                    export type BasketLineSubscriptionNudgeMaybe = components['schemas']['BasketLineSubscriptionNudgeMaybe'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MatasPlusBasketLineInfoModel = components['schemas']['MatasPlusBasketLineInfoModel'];
                    export type MatasPlusBasketLineInfoModelMaybe = components['schemas']['MatasPlusBasketLineInfoModelMaybe'];
                    export type DonationBasketLineInfoModel = components['schemas']['DonationBasketLineInfoModel'];
                    export type DonationBasketLineInfoModelMaybe = components['schemas']['DonationBasketLineInfoModelMaybe'];
                    export type SwapCertificateModel = components['schemas']['SwapCertificateModel'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginDirectRequest = components['schemas']['LoginDirectRequest'];
                    export type BasketLineDiscountInfoModel = components['schemas']['BasketLineDiscountInfoModel'];
                    export type BasketLineModel = components['schemas']['BasketLineModel'];
                    export type DeliveryGroupSettings = components['schemas']['DeliveryGroupSettings'];
                    export type CheckoutDeliveryGroupModel = components['schemas']['CheckoutDeliveryGroupModel'];
                    export type CheckoutDeliveryPostModel = components['schemas']['CheckoutDeliveryPostModel'];
                    export type BasketPrizeCertificateModelMaybe = components['schemas']['BasketPrizeCertificateModelMaybe'];
                    export type SignupNudgingType = components['schemas']['SignupNudgingType'];
                    export type SignupNudgingFooter = components['schemas']['SignupNudgingFooter'];
                    export type SignupNudgingFooterMaybe = components['schemas']['SignupNudgingFooterMaybe'];
                    export type SignupNudgingModel = components['schemas']['SignupNudgingModel'];
                    export type SignupNudgingModelMaybe = components['schemas']['SignupNudgingModelMaybe'];
                    export type CheckoutInvoiceAddress = components['schemas']['CheckoutInvoiceAddress'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type CheckoutDeliveryPreview = components['schemas']['CheckoutDeliveryPreview'];
                    export type NonurgentDelivery = components['schemas']['NonurgentDelivery'];
                    export type NonurgentDeliveryMaybe = components['schemas']['NonurgentDeliveryMaybe'];
                    export type CheckoutDeliveryViewModel = components['schemas']['CheckoutDeliveryViewModel'];
                    export type PaymentGatewayModel = components['schemas']['PaymentGatewayModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type IBasketInfoBoxMaybe = components['schemas']['IBasketInfoBoxMaybe'];
                    export type CheckoutBasketGroupViewModel = components['schemas']['CheckoutBasketGroupViewModel'];
                    export type CheckoutGroupedBasketViewModel = components['schemas']['CheckoutGroupedBasketViewModel'];
                    export type TrackingInfo = components['schemas']['TrackingInfo'];
                    export type PaymentViewModel = components['schemas']['PaymentViewModel'];
                    export type PaymentReepayCallbackViewModel = components['schemas']['PaymentReepayCallbackViewModel'];
                    export type LocalizedPageUrlModel = components['schemas']['LocalizedPageUrlModel'];
                    export type PageMetadataModel = components['schemas']['PageMetadataModel'];
                    export type ReceiptPageInfoModel = components['schemas']['ReceiptPageInfoModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ReceiptOrderInfoViewModel = components['schemas']['ReceiptOrderInfoViewModel'];
                    export type ReceiptOrderInfoViewModelMaybe = components['schemas']['ReceiptOrderInfoViewModelMaybe'];
                    export type ReceiptViewModel = components['schemas']['ReceiptViewModel'];
                    export type ReceiptOrderDetailsViewModel = components['schemas']['ReceiptOrderDetailsViewModel'];
                    export type ClubMatasSignupTermsModel = components['schemas']['ClubMatasSignupTermsModel'];
                    export type TrackingOperationResult = components['schemas']['TrackingOperationResult'];
                    

        export function UpsaleDeliveryMethoddestinationDiscriminator<T>(
    model: components['schemas']['UpsaleDeliveryMethod']['destination'],
    funcCompanyAddress: (m: components['schemas']['CompanyAddress']) => T,
    funcParcelShop: (m: components['schemas']['ParcelShop']) => T,
    funcPrivateAddress: (m: components['schemas']['PrivateAddress']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CompanyAddress': return funcCompanyAddress(model as components['schemas']['CompanyAddress']);
        case 'ParcelShop': return funcParcelShop(model as components['schemas']['ParcelShop']);
        case 'PrivateAddress': return funcPrivateAddress(model as components['schemas']['PrivateAddress']);

        default:
            return defaultFunc();
    }
}export function UpsaleDeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['UpsaleDeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function DeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['DeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function SelectedDeliveryMethoddestinationDiscriminator<T>(
    model: components['schemas']['SelectedDeliveryMethod']['destination'],
    funcCompanyAddress: (m: components['schemas']['CompanyAddress']) => T,
    funcParcelShop: (m: components['schemas']['ParcelShop']) => T,
    funcPrivateAddress: (m: components['schemas']['PrivateAddress']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CompanyAddress': return funcCompanyAddress(model as components['schemas']['CompanyAddress']);
        case 'ParcelShop': return funcParcelShop(model as components['schemas']['ParcelShop']);
        case 'PrivateAddress': return funcPrivateAddress(model as components['schemas']['PrivateAddress']);

        default:
            return defaultFunc();
    }
}export function SelectedDeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['SelectedDeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function DeliveryMethodCategorydeliveryPriceDiscriminator<T>(
    model: components['schemas']['DeliveryMethodCategory']['deliveryPrice'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function DeliveryGrouphighlightedDeliveryMethodsDiscriminator<T>(
    model: components['schemas']['DeliveryGroup']['highlightedDeliveryMethods'][0],
    funcUpsaleDeliveryMethod: (m: components['schemas']['UpsaleDeliveryMethod']) => T,
    funcDeliveryMethod: (m: components['schemas']['DeliveryMethod']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'UpsaleDeliveryMethod': return funcUpsaleDeliveryMethod(model as components['schemas']['UpsaleDeliveryMethod']);
        case 'DeliveryMethod': return funcDeliveryMethod(model as components['schemas']['DeliveryMethod']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}export function DeliveryGroupdeliveryMethodCategoriesDiscriminator<T>(
    model: components['schemas']['DeliveryGroup']['deliveryMethodCategories'][0],
    funcDeliveryMethodCategory: (m: components['schemas']['DeliveryMethodCategory']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryMethodCategory': return funcDeliveryMethodCategory(model as components['schemas']['DeliveryMethodCategory']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}export function DeliveryGroupdeliveryMessagesDiscriminator<T>(
    model: components['schemas']['DeliveryGroup']['deliveryMessages'][0],
    funcFixedDeliveryMessage: (m: components['schemas']['FixedDeliveryMessage']) => T,
    funcFreeDeliveryWithClubMatasMessage: (m: components['schemas']['FreeDeliveryWithClubMatasMessage']) => T,
    funcSubscriptionsMessage: (m: components['schemas']['SubscriptionsMessage']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'FixedDeliveryMessage': return funcFixedDeliveryMessage(model as components['schemas']['FixedDeliveryMessage']);
        case 'FreeDeliveryWithClubMatasMessage': return funcFreeDeliveryWithClubMatasMessage(model as components['schemas']['FreeDeliveryWithClubMatasMessage']);
        case 'SubscriptionsMessage': return funcSubscriptionsMessage(model as components['schemas']['SubscriptionsMessage']);

        default:
            return defaultFunc();
    }
}export function DeliveryParcelShopOptionpriceDiscriminator<T>(
    model: components['schemas']['DeliveryParcelShopOption']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function BasketInfoBoxbuttonsDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}export function EnhancedEcommerceTrackingEventecommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function DataLayerDtoonLoadEventsDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}export function ReceiptOrderDetailsViewModelinfoBoxesDiscriminator<T>(
    model: components['schemas']['ReceiptOrderDetailsViewModel']['infoBoxes'][0],
    funcCheckoutGiftCardInfo: (m: components['schemas']['CheckoutGiftCardInfo']) => T,
    funcCheckoutPaymentTypeList: (m: components['schemas']['CheckoutPaymentTypeList']) => T,
    funcBasketInfoBox: (m: components['schemas']['BasketInfoBox']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'CheckoutGiftCardInfo': return funcCheckoutGiftCardInfo(model as components['schemas']['CheckoutGiftCardInfo']);
        case 'CheckoutPaymentTypeList': return funcCheckoutPaymentTypeList(model as components['schemas']['CheckoutPaymentTypeList']);
        case 'BasketInfoBox': return funcBasketInfoBox(model as components['schemas']['BasketInfoBox']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type PrizeCertificatesSuccess = paths['/internal/checkout/Delivery/PrizeCertificates']['get']['responses']['200']['content']['text/plain'];

        export const PrizeCertificatesUrl = '/internal/checkout/Delivery/PrizeCertificates';

        
        export function PrizeCertificates(): Promise<PrizeCertificatesSuccess> {
            return fetchJSON<PrizeCertificatesSuccess>(`/internal/checkout/Delivery/PrizeCertificates`)
        }
    
        
     
    
        

        export type AddPrizeCertificateParams = paths['/internal/checkout/Delivery/AddPrizeCertificate']['post']['parameters']['query'];

        export type AddPrizeCertificateSuccess = paths['/internal/checkout/Delivery/AddPrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const AddPrizeCertificateUrl = '/internal/checkout/Delivery/AddPrizeCertificate';

        
        export function AddPrizeCertificate(params: AddPrizeCertificateParams): Promise<AddPrizeCertificateSuccess> {
            return postQuery<AddPrizeCertificateSuccess>(`/internal/checkout/Delivery/AddPrizeCertificate${formatParams(params)}`)
        }
    
        
     
    
        

        export type RemovePrizeCertificateParams = paths['/internal/checkout/Delivery/RemovePrizeCertificate']['post']['parameters']['query'];

        export type RemovePrizeCertificateSuccess = paths['/internal/checkout/Delivery/RemovePrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const RemovePrizeCertificateUrl = '/internal/checkout/Delivery/RemovePrizeCertificate';

        
        export function RemovePrizeCertificate(params: RemovePrizeCertificateParams): Promise<RemovePrizeCertificateSuccess> {
            return postQuery<RemovePrizeCertificateSuccess>(`/internal/checkout/Delivery/RemovePrizeCertificate${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type CheckoutDeliveryBasketSuccess = paths['/internal/checkout/Delivery/CheckoutDeliveryBasket']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryBasketUrl = '/internal/checkout/Delivery/CheckoutDeliveryBasket';

        
        export function CheckoutDeliveryBasket(): Promise<CheckoutDeliveryBasketSuccess> {
            return fetchJSON<CheckoutDeliveryBasketSuccess>(`/internal/checkout/Delivery/CheckoutDeliveryBasket`)
        }
    
        
     
    
        

        export type GetCityFromZipCodeParams = paths['/internal/checkout/Delivery/GetCityFromZipCode']['get']['parameters']['query'];

        export type GetCityFromZipCodeSuccess = paths['/internal/checkout/Delivery/GetCityFromZipCode']['get']['responses']['200']['content']['text/plain'];

        export const GetCityFromZipCodeUrl = '/internal/checkout/Delivery/GetCityFromZipCode';

        
        export function GetCityFromZipCode(params: GetCityFromZipCodeParams): Promise<GetCityFromZipCodeSuccess> {
            return fetchJSON<GetCityFromZipCodeSuccess>(`/internal/checkout/Delivery/GetCityFromZipCode${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type GetDeliveryGroupsSuccess = paths['/internal/checkout/Delivery/GetDeliveryGroups']['get']['responses']['200']['content']['text/plain'];

        export const GetDeliveryGroupsUrl = '/internal/checkout/Delivery/GetDeliveryGroups';

        
        export function GetDeliveryGroups(): Promise<GetDeliveryGroupsSuccess> {
            return fetchJSON<GetDeliveryGroupsSuccess>(`/internal/checkout/Delivery/GetDeliveryGroups`)
        }
    
        
     
    
        

        export type GetDeliveryGroupParams = paths['/internal/checkout/Delivery/GetDeliveryGroup']['get']['parameters']['query'];

        export type GetDeliveryGroupSuccess = paths['/internal/checkout/Delivery/GetDeliveryGroup']['get']['responses']['200']['content']['text/plain'];

        export const GetDeliveryGroupUrl = '/internal/checkout/Delivery/GetDeliveryGroup';

        
        export function GetDeliveryGroup(params: GetDeliveryGroupParams): Promise<GetDeliveryGroupSuccess> {
            return fetchJSON<GetDeliveryGroupSuccess>(`/internal/checkout/Delivery/GetDeliveryGroup${formatParams(params)}`)
        }
    
        
     
    
        export type UpdateInvoiceAddressRequestBody = paths['/internal/checkout/Delivery/UpdateInvoiceAddress']['post']['requestBody']['content']['application/json'];

        

        export type UpdateInvoiceAddressSuccess = paths['/internal/checkout/Delivery/UpdateInvoiceAddress']['post']['responses']['200']['content']['text/plain'];

        export const UpdateInvoiceAddressUrl = '/internal/checkout/Delivery/UpdateInvoiceAddress';

        
        export function UpdateInvoiceAddress(requestBody: UpdateInvoiceAddressRequestBody): Promise<UpdateInvoiceAddressSuccess> {
            return postJSON<UpdateInvoiceAddressSuccess>(`/internal/checkout/Delivery/UpdateInvoiceAddress`, requestBody)
        }
    
        
     
    
        

        export type SelectPrivateDeliveryParams = paths['/internal/checkout/Delivery/SelectPrivateDelivery']['post']['parameters']['query'];

        export type SelectPrivateDeliverySuccess = paths['/internal/checkout/Delivery/SelectPrivateDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectPrivateDeliveryUrl = '/internal/checkout/Delivery/SelectPrivateDelivery';

        
        export function SelectPrivateDelivery(params: SelectPrivateDeliveryParams): Promise<SelectPrivateDeliverySuccess> {
            return postQuery<SelectPrivateDeliverySuccess>(`/internal/checkout/Delivery/SelectPrivateDelivery${formatParams(params)}`)
        }
    
        
     
    
        export type UpdatePrivateDeliveryAddressRequestBody = paths['/internal/checkout/Delivery/UpdatePrivateDeliveryAddress']['post']['requestBody']['content']['application/json'];

        

        export type UpdatePrivateDeliveryAddressSuccess = paths['/internal/checkout/Delivery/UpdatePrivateDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const UpdatePrivateDeliveryAddressUrl = '/internal/checkout/Delivery/UpdatePrivateDeliveryAddress';

        
        export function UpdatePrivateDeliveryAddress(requestBody: UpdatePrivateDeliveryAddressRequestBody): Promise<UpdatePrivateDeliveryAddressSuccess> {
            return postJSON<UpdatePrivateDeliveryAddressSuccess>(`/internal/checkout/Delivery/UpdatePrivateDeliveryAddress`, requestBody)
        }
    
        
     
    
        export type SelectAndUpdateCompanyDeliveryAddressRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateCompanyDeliveryAddressSuccess = paths['/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateCompanyDeliveryAddressUrl = '/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress';

        
        export function SelectAndUpdateCompanyDeliveryAddress(requestBody: SelectAndUpdateCompanyDeliveryAddressRequestBody): Promise<SelectAndUpdateCompanyDeliveryAddressSuccess> {
            return postJSON<SelectAndUpdateCompanyDeliveryAddressSuccess>(`/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress`, requestBody)
        }
    
        
     
    
        

        export type SelectParcelShopDeliveryParams = paths['/internal/checkout/Delivery/SelectParcelShopDelivery']['post']['parameters']['query'];

        export type SelectParcelShopDeliverySuccess = paths['/internal/checkout/Delivery/SelectParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectParcelShopDeliveryUrl = '/internal/checkout/Delivery/SelectParcelShopDelivery';

        
        export function SelectParcelShopDelivery(params: SelectParcelShopDeliveryParams): Promise<SelectParcelShopDeliverySuccess> {
            return postQuery<SelectParcelShopDeliverySuccess>(`/internal/checkout/Delivery/SelectParcelShopDelivery${formatParams(params)}`)
        }
    
        
     
    
        export type SelectAndUpdateParcelShopDeliveryRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateParcelShopDeliverySuccess = paths['/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateParcelShopDeliveryUrl = '/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery';

        
        export function SelectAndUpdateParcelShopDelivery(requestBody: SelectAndUpdateParcelShopDeliveryRequestBody): Promise<SelectAndUpdateParcelShopDeliverySuccess> {
            return postJSON<SelectAndUpdateParcelShopDeliverySuccess>(`/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery`, requestBody)
        }
    
        
     
    
        

        export type ListParcelShopsParams = paths['/internal/checkout/Delivery/ListParcelShops']['get']['parameters']['query'];

        export type ListParcelShopsSuccess = paths['/internal/checkout/Delivery/ListParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsUrl = '/internal/checkout/Delivery/ListParcelShops';

        
        export function ListParcelShops(params: ListParcelShopsParams): Promise<ListParcelShopsSuccess> {
            return fetchJSON<ListParcelShopsSuccess>(`/internal/checkout/Delivery/ListParcelShops${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListParcelShopsByAddressParams = paths['/internal/checkout/Delivery/ListParcelShopsByAddress']['get']['parameters']['query'];

        export type ListParcelShopsByAddressSuccess = paths['/internal/checkout/Delivery/ListParcelShopsByAddress']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsByAddressUrl = '/internal/checkout/Delivery/ListParcelShopsByAddress';

        
        export function ListParcelShopsByAddress(params: ListParcelShopsByAddressParams): Promise<ListParcelShopsByAddressSuccess> {
            return fetchJSON<ListParcelShopsByAddressSuccess>(`/internal/checkout/Delivery/ListParcelShopsByAddress${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListParcelShopsByCoordinatesParams = paths['/internal/checkout/Delivery/ListParcelShopsByCoordinates']['get']['parameters']['query'];

        export type ListParcelShopsByCoordinatesSuccess = paths['/internal/checkout/Delivery/ListParcelShopsByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsByCoordinatesUrl = '/internal/checkout/Delivery/ListParcelShopsByCoordinates';

        
        export function ListParcelShopsByCoordinates(params: ListParcelShopsByCoordinatesParams): Promise<ListParcelShopsByCoordinatesSuccess> {
            return fetchJSON<ListParcelShopsByCoordinatesSuccess>(`/internal/checkout/Delivery/ListParcelShopsByCoordinates${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectMatasDeliveryParams = paths['/internal/checkout/Delivery/SelectMatasDelivery']['post']['parameters']['query'];

        export type SelectMatasDeliverySuccess = paths['/internal/checkout/Delivery/SelectMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectMatasDeliveryUrl = '/internal/checkout/Delivery/SelectMatasDelivery';

        
        export function SelectMatasDelivery(params: SelectMatasDeliveryParams): Promise<SelectMatasDeliverySuccess> {
            return postQuery<SelectMatasDeliverySuccess>(`/internal/checkout/Delivery/SelectMatasDelivery${formatParams(params)}`)
        }
    
        
     
    
        export type SelectAndUpdateMatasDeliveryRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateMatasDelivery']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateMatasDeliverySuccess = paths['/internal/checkout/Delivery/SelectAndUpdateMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateMatasDeliveryUrl = '/internal/checkout/Delivery/SelectAndUpdateMatasDelivery';

        
        export function SelectAndUpdateMatasDelivery(requestBody: SelectAndUpdateMatasDeliveryRequestBody): Promise<SelectAndUpdateMatasDeliverySuccess> {
            return postJSON<SelectAndUpdateMatasDeliverySuccess>(`/internal/checkout/Delivery/SelectAndUpdateMatasDelivery`, requestBody)
        }
    
        
     
    
        

        export type ListMatasParcelShopsParams = paths['/internal/checkout/Delivery/ListMatasParcelShops']['get']['parameters']['query'];

        export type ListMatasParcelShopsSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsUrl = '/internal/checkout/Delivery/ListMatasParcelShops';

        
        export function ListMatasParcelShops(params: ListMatasParcelShopsParams): Promise<ListMatasParcelShopsSuccess> {
            return fetchJSON<ListMatasParcelShopsSuccess>(`/internal/checkout/Delivery/ListMatasParcelShops${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListMatasParcelShopsByAddressParams = paths['/internal/checkout/Delivery/ListMatasParcelShopsByAddress']['get']['parameters']['query'];

        export type ListMatasParcelShopsByAddressSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShopsByAddress']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsByAddressUrl = '/internal/checkout/Delivery/ListMatasParcelShopsByAddress';

        
        export function ListMatasParcelShopsByAddress(params: ListMatasParcelShopsByAddressParams): Promise<ListMatasParcelShopsByAddressSuccess> {
            return fetchJSON<ListMatasParcelShopsByAddressSuccess>(`/internal/checkout/Delivery/ListMatasParcelShopsByAddress${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListMatasParcelShopsByCoordinatesParams = paths['/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates']['get']['parameters']['query'];

        export type ListMatasParcelShopsByCoordinatesSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsByCoordinatesUrl = '/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates';

        
        export function ListMatasParcelShopsByCoordinates(params: ListMatasParcelShopsByCoordinatesParams): Promise<ListMatasParcelShopsByCoordinatesSuccess> {
            return fetchJSON<ListMatasParcelShopsByCoordinatesSuccess>(`/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectUpsaleDeliveryParams = paths['/internal/checkout/Delivery/SelectUpsaleDelivery']['post']['parameters']['query'];

        export type SelectUpsaleDeliverySuccess = paths['/internal/checkout/Delivery/SelectUpsaleDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectUpsaleDeliveryUrl = '/internal/checkout/Delivery/SelectUpsaleDelivery';

        
        export function SelectUpsaleDelivery(params: SelectUpsaleDeliveryParams): Promise<SelectUpsaleDeliverySuccess> {
            return postQuery<SelectUpsaleDeliverySuccess>(`/internal/checkout/Delivery/SelectUpsaleDelivery${formatParams(params)}`)
        }
    
        
     
    
        

        export type ToggleNonurgentDeliveryParams = paths['/internal/checkout/Delivery/ToggleNonurgentDelivery']['get']['parameters']['query'];

        export type ToggleNonurgentDeliverySuccess = paths['/internal/checkout/Delivery/ToggleNonurgentDelivery']['get']['responses']['200']['content']['text/plain'];

        export const ToggleNonurgentDeliveryUrl = '/internal/checkout/Delivery/ToggleNonurgentDelivery';

        
        export function ToggleNonurgentDelivery(params: ToggleNonurgentDeliveryParams): Promise<ToggleNonurgentDeliverySuccess> {
            return fetchJSON<ToggleNonurgentDeliverySuccess>(`/internal/checkout/Delivery/ToggleNonurgentDelivery${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type CheckoutDeliveryGroupModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryGroupModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryGroupModelUrl = '/internal/ModelOnly/CheckoutDeliveryGroupModel';

        
        export function CheckoutDeliveryGroupModel(): Promise<CheckoutDeliveryGroupModelSuccess> {
            return fetchJSON<CheckoutDeliveryGroupModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryGroupModel`)
        }
    
        
     
    
        

        

        export type CheckoutDeliveryPostModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryPostModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryPostModelUrl = '/internal/ModelOnly/CheckoutDeliveryPostModel';

        
        export function CheckoutDeliveryPostModel(): Promise<CheckoutDeliveryPostModelSuccess> {
            return fetchJSON<CheckoutDeliveryPostModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryPostModel`)
        }
    
        
     
    
        

        

        export type CheckoutDeliveryViewModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryViewModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryViewModelUrl = '/internal/ModelOnly/CheckoutDeliveryViewModel';

        
        export function CheckoutDeliveryViewModel(): Promise<CheckoutDeliveryViewModelSuccess> {
            return fetchJSON<CheckoutDeliveryViewModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryViewModel`)
        }
    
        
     
    
        

        

        export type PaymentViewModelSuccess = paths['/internal/ModelOnly/PaymentViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PaymentViewModelUrl = '/internal/ModelOnly/PaymentViewModel';

        
        export function PaymentViewModel(): Promise<PaymentViewModelSuccess> {
            return fetchJSON<PaymentViewModelSuccess>(`/internal/ModelOnly/PaymentViewModel`)
        }
    
        
     
    
        

        

        export type PaymentReepayCallbackViewModelSuccess = paths['/internal/ModelOnly/PaymentReepayCallbackViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PaymentReepayCallbackViewModelUrl = '/internal/ModelOnly/PaymentReepayCallbackViewModel';

        
        export function PaymentReepayCallbackViewModel(): Promise<PaymentReepayCallbackViewModelSuccess> {
            return fetchJSON<PaymentReepayCallbackViewModelSuccess>(`/internal/ModelOnly/PaymentReepayCallbackViewModel`)
        }
    
        
     
    
        

        

        export type ReceiptPageViewModelSuccess = paths['/internal/ModelOnly/ReceiptPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ReceiptPageViewModelUrl = '/internal/ModelOnly/ReceiptPageViewModel';

        
        export function ReceiptPageViewModel(): Promise<ReceiptPageViewModelSuccess> {
            return fetchJSON<ReceiptPageViewModelSuccess>(`/internal/ModelOnly/ReceiptPageViewModel`)
        }
    
        
     
    
        

        export type ReepayAcceptParams = paths['/internal/PaymentCallback/ReepayAccept']['post']['parameters']['query'];

        

        export const ReepayAcceptUrl = '/internal/PaymentCallback/ReepayAccept';

        
        export function ReepayAccept(params: ReepayAcceptParams): Promise<unknown> {
            return postQuery<unknown>(`/internal/PaymentCallback/ReepayAccept${formatParams(params)}`)
        }
    
        
     
    
        

        export type OrderDetailsParams = paths['/internal/Receipt/OrderDetails']['get']['parameters']['query'];

        export type OrderDetailsSuccess = paths['/internal/Receipt/OrderDetails']['get']['responses']['200']['content']['text/plain'];

        export const OrderDetailsUrl = '/internal/Receipt/OrderDetails';

        
        export function OrderDetails(params: OrderDetailsParams): Promise<OrderDetailsSuccess> {
            return fetchJSON<OrderDetailsSuccess>(`/internal/Receipt/OrderDetails${formatParams(params)}`)
        }
    
        
     
    
        

        export type CreateOrderDetailsPdfParams = paths['/internal/Receipt/CreateOrderDetailsPdf']['get']['parameters']['query'];

        export type CreateOrderDetailsPdfSuccess = paths['/internal/Receipt/CreateOrderDetailsPdf']['get']['responses']['200']['content']['text/plain'];

        export const CreateOrderDetailsPdfUrl = '/internal/Receipt/CreateOrderDetailsPdf';

        
        export function CreateOrderDetailsPdf(params: CreateOrderDetailsPdfParams): Promise<CreateOrderDetailsPdfSuccess> {
            return fetchJSON<CreateOrderDetailsPdfSuccess>(`/internal/Receipt/CreateOrderDetailsPdf${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type ModelForBasketSuccess = paths['/internal/checkout/Signup/ModelForBasket']['get']['responses']['200']['content']['text/plain'];

        export const ModelForBasketUrl = '/internal/checkout/Signup/ModelForBasket';

        
        export function ModelForBasket(): Promise<ModelForBasketSuccess> {
            return fetchJSON<ModelForBasketSuccess>(`/internal/checkout/Signup/ModelForBasket`)
        }
    
        
     
    
        

        

        export type ModelForCheckoutSuccess = paths['/internal/checkout/Signup/ModelForCheckout']['get']['responses']['200']['content']['text/plain'];

        export const ModelForCheckoutUrl = '/internal/checkout/Signup/ModelForCheckout';

        
        export function ModelForCheckout(): Promise<ModelForCheckoutSuccess> {
            return fetchJSON<ModelForCheckoutSuccess>(`/internal/checkout/Signup/ModelForCheckout`)
        }
    
        
     
    
        

        

        export type ClubMatasTermsSuccess = paths['/internal/checkout/Signup/ClubMatasTerms']['get']['responses']['200']['content']['text/plain'];

        export const ClubMatasTermsUrl = '/internal/checkout/Signup/ClubMatasTerms';

        
        export function ClubMatasTerms(): Promise<ClubMatasTermsSuccess> {
            return fetchJSON<ClubMatasTermsSuccess>(`/internal/checkout/Signup/ClubMatasTerms`)
        }
    
        
     
    
        

        export type TogglePlusSignupParams = paths['/internal/checkout/Signup/TogglePlusSignup']['post']['parameters']['query'];

        export type TogglePlusSignupSuccess = paths['/internal/checkout/Signup/TogglePlusSignup']['post']['responses']['200']['content']['text/plain'];

        export const TogglePlusSignupUrl = '/internal/checkout/Signup/TogglePlusSignup';

        
        export function TogglePlusSignup(params: TogglePlusSignupParams): Promise<TogglePlusSignupSuccess> {
            return postQuery<TogglePlusSignupSuccess>(`/internal/checkout/Signup/TogglePlusSignup${formatParams(params)}`)
        }
    
        
     
    
        

        export type ToggleClubSignupParams = paths['/internal/checkout/Signup/ToggleClubSignup']['post']['parameters']['query'];

        

        export const ToggleClubSignupUrl = '/internal/checkout/Signup/ToggleClubSignup';

        
        export function ToggleClubSignup(params: ToggleClubSignupParams): Promise<unknown> {
            return postQuery<unknown>(`/internal/checkout/Signup/ToggleClubSignup${formatParams(params)}`)
        }
    
        
     
    
    