import { Spacing16, Spacing4 } from 'autogen/design-tokens/tokens';
import type { SessionMenuViewModel } from 'autogen/swagger/layout';
import SessionMenuItem from 'components/SessionMenu/components/Items';
import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';

/* #region  styles */
const MenuContainer = styled.nav`
    display: grid;
    grid-template-columns: repeat(auto-fit, 40px);
    min-height: 32px;
    justify-content: flex-end;
    grid-auto-flow: column;
    width: 100%;
    gap: ${Spacing4};

    ${breakpoint.up(361)} {
        grid-template-columns: repeat(auto-fit, minmax(32px, 40px));
        gap: ${Spacing16};
    }

    ${breakpoint.up(sizes.lg)} {
        grid-template-columns: repeat(auto-fit, minmax(32px, 56px));
    }
`;

const HideOnMobile = css`
    display: none;

    ${breakpoint.up(sizes.sm)} {
        display: flex;
    }
`;
/* #endregion */

export default function SessionMenu({
    model: { favoriteIconUrl, findStoreUrl, basketUrl, myProfile, tooltips, rebuyUrl }
}: {
    model: SessionMenuViewModel;
}) {
    return (
        <MenuContainer>
            {findStoreUrl && <SessionMenuItem.FindStore findStoreUrl={findStoreUrl} css={HideOnMobile} />}
            <SessionMenuItem.MyProfile model={myProfile} tooltip={tooltips?.clubMatasTooltip} />
            <SessionMenuItem.Favorite model={favoriteIconUrl} />
            {rebuyUrl && (
                <SessionMenuItem.Rebuy rebuyUrl={rebuyUrl} tooltip={tooltips?.rebuyTooltip} css={HideOnMobile} />
            )}
            <SessionMenuItem.Basket basketUrl={basketUrl} />
        </MenuContainer>
    );
}
