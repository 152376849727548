import React from 'react';
import WhenDesktop from './components/WhenDesktop';
import ISelect from './shared/types/select';
import NativeSelect from './components/NativeSelect';
import CustomSelect from './components/CustomSelect';

export default function Select({ onChange, options, size = 'md', ...rest }: ISelect) {
    return (
        <>
            {/* When NOT desktop show native select */}
            <WhenDesktop negate>
                <NativeSelect options={options} onChange={onChange} size={size} {...rest} />
            </WhenDesktop>

            {/* When desktop show custom select */}
            <WhenDesktop>
                <CustomSelect options={options} onChange={onChange} size={size} {...rest} />
            </WhenDesktop>
        </>
    );
}
