
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {GetForFixedLowPrice,GetForFixedLowPriceSuccess} from './index'

        
        
    export const GetForFixedLowPriceQueryKey = 'pricelabelinfo_GetForFixedLowPrice';

    export function useGetForFixedLowPriceQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetForFixedLowPriceSuccess, TError, GetForFixedLowPriceSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetForFixedLowPriceSuccess | (() => GetForFixedLowPriceSuccess);},  baseQueryKey = GetForFixedLowPriceQueryKey) {
            return useQuery([baseQueryKey, ], 
            GetForFixedLowPrice, options);
        }
  
    
    