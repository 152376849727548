
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {Mega,MegaSuccess,Footer,FooterSuccess,List,ListSuccess,ListParams,MenuForWebshop,MenuForWebshopSuccess,MenuForWebshopParams,Menu,MenuSuccess,MenuParams,MenuTooltipsViewModel,MenuTooltipsViewModelSuccess} from './index'

        
        
    export const MegaQueryKey = 'menu_Mega';

    export function useMegaQuery<TError = unknown>( options?: Omit<UseQueryOptions<MegaSuccess, TError, MegaSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MegaSuccess | (() => MegaSuccess);},  baseQueryKey = MegaQueryKey) {
            return useQuery([baseQueryKey, ], 
            Mega, options);
        }
  
    
        
    export const FooterQueryKey = 'menu_Footer';

    export function useFooterQuery<TError = unknown>( options?: Omit<UseQueryOptions<FooterSuccess, TError, FooterSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FooterSuccess | (() => FooterSuccess);},  baseQueryKey = FooterQueryKey) {
            return useQuery([baseQueryKey, ], 
            Footer, options);
        }
  
    
        
    export const ListQueryKey = 'menu_List';

    export function useListQuery<TError = unknown>(params: ListParams, options?: Omit<UseQueryOptions<ListSuccess, TError, ListSuccess, [string, ListParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListSuccess | (() => ListSuccess);},  baseQueryKey = ListQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => List(params), options);
        }
  
    
        
    export const MenuForWebshopQueryKey = 'menu_MenuForWebshop';

    export function useMenuForWebshopQuery<TError = unknown>(params: MenuForWebshopParams, options?: Omit<UseQueryOptions<MenuForWebshopSuccess, TError, MenuForWebshopSuccess, [string, MenuForWebshopParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuForWebshopSuccess | (() => MenuForWebshopSuccess);},  baseQueryKey = MenuForWebshopQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => MenuForWebshop(params), options);
        }
  
    
        
    export const MenuQueryKey = 'menu_Menu';

    export function useMenuQuery<TError = unknown>(params: MenuParams, options?: Omit<UseQueryOptions<MenuSuccess, TError, MenuSuccess, [string, MenuParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuSuccess | (() => MenuSuccess);},  baseQueryKey = MenuQueryKey) {
            return useQuery([baseQueryKey, params,], 
            () => Menu(params), options);
        }
  
    
        
    export const MenuTooltipsViewModelQueryKey = 'menu_MenuTooltipsViewModel';

    export function useMenuTooltipsViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MenuTooltipsViewModelSuccess, TError, MenuTooltipsViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuTooltipsViewModelSuccess | (() => MenuTooltipsViewModelSuccess);},  baseQueryKey = MenuTooltipsViewModelQueryKey) {
            return useQuery([baseQueryKey, ], 
            MenuTooltipsViewModel, options);
        }
  
    
    