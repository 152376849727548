import { URLify } from 'modules/helpers/strings';
import Routes from '../../routes';

const localStorageKey = 'latestSearchQueries';
const latestSearchQueriesLimit = 5;

const convertFormat = oldFormat => {
    const oldFormatJSON = JSON.parse(oldFormat);
    const items = Object.keys(oldFormatJSON).map(name => {
        const item = oldFormatJSON[name];

        return {
            title: item.searchPhrase,
            url: Routes.Search.InitiateSearchFromHistory + URLify(item.searchPhrase),
            latestDate: new Date(item.latestDate)
        };
    });

    return { items };
};

export const getSearchHistory = () => {
    if (typeof window.localStorage === 'undefined') return null;
    const oldFormat = window.localStorage.getItem('searchHistory');

    if (oldFormat !== null) {
        // convert old format to new format and remove the old one
        const newFormat = convertFormat(oldFormat);
        try {
            return newFormat;
        } finally {
            window.localStorage.setItem(localStorageKey, JSON.stringify(newFormat));
            window.localStorage.removeItem('searchHistory');
        }
    }

    return JSON.parse(window.localStorage.getItem(localStorageKey));
};

export const clearSearchHistory = () => {
    window.localStorage.removeItem(localStorageKey);
};

export const saveSearchHistory = searchString => {
    if (typeof window.localStorage === 'undefined' || searchString.length <= 0) return null;

    const formattedSearchString = searchString.trim();
    const searchHistory = JSON.parse(window.localStorage.getItem(localStorageKey)) || { items: [] };
    const newSearchString = {
        title: formattedSearchString,
        url: Routes.Search.InitiateSearchFromHistory + URLify(formattedSearchString),
        latestDate: new Date()
    };

    if (searchHistory && searchHistory.items.length) {
        // remove the old string if it's already saved.
        searchHistory.items.map((obj, index) => {
            if (obj.title !== formattedSearchString) return;
            searchHistory.items.splice(index, 1);
            return;
        });
        searchHistory.items.unshift(newSearchString);
        searchHistory.items.splice(latestSearchQueriesLimit);
    } else {
        searchHistory.items.push(newSearchString);
    }

    window.localStorage.setItem(localStorageKey, JSON.stringify(searchHistory));
};
