import Translations from 'modules/translations';

const currentGrowls = [];

export class Growl {
    constructor(options) {
        this.options = options;
        this.target = $('body');
        this.baseClass = 'growl';
        this.growl = $(this.template());
        this.messageContainer = this.growl.find('.' + this.baseClass + '__message');
        this.close = this.growl.find('.' + this.baseClass + '__close');
    }

    init() {
        this.showMessage(this.options.message);
        this.bindings();
    }

    bindings() {
        this.close.on('click', () => {
            let message = this.messageContainer.text();
            this.removeMessage(message);
        });

        if (this.options.remove) {
            setTimeout(() => this.removeMessage(), this.options.remove);
        }
    }

    showMessage(message) {
        if (currentGrowls.indexOf(message) !== -1) return;

        this.messageContainer.html(message);
        this.growl.appendTo(this.target);
        currentGrowls.push(message);
    }

    removeMessage(message) {
        this.growl.remove();
        currentGrowls.splice(currentGrowls.indexOf(message), 1);
    }

    template() {
        return `
			<div class="${this.baseClass}">
				<div class="${this.baseClass}__top">
					<div class="${this.baseClass}__close">
						<svg class="icon icon--close">
	                        <use xlink:href="#icons_close"></use>
	                    </svg>
                    </div>
				</div>
				<div class="${this.baseClass}__message" role="alert"></div>
			</div>
		`;
    }
}

export default Growl;

export function growlGeneralExceptionError() {
    const growl = new Growl({
        message: Translations.Website.Shared.GeneralExceptionError,
        remove: 5000
    });
    growl.init();
}
