/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Subscription from './trans-website-pos-subscription';

export const Brands = 'Website.Pos.Brands';
export const Menu = 'Website.Pos.Menu';
export const Booking = 'Website.Pos.Booking';
export const BookingMyProfile = 'Website.Pos.BookingMyProfile';
export const Search = 'Website.Pos.Search';
export const LoginFailed = 'Website.Pos.LoginFailed';
export const AssistantUsageSuggestion = 'Website.Pos.AssistantUsageSuggestion';
export const Error = 'Website.Pos.Error';
