import { FontWeightNormal, ColorNeutralDarkGrey, ColorSpot1, Spacing8 } from 'autogen/design-tokens/tokens';
import React from 'react';
import styled from 'styled-components';
import Price from './Price';
import type { IPrice } from './shared/types';

export interface IPriceDiscount extends IPrice {
    priceSaving?: string;
}

const StyledPriceBefore = styled(Price)`
    && {
        font-weight: ${FontWeightNormal};
        color: ${ColorNeutralDarkGrey};
        text-decoration: line-through;
        line-height: normal;
    }
`;

const StyledPriceSaving = styled(Price)`
    && {
        color: ${ColorSpot1};
        font-weight: ${FontWeightNormal};
        margin-left: ${Spacing8};
    }
`;

const Container = styled.span`
    line-height: normal;
`;

export function PriceDiscount({ price, priceSaving, size = 12 }: IPriceDiscount) {
    return (
        <Container>
            <StyledPriceBefore price={price} size={size} />
            {priceSaving && <StyledPriceSaving price={priceSaving} size={size} />}
        </Container>
    );
}
