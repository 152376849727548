import {
    FontSize10,
    FontSize12,
    FontSize14,
    FontSize16,
    FontSize18,
    FontSize20,
    FontSize24,
    FontSize28,
    FontSize32,
    FontSize40,
    FontSize48,
    FontSize56,
    FontSize8
} from 'autogen/design-tokens/tokens';
import React from 'react';
import styled, { type RuleSet, css } from 'styled-components';
import { MappedBreakpointStyling, mediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { breakPointObject, availableFontSizeTypes, IText } from '../types';

function fontSizeTypeToToken(size: availableFontSizeTypes): string {
    switch (size) {
        case 8:
            return FontSize8;
        case 10:
            return FontSize10;
        case 12:
            return FontSize12;
        case 14:
            return FontSize14;
        case 16:
            return FontSize16;
        case 18:
            return FontSize18;
        case 20:
            return FontSize20;
        case 24:
            return FontSize24;
        case 28:
            return FontSize28;
        case 32:
            return FontSize32;
        case 40:
            return FontSize40;
        case 48:
            return FontSize48;
        case 56:
            return FontSize56;
        default:
            return FontSize16;
    }
}

function fontSizeStyling(textSize: availableFontSizeTypes) {
    return css`
        font-size: ${fontSizeTypeToToken(textSize)};
    `;
}

type BreakpointFontSize = breakPointObject<RuleSet>;

function handleMediaQueries(textSize: breakPointObject) {
    const breakpointFontSize = Object.keys(textSize).reduce<BreakpointFontSize>((acc, key) => {
        acc[key] = css`font-size: ${fontSizeTypeToToken(textSize[key])};`;
        return acc;
    }, {});

    return mediaQueryStyling(breakpointFontSize);
}

const TextElement = styled.span<{ $size: IText['size']; nowrap?: IText['nowrap'] }>`
    ${({ $size }) => $size && (typeof $size === 'object' ? handleMediaQueries($size) : fontSizeStyling($size))}
    ${({ nowrap }) =>
        nowrap &&
        css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `}
`;

export default function Text({ size, children, ...restProps }: IText): JSX.Element {
    return (
        <TextElement $size={size} {...restProps}>
            {children}
        </TextElement>
    );
}
