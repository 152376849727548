/* eslint-disable */
/// This file is auto generated - do not edit manually
export const AddedToBasketMessage = 'Website.Basket.Powerstep.AddedToBasketMessage';
export const QuantityAddedToBasketMessage = 'Website.Basket.Powerstep.QuantityAddedToBasketMessage';
export const SubscriptionAddedToBasketText = 'Website.Basket.Powerstep.SubscriptionAddedToBasketText';
export const QuantityInBasketMessage = 'Website.Basket.Powerstep.QuantityInBasketMessage';
export const FreeShippingMessage = 'Website.Basket.Powerstep.FreeShippingMessage';
export const FreeShippingObtainedMessage = 'Website.Basket.Powerstep.FreeShippingObtainedMessage';
export const FreeShippingSavings = 'Website.Basket.Powerstep.FreeShippingSavings';
export const FreeShippingSavingsObtained = 'Website.Basket.Powerstep.FreeShippingSavingsObtained';
export const ProtectTheEnvironment = 'Website.Basket.Powerstep.ProtectTheEnvironment';
export const IntroPrice = 'Website.Basket.Powerstep.IntroPrice';
export const SubscriptionPrice = 'Website.Basket.Powerstep.SubscriptionPrice';
export const SubscriptionPriceHereAfter = 'Website.Basket.Powerstep.SubscriptionPriceHereAfter';
export const SubscriptionPriceMinBuyHereAfter = 'Website.Basket.Powerstep.SubscriptionPriceMinBuyHereAfter';
export const EditVariantsButton = 'Website.Basket.Powerstep.EditVariantsButton';
