
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './term';

        export type ClubMatasTermsViewModel = components['schemas']['ClubMatasTermsViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    

        

        
        

        

        export type IndexSuccess = paths['/internal/Term/Index']['get']['responses']['200']['content']['text/plain'];

        export const IndexUrl = '/internal/Term/Index';

        
        export function Index(): Promise<IndexSuccess> {
            return fetchJSON<IndexSuccess>(`/internal/Term/Index`)
        }
    
        
     
    
        

        

        export type HideSuccess = paths['/internal/Term/Hide']['post']['responses']['200']['content']['text/plain'];

        export const HideUrl = '/internal/Term/Hide';

        
        export function Hide(): Promise<HideSuccess> {
            return postQuery<HideSuccess>(`/internal/Term/Hide`)
        }
    
        
     
    
    