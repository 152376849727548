import { MenuItemLoginOrMyProfileViewModel } from 'autogen/swagger/menu';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import useLoginLink from 'hooks/user/useLoginLink';
import loadHtmlModalFunc from 'modules/modal/helpers';
import Default from 'modules/modal/layouts';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';
import { bindComponents } from 'modules/modalLink';
import { GA4Tracking } from 'modules/tracking/GA4';
import DefaultMenuItem from './DefaultMenuItem';
import { DefaultMenuItemProps } from '../types';

type LoginOrMyProfileMenuItemProps = {
    model: MenuItemLoginOrMyProfileViewModel;
    defaultProps: DefaultMenuItemProps;
};

export default function LoginOrMyProfileMenuItem({
    model: { myProfileUrl, textWhenLoggedIn, textWhenNotLoggedIn },
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: LoginOrMyProfileMenuItemProps) {
    const isAuthenticated = useIsAuthenticated();
    const { loginUrl, loginModal } = useLoginLink();

    if (!loginUrl && loginModal) {
        return (
            <DefaultMenuItem.Button
                styling={styling}
                iconUrl={iconUrl}
                onClick={async () => {
                    const htmlModal = await loadHtmlModalFunc();
                    const modal = htmlModal({ layout: { component: <Default /> } });
                    modal({ url: loginModal.url, onAfterContent: bindComponents });

                    UAEventTracking({
                        eventCategory: 'sideMenu',
                        eventAction: loginModal.trackingAction as TrackingAction
                    });
                }}
                {...restProps}
            >
                {textWhenNotLoggedIn}
            </DefaultMenuItem.Button>
        );
    }

    if (!isAuthenticated)
        return (
            <DefaultMenuItem.Anchor
                anchorProps={{ href: loginUrl, title: textWhenNotLoggedIn }}
                styling={styling}
                iconUrl={iconUrl}
                onClick={() => {
                    UAEventTracking({
                        eventCategory: 'sideMenu',
                        eventAction: 'goToSSO',
                        eventLabel: textWhenNotLoggedIn
                    });

                    GA4Tracking({
                        eventName: 'open_sso_modal',
                        context: 'menu',
                        category: 'login'
                    });
                }}
                {...restProps}
            >
                {textWhenNotLoggedIn}
            </DefaultMenuItem.Anchor>
        );

    return (
        <DefaultMenuItem.Anchor
            anchorProps={{ href: myProfileUrl, title: textWhenLoggedIn }}
            styling={styling}
            iconUrl={iconUrl}
            onClick={() => {
                UAEventTracking({
                    eventCategory: 'sideMenu',
                    eventAction: 'link',
                    eventLabel: textWhenLoggedIn
                });

                GA4Tracking({
                    eventName: 'link_click',
                    context: 'menu',
                    category: 'navigation',
                    eventParams: [
                        { param_name: 'link_type', param_value: 'internal' },
                        { param_name: 'list_name', param_value: textWhenLoggedIn },
                        { param_name: 'id', param_value: id }
                    ]
                });
            }}
            {...restProps}
        >
            {textWhenLoggedIn}
        </DefaultMenuItem.Anchor>
    );
}
