
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './minibasket';

        export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type TriggeredDiscountViewModel = components['schemas']['TriggeredDiscountViewModel'];
                    export type PotentialDiscountViewModel = components['schemas']['PotentialDiscountViewModel'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type ProgressiveDiscountViewModelMaybe = components['schemas']['ProgressiveDiscountViewModelMaybe'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type DiscountInfoViewModel = components['schemas']['DiscountInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionInfoViewModelMaybe = components['schemas']['SubscriptionInfoViewModelMaybe'];
                    export type MiniBasketLineViewModel = components['schemas']['MiniBasketLineViewModel'];
                    export type MiniBasketGiftLineViewModel = components['schemas']['MiniBasketGiftLineViewModel'];
                    export type MiniBasketSampleLineViewModel = components['schemas']['MiniBasketSampleLineViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type MiniBasketSummaryViewModel = components['schemas']['MiniBasketSummaryViewModel'];
                    export type LoginDirectLinkMaybe = components['schemas']['LoginDirectLinkMaybe'];
                    export type BasketMessageDiscountViewModel = components['schemas']['BasketMessageDiscountViewModel'];
                    export type BasketMessageType = components['schemas']['BasketMessageType'];
                    export type BasketMessageGwpViewModel = components['schemas']['BasketMessageGwpViewModel'];
                    export type BasketMessageMatasPlusViewModel = components['schemas']['BasketMessageMatasPlusViewModel'];
                    export type BasketMessageSameDayDeliveryViewModel = components['schemas']['BasketMessageSameDayDeliveryViewModel'];
                    export type BasketMessageSampleGroupViewModel = components['schemas']['BasketMessageSampleGroupViewModel'];
                    export type BasketMessageShippingViewModel = components['schemas']['BasketMessageShippingViewModel'];
                    export type BasketMessageWrapper = components['schemas']['BasketMessageWrapper'];
                    export type BasketMessageWrapperMaybe = components['schemas']['BasketMessageWrapperMaybe'];
                    export type MiniBasketViewModel = components['schemas']['MiniBasketViewModel'];
                    

        export function BasketMessageWrapperbasketMessageDiscriminator<T>(
    model: components['schemas']['BasketMessageWrapper']['basketMessage'],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}export function BasketMessageWrapperMaybebasketMessageDiscriminator<T>(
    model: components['schemas']['BasketMessageWrapperMaybe']['basketMessage'],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type _MiniBasketSuccess = paths['/internal/MiniBasket/_MiniBasket']['get']['responses']['200']['content']['text/html'];

        export const _MiniBasketUrl = '/internal/MiniBasket/_MiniBasket';

        
        export function _MiniBasket(): Promise<_MiniBasketSuccess> {
            return fetchHTML(`/internal/MiniBasket/_MiniBasket`)
        }
    
        
     
    
        

        

        export type IndexSuccess = paths['/internal/MiniBasket/Index']['get']['responses']['200']['content']['text/plain'];

        export const IndexUrl = '/internal/MiniBasket/Index';

        
        export function Index(): Promise<IndexSuccess> {
            return fetchJSON<IndexSuccess>(`/internal/MiniBasket/Index`)
        }
    
        
     
    
    