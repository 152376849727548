
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {_MiniBasket,_MiniBasketSuccess,Index,IndexSuccess} from './index'

        
        
    export const _MiniBasketQueryKey = 'minibasket__MiniBasket';

    export function use_MiniBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<_MiniBasketSuccess, TError, _MiniBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _MiniBasketSuccess | (() => _MiniBasketSuccess);},  baseQueryKey = _MiniBasketQueryKey) {
            return useQuery([baseQueryKey, ], 
            _MiniBasket, options);
        }
  
    
        
    export const IndexQueryKey = 'minibasket_Index';

    export function useIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<IndexSuccess, TError, IndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: IndexSuccess | (() => IndexSuccess);},  baseQueryKey = IndexQueryKey) {
            return useQuery([baseQueryKey, ], 
            Index, options);
        }
  
    
    