
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './pendingsubscription';

        export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type PendingSubscriptionState = components['schemas']['PendingSubscriptionState'];
                    export type ExplanationModel = components['schemas']['ExplanationModel'];
                    export type SubscriptionWeekInfo = components['schemas']['SubscriptionWeekInfo'];
                    export type SubscriptionFrequency = components['schemas']['SubscriptionFrequency'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type IProductImageMaybe = components['schemas']['IProductImageMaybe'];
                    export type PendingSubscriptionLineViewModel = components['schemas']['PendingSubscriptionLineViewModel'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type PaymentCardOptionModelMaybe = components['schemas']['PaymentCardOptionModelMaybe'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type CreditCardModel = components['schemas']['CreditCardModel'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessage = components['schemas']['UserMessage'];
                    export type UserMessageMaybe = components['schemas']['UserMessageMaybe'];
                    export type PendingSubscriptionActivationModel = components['schemas']['PendingSubscriptionActivationModel'];
                    

        

        
        

        export type ApproveParams = paths['/internal/myprofile/PendingSubscription/Approve']['get']['parameters']['query'];

        export type ApproveSuccess = paths['/internal/myprofile/PendingSubscription/Approve']['get']['responses']['200']['content']['text/plain'];

        export const ApproveUrl = '/internal/myprofile/PendingSubscription/Approve';

        
        export function Approve(params: ApproveParams): Promise<ApproveSuccess> {
            return fetchJSON<ApproveSuccess>(`/internal/myprofile/PendingSubscription/Approve${formatParams(params)}`)
        }
    
        
     
    
        

        export type CancelParams = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['parameters']['query'];

        export type CancelSuccess = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['responses']['200']['content']['text/plain'];

        export const CancelUrl = '/internal/myprofile/PendingSubscription/Cancel';

        
        export function Cancel(params: CancelParams): Promise<CancelSuccess> {
            return fetchJSON<CancelSuccess>(`/internal/myprofile/PendingSubscription/Cancel${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetModelParams = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['parameters']['query'];

        export type GetModelSuccess = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const GetModelUrl = '/internal/myprofile/PendingSubscription/GetModel';

        
        export function GetModel(params: GetModelParams): Promise<GetModelSuccess> {
            return fetchJSON<GetModelSuccess>(`/internal/myprofile/PendingSubscription/GetModel${formatParams(params)}`)
        }
    
        
     
    
    