/* eslint-disable */
/// This file is auto generated - do not edit manually
export const ReadMore = 'Website.Booking.ConsultationList.ConsultationItem.ReadMore';
export const FreeLabel = 'Website.Booking.ConsultationList.ConsultationItem.FreeLabel';
export const NextAvailableBookingSlotHeadline = 'Website.Booking.ConsultationList.ConsultationItem.NextAvailableBookingSlotHeadline';
export const NextAvailableBookingSlotHeadlineEvent = 'Website.Booking.ConsultationList.ConsultationItem.NextAvailableBookingSlotHeadlineEvent';
export const NextEvent = 'Website.Booking.ConsultationList.ConsultationItem.NextEvent';
export const Consultation = 'Website.Booking.ConsultationList.ConsultationItem.Consultation';
export const Event = 'Website.Booking.ConsultationList.ConsultationItem.Event';
export const Virtual = 'Website.Booking.ConsultationList.ConsultationItem.Virtual';
