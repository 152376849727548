
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './liveevent';

        export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type LiveEventNotificationDeliveryType = components['schemas']['LiveEventNotificationDeliveryType'];
                    export type NotificationDeliveryTypeViewModel = components['schemas']['NotificationDeliveryTypeViewModel'];
                    export type LiveEventNotificationViewModel = components['schemas']['LiveEventNotificationViewModel'];
                    export type LiveEventNotificationViewModelMaybe = components['schemas']['LiveEventNotificationViewModelMaybe'];
                    export type LiveEventFilterType = components['schemas']['LiveEventFilterType'];
                    export type LiveEventSubscriptionViewModel = components['schemas']['LiveEventSubscriptionViewModel'];
                    export type LiveEventSubscriberViewModel = components['schemas']['LiveEventSubscriberViewModel'];
                    export type LiveEventSubscriberOverviewViewModel = components['schemas']['LiveEventSubscriberOverviewViewModel'];
                    export type AddLiveEventNotificationPostModel = components['schemas']['AddLiveEventNotificationPostModel'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type LiveEventInfo = components['schemas']['LiveEventInfo'];
                    export type DateTimeTranslations = components['schemas']['DateTimeTranslations'];
                    export type BambuserViewModel = components['schemas']['BambuserViewModel'];
                    

        

        
        

        export type UnsubscribeAllParams = paths['/internal/LiveEvent/UnsubscribeAll']['post']['parameters']['query'];

        export type UnsubscribeAllSuccess = paths['/internal/LiveEvent/UnsubscribeAll']['post']['responses']['200']['content']['text/plain'];

        export const UnsubscribeAllUrl = '/internal/LiveEvent/UnsubscribeAll';

        
        export function UnsubscribeAll(params: UnsubscribeAllParams): Promise<UnsubscribeAllSuccess> {
            return postQuery<UnsubscribeAllSuccess>(`/internal/LiveEvent/UnsubscribeAll${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetEventSubscriberInfoParams = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['parameters']['query'];

        export type GetEventSubscriberInfoSuccess = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetEventSubscriberInfoUrl = '/internal/LiveEvent/GetEventSubscriberInfo';

        
        export function GetEventSubscriberInfo(params: GetEventSubscriberInfoParams): Promise<GetEventSubscriberInfoSuccess> {
            return fetchJSON<GetEventSubscriberInfoSuccess>(`/internal/LiveEvent/GetEventSubscriberInfo${formatParams(params)}`)
        }
    
        
     
    
        export type AddEventNotificationRequestBody = paths['/internal/LiveEvent/AddEventNotification']['post']['requestBody']['content']['application/json'];

        

        

        export const AddEventNotificationUrl = '/internal/LiveEvent/AddEventNotification';

        
        export function AddEventNotification(requestBody: AddEventNotificationRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/LiveEvent/AddEventNotification`, requestBody)
        }
    
        
     
    
        

        export type GetLiveEventStatusModelParams = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['parameters']['query'];

        export type GetLiveEventStatusModelSuccess = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['responses']['200']['content']['text/plain'];

        export const GetLiveEventStatusModelUrl = '/internal/LiveEvent/GetLiveEventStatusModel';

        
        export function GetLiveEventStatusModel(params: GetLiveEventStatusModelParams): Promise<GetLiveEventStatusModelSuccess> {
            return fetchJSON<GetLiveEventStatusModelSuccess>(`/internal/LiveEvent/GetLiveEventStatusModel${formatParams(params)}`)
        }
    
        
     
    
        

        

        export type BambuserViewModelSuccess = paths['/internal/ModelOnly/BambuserViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BambuserViewModelUrl = '/internal/ModelOnly/BambuserViewModel';

        
        export function BambuserViewModel(): Promise<BambuserViewModelSuccess> {
            return fetchJSON<BambuserViewModelSuccess>(`/internal/ModelOnly/BambuserViewModel`)
        }
    
        
     
    
    