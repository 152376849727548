import { RequestContextViewModel } from 'recoilState/atoms/requestData';

const requestContextObj: RequestContextViewModel = {
    isClubMatasApp: false,
    isClubMatasAppApplePaySupport: false,
    isClubMatasLightPdp: false,
    supportsNativeBackArrow: false,
    supportsNativeTitleBar: false
};

export const setRequestContext = (obj: Record<string, unknown>): void => {
    Object.entries(obj).forEach(([key, item]): void => {
        requestContextObj[key] = item;
    });
};

const RequestContext = (): RequestContextViewModel => requestContextObj;

export default RequestContext();
