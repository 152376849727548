
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './login';

        export type LoginInfoType = components['schemas']['LoginInfoType'];
                    export type LoginLevel = components['schemas']['LoginLevel'];
                    export type LoginMode = components['schemas']['LoginMode'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type LoginInfoIcon = components['schemas']['LoginInfoIcon'];
                    export type LoginInfoHeaderModel = components['schemas']['LoginInfoHeaderModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type TextHyperlinkMaybe = components['schemas']['TextHyperlinkMaybe'];
                    export type LoginInfoModel = components['schemas']['LoginInfoModel'];
                    

        

        
        

        export type _LoginInfoParams = paths['/internal/Login/_LoginInfo']['get']['parameters']['query'];

        export type _LoginInfoSuccess = paths['/internal/Login/_LoginInfo']['get']['responses']['200']['content']['text/plain'];

        export const _LoginInfoUrl = '/internal/Login/_LoginInfo';

        
        export function _LoginInfo(params: _LoginInfoParams): Promise<_LoginInfoSuccess> {
            return fetchJSON<_LoginInfoSuccess>(`/internal/Login/_LoginInfo${formatParams(params)}`)
        }
    
        
     
    
    