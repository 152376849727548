export const BorderDefault = "1px solid #DEDFDF";
export const BorderRadiusExtraSmall = "2px";
export const BorderRadiusSmall = "4px";
export const BorderRadiusMedium = "8px";
export const BorderRadiusLarge = "16px";
export const BorderSecondary = "1px solid #CDCED0";
export const BorderWidthSmall = "1px";
export const BorderWidthMedium = "2px";
export const BorderWidthLarge = "4px";
export const ColorNeutralBlack = "#000000ff";
export const ColorNeutralDarkGrey = "#666666ff";
export const ColorNeutralLightGrey = "#999999ff";
export const ColorNeutralWhite = "#ffffffff";
export const ColorNeutralGrey1 = "#b4b6b8ff";
export const ColorNeutralGrey2 = "#bdbec0ff";
export const ColorNeutralGrey3 = "#cdced0ff";
export const ColorNeutralGrey4 = "#dedfdfff";
export const ColorNeutralGrey5 = "#eaebebff";
export const ColorBrandBlue1 = "#002643ff";
export const ColorBrandBlue2 = "#335169ff";
export const ColorBrandBlue3 = "#667d8eff";
export const ColorBrandBlue4 = "#99a8b4ff";
export const ColorBrandBlue5 = "#ccd4d9ff";
export const ColorBrandBlueHover = "#284861ff";
export const ColorBrandBlueFocus = "#516b7fff";
export const ColorBrandBlueSelected = "#3d5a70ff";
export const ColorBrandBlueActivated = "#516b7fff";
export const ColorBrandBluePressed = "#667c8eff";
export const ColorBrandBlueReadonly = "#0a2e4aff";
export const ColorNordicGrey1 = "#d8d1cbff";
export const ColorNordicGrey2 = "#e0dad5ff";
export const ColorNordicGrey3 = "#e8e3e0ff";
export const ColorNordicGrey4 = "#efedeaff";
export const ColorNordicGrey5 = "#f7f6f5ff";
export const ColorNordicGreyHover = "#c6c0baff";
export const ColorNordicGreyFocus = "#b5afaaff";
export const ColorNordicGreySelected = "#beb7b2ff";
export const ColorNordicGreyActivated = "#b5afaaff";
export const ColorNordicGreyPressed = "#b5afaaff";
export const ColorNordicGreyReadonly = "#cfc8c2ff";
export const ColorClubMatas1 = "#eb595eff";
export const ColorClubMatas2 = "#ef7a7eff";
export const ColorClubMatas3 = "#f39b9eff";
export const ColorClubMatas4 = "#f7bdbfff";
export const ColorClubMatas5 = "#fbdedfff";
export const ColorClubMatasHover = "#f18f92ff";
export const ColorClubMatasFocus = "#f4a4a7ff";
export const ColorClubMatasSelected = "#f2999cff";
export const ColorClubMatasActivated = "#f4a4a7ff";
export const ColorClubMatasPressed = "#f5afb1ff";
export const ColorClubMatasReadonly = "#ef7f83ff";
export const ColorClubMatasPlus1 = "#b38b44ff";
export const ColorClubMatasPlus2 = "#c2a269ff";
export const ColorClubMatasPlus3 = "#d1b98fff";
export const ColorClubMatasPlus4 = "#e1d1b4ff";
export const ColorClubMatasPlus5 = "#f0e8daff";
export const ColorClubMatasPlusHover = "#cbb081ff";
export const ColorClubMatasPlusFocus = "#d5bf99ff";
export const ColorClubMatasPlusSelected = "#d0b88dff";
export const ColorClubMatasPlusActivated = "#d5bf99ff";
export const ColorClubMatasPlusPressed = "#dac7a5ff";
export const ColorClubMatasPlusReadonly = "#c4a56fff";
export const ColorAdvice1 = "#bfd3e7ff";
export const ColorAdvice2 = "#ccdcecff";
export const ColorAdvice3 = "#d9e5f1ff";
export const ColorAdvice4 = "#e5edf5ff";
export const ColorAdvice5 = "#f2f6faff";
export const ColorSubscription1 = "#74b5c8ff";
export const ColorSubscription2 = "#90c4d3ff";
export const ColorSubscription3 = "#acd3deff";
export const ColorSubscription4 = "#c7e1e9ff";
export const ColorSubscription5 = "#e3f0f4ff";
export const ColorSubscriptionHover = "#8ac0d0ff";
export const ColorSubscriptionFocus = "#a0ccd9ff";
export const ColorSubscriptionSelected = "#95c6d5ff";
export const ColorSubscriptionActivated = "#a0ccd9ff";
export const ColorSubscriptionPressed = "#abd2deff";
export const ColorSubscriptionReadonly = "#79b7caff";
export const ColorSpot1 = "#c94c36ff";
export const ColorSpot2 = "#d4705eff";
export const ColorSpot3 = "#df9486ff";
export const ColorSpot4 = "#e9b7afff";
export const ColorSpot5 = "#f4dbd7ff";
export const ColorFixed1 = "#e4803aff";
export const ColorFixed2 = "#e99961ff";
export const ColorFixed3 = "#efb389ff";
export const ColorFixed4 = "#f4ccb0ff";
export const ColorFixed5 = "#fae6d8ff";
export const ColorBeauty1 = "#f190b3ff";
export const ColorBeauty2 = "#f29dbcff";
export const ColorBeauty3 = "#f5afc8ff";
export const ColorBeauty4 = "#f9ceddff";
export const ColorBeauty5 = "#fdedf2ff";
export const ColorBeautyHover = "#f3a1bfff";
export const ColorBeautyFocus = "#f5b3cbff";
export const ColorBeautySelected = "#f4aac5ff";
export const ColorBeautyActivated = "#f5b3cbff";
export const ColorBeautyPressed = "#f6bcd1ff";
export const ColorBeautyReadonly = "#f194b6ff";
export const ColorSkin1 = "#c09b8cff";
export const ColorSkin2 = "#c5a194ff";
export const ColorSkin3 = "#d3b9afff";
export const ColorSkin4 = "#e6d7d1ff";
export const ColorSkin5 = "#f0e8e4ff";
export const ColorMen1 = "#748daaff";
export const ColorMen2 = "#94bcd9ff";
export const ColorMen3 = "#a1c4ddff";
export const ColorMen4 = "#c6dbebff";
export const ColorMen5 = "#e0ebf4ff";
export const ColorHair1 = "#81cdc7ff";
export const ColorHair2 = "#91d4ceff";
export const ColorHair3 = "#a1dad5ff";
export const ColorHair4 = "#c0e6e3ff";
export const ColorHair5 = "#e0f3f1ff";
export const ColorHealth1 = "#77a189ff";
export const ColorHealth2 = "#85aa95ff";
export const ColorHealth3 = "#a0bdacff";
export const ColorHealth4 = "#c9d9d0ff";
export const ColorHealth5 = "#e4ece7ff";
export const ColorFragrance1 = "#a093c4ff";
export const ColorFragrance2 = "#b3a9d0ff";
export const ColorFragrance3 = "#c6bedcff";
export const ColorFragrance4 = "#d9d4e7ff";
export const ColorFragrance5 = "#e7e4f0ff";
export const ColorMedicine1 = "#b8b975ff";
export const ColorMedicine2 = "#c1c187ff";
export const ColorMedicine3 = "#caca98ff";
export const ColorMedicine4 = "#dbdcbaff";
export const ColorMedicine5 = "#e9e9d4ff";
export const ColorSystemPositive1 = "#219916ff";
export const ColorSystemPositive2 = "#2fdb20ff";
export const ColorSystemPositive3 = "#6de663ff";
export const ColorSystemPositive4 = "#acf1a6ff";
export const ColorSystemPositive5 = "#dff5ddff";
export const ColorSystemNegative1 = "#a92828ff";
export const ColorSystemNegative2 = "#f23939ff";
export const ColorSystemNegative3 = "#f67474ff";
export const ColorSystemNegative4 = "#fab0b0ff";
export const ColorSystemNegative5 = "#ffeaeaff";
export const ColorSystemAttention1 = "#b3911bff";
export const ColorSystemAttention2 = "#ffcf27ff";
export const ColorSystemAttention3 = "#ffdd68ff";
export const ColorSystemAttention4 = "#ffeca9ff";
export const ColorSystemAttention5 = "#fff5d0ff";
export const ColorOpacityDark1 = "#000000cc";
export const ColorOpacityDark2 = "#00000099";
export const ColorOpacityDark3 = "#00000066";
export const ColorOpacityDark4 = "#00000033";
export const ColorOpacityDark5 = "#0000001a";
export const ColorOpacityLight1 = "#ffffffcc";
export const ColorOpacityLight2 = "#ffffff99";
export const ColorOpacityLight3 = "#ffffff66";
export const ColorOpacityLight4 = "#ffffff33";
export const ColorOpacityLight5 = "#ffffff1a";
export const ColorOpacityOnDarkHover = "#ffffff29";
export const ColorOpacityOnDarkFocus = "#ffffff52";
export const ColorOpacityOnDarkSelected = "#ffffff3d";
export const ColorOpacityOnDarkActivated = "#ffffff52";
export const ColorOpacityOnDarkPressed = "#ffffff66";
export const ColorOpacityOnLightHover = "#00000014";
export const ColorOpacityOnLightFocus = "#00000029";
export const ColorOpacityOnLightSelected = "#0000001f";
export const ColorOpacityOnLightActivated = "#00000029";
export const ColorOpacityOnLightPressed = "#00000029";
export const ColorFocusStateBorderOnLight = "#e1dcd7ff";
export const ColorFocusStateBorderOnDark = "#ffffff3d";
export const ColorWhiteHover = "#eaeaeaff";
export const ColorWhiteFocus = "#d6d6d6ff";
export const ColorWhiteSelected = "#e0e0e0ff";
export const ColorWhiteActivated = "#d6d6d6ff";
export const ColorWhitePressed = "#d6d6d6ff";
export const ColorWhiteReadonly = "#f4f4f4ff";
export const FontHeading1FamilySansSerif = "'Inter', sans-serif";
export const FontHeading1FamilySerif = "'DM_Serif_Text', serif";
export const FontHeading1Size = "2.5000rem";
export const FontHeading1LineHeight = 1.05;
export const FontHeading1WeightSemiBold = 600;
export const FontHeading1WeightRegular = 400;
export const FontHeading2FamilySansSerif = "'Inter', sans-serif";
export const FontHeading2FamilySerif = "'DM_Serif_Text', serif";
export const FontHeading2Size = "2.0000rem";
export const FontHeading2LineHeight = 1.3125;
export const FontHeading2WeightSemiBold = 600;
export const FontHeading2WeightRegular = 400;
export const FontHeading3FamilySansSerif = "'Inter', sans-serif";
export const FontHeading3FamilySerif = "'DM_Serif_Text', serif";
export const FontHeading3Size = "1.5000rem";
export const FontHeading3LineHeightSansSerifSemiBold = 1;
export const FontHeading3LineHeightSerifRegular = 1.3333;
export const FontHeading3WeightSemiBold = 600;
export const FontHeading3WeightRegular = 400;
export const FontHeading4Size = "1.0000rem";
export const FontHeading4LineHeight = 1.5;
export const FontHeading4FamilySansSerif = "'Inter', sans-serif";
export const FontHeading4FamilySerif = "'DM_Serif_Text', serif";
export const FontHeading4WeightRegular = 400;
export const FontHeading4WeightSemiBold = 600;
export const FontBody1Family = "'Inter', sans-serif";
export const FontBody1Size = "1.0000rem";
export const FontBody1LineHeight = 1.5;
export const FontBody1WeightLight = 300;
export const FontBody1WeightRegular = 400;
export const FontBody1WeightSemiBold = 600;
export const FontBody2Family = "'Inter', sans-serif";
export const FontBody2SizeSmall = "0.8125rem";
export const FontBody2SizeLarge = "0.8750rem";
export const FontBody2LineHeightSmall = 1.2308;
export const FontBody2LineHeightLarge = 1.2143;
export const FontBody2WeightLight = 300;
export const FontBody2WeightRegular = 400;
export const FontBody2WeightSemiBold = 600;
export const FontButtonFamily = "'Inter', sans-serif";
export const FontButtonSizeSmall = "0.8125rem";
export const FontButtonSizeLarge = "1.0000rem";
export const FontButtonLineHeight = 1;
export const FontButtonWeightRegular = 400;
export const FontButtonWeightSemiBold = 600;
export const FontLeadParagraphFamily = "'DM_Serif_Text', serif";
export const FontLeadParagraphSize = "1.0000rem";
export const FontLeadParagraphLineHeight = 1.5;
export const FontLeadParagraphWeight = 400;
export const FontOverlineFamily = "'Inter', sans-serif";
export const FontOverlineSizeLarge = "0.7500rem";
export const FontOverlineSizeSmall = "0.6250rem";
export const FontOverlineLetterSpacing = "1px";
export const FontOverlineLineHeight = 1;
export const FontOverlineWeight = 400;
export const FontOverlineTextTransform = "uppercase";
export const FontCaptionFamily = "'Inter', sans-serif";
export const FontCaptionSizeSmall = "0.5000rem";
export const FontCaptionSizeMedium = "0.6250rem";
export const FontCaptionSizeLarge = "0.7500rem";
export const FontCaptionLineHeightSmall = 1.25;
export const FontCaptionLineHeightMedium = 1.4;
export const FontCaptionLineHeightLarge = 1.3333;
export const FontCaptionWeightLight = 300;
export const FontCaptionWeightRegular = 400;
export const FontCaptionWeightSemiBold = 600;
export const FontLabelFamily = "'Inter', sans-serif";
export const FontLabelSizeSmall = "0.8750rem";
export const FontLabelSizeLarge = "1.0000rem";
export const FontLabelLineHeight = 1;
export const FontLabelWeightRegular = 400;
export const FontLabelWeightSemiBold = 600;
export const FontSize8 = "0.5714rem";
export const FontSize10 = "0.7143rem";
export const FontSize12 = "0.8571rem";
export const FontSize14 = "1.0000rem";
export const FontSize16 = "1.1429rem";
export const FontSize18 = "1.2857rem";
export const FontSize20 = "1.4286rem";
export const FontSize24 = "1.7143rem";
export const FontSize28 = "2.0000rem";
export const FontSize32 = "2.2857rem";
export const FontSize40 = "2.8571rem";
export const FontSize48 = "3.4286rem";
export const FontSize56 = "4.0000rem";
export const FontWeightNormal = 400;
export const FontWeightSemiBold = 600;
export const FontLineHeightNone = 1;
export const FontLineHeightTight = 1.2;
export const FontLineHeightNormal = 1.5;
export const FontLetterSpacing04 = "0.04em";
export const FontFontFamilyPrimary = "'Inter', sans-serif";
export const FontFontFamilySecondary = "'DM_Serif_Text', serif";
export const BoxShadowSmall = "0px 2px 6px rgba(0,0,0,0.08)";
export const BoxShadowMedium = "0px 4px 6px rgba(0,0,0,0.08)";
export const BoxShadowLarge = "0px 6px 6px rgba(0,0,0,0.08)";
export const BoxShadowXl = "0px 8px 6px rgba(0,0,0,0.08)";
export const Spacing2 = "2px";
export const Spacing4 = "4px";
export const Spacing6 = "6px";
export const Spacing8 = "8px";
export const Spacing12 = "12px";
export const Spacing16 = "16px";
export const Spacing20 = "20px";
export const Spacing24 = "24px";
export const Spacing28 = "28px";
export const Spacing32 = "32px";
export const Spacing40 = "40px";
export const Spacing48 = "48px";
export const Spacing56 = "56px";
export const Spacing64 = "64px";
export const Spacing72 = "72px";
export const Spacing80 = "80px";
export const Spacing88 = "88px";
export const Spacing96 = "96px";
export const Spacing104 = "104px";
export const Spacing112 = "112px";
export const Spacing120 = "120px";