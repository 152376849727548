const websiteSettingsObj = {};

export const setWebsiteSettings = (obj: Record<string, unknown>): void => {
    Object.entries(obj).forEach(([key, item]): void => {
        websiteSettingsObj[key] = item;
    });
};

const WebsiteSettings = (): any => websiteSettingsObj;

export default WebsiteSettings();
