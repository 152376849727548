
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './myprofilesubscription';

        export type SubscriptionOverviewUpsaleBannerViewModel = components['schemas']['SubscriptionOverviewUpsaleBannerViewModel'];
                    export type SubscriptionOverviewUpsaleBannerViewModelMaybe = components['schemas']['SubscriptionOverviewUpsaleBannerViewModelMaybe'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProductDetailedViewModel = components['schemas']['ProductDetailedViewModel'];
                    export type SubscriptionWeekInfo = components['schemas']['SubscriptionWeekInfo'];
                    export type PendingSubscriptionOverviewViewModel = components['schemas']['PendingSubscriptionOverviewViewModel'];
                    export type SubscriptionInfoUrls = components['schemas']['SubscriptionInfoUrls'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type PaymentMethodError = components['schemas']['PaymentMethodError'];
                    export type SubscriptionAdhocOrderViewModel = components['schemas']['SubscriptionAdhocOrderViewModel'];
                    export type SubscriptionAdhocOrderViewModelMaybe = components['schemas']['SubscriptionAdhocOrderViewModelMaybe'];
                    export type SubscriptionViewModel = components['schemas']['SubscriptionViewModel'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionPriceSummaryViewModel = components['schemas']['SubscriptionPriceSummaryViewModel'];
                    export type SubscriptionMyProfileViewModel = components['schemas']['SubscriptionMyProfileViewModel'];
                    export type SubscriptionListViewModel = components['schemas']['SubscriptionListViewModel'];
                    export type SubscriptionOverviewViewModel = components['schemas']['SubscriptionOverviewViewModel'];
                    export type SubscriptionOverviewViewModelMaybe = components['schemas']['SubscriptionOverviewViewModelMaybe'];
                    export type OverviewViewModel = components['schemas']['OverviewViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ValidatedOperationResult = components['schemas']['ValidatedOperationResult'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type DeliveryParcelShopOption = components['schemas']['DeliveryParcelShopOption'];
                    export type ParcelShopSearchResponseModel = components['schemas']['ParcelShopSearchResponseModel'];
                    export type CheckoutStateField = components['schemas']['CheckoutStateField'];
                    export type DropDownListItemViewModel = components['schemas']['DropDownListItemViewModel'];
                    export type DropDownListViewModel = components['schemas']['DropDownListViewModel'];
                    export type ChoosableSubscriptionFrequencyGroupViewModel = components['schemas']['ChoosableSubscriptionFrequencyGroupViewModel'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessageViewModel = components['schemas']['UserMessageViewModel'];
                    export type SubscriptionPackageViewModel = components['schemas']['SubscriptionPackageViewModel'];
                    export type SubscriptionPackageListViewModel = components['schemas']['SubscriptionPackageListViewModel'];
                    export type SubscriptionDetailsViewModel = components['schemas']['SubscriptionDetailsViewModel'];
                    export type SubscriptionAdhocOrderAction = components['schemas']['SubscriptionAdhocOrderAction'];
                    export type SubscriptionAdhocOrderActionModel = components['schemas']['SubscriptionAdhocOrderActionModel'];
                    export type SubscriptionAdhocOrderUseCaseModel = components['schemas']['SubscriptionAdhocOrderUseCaseModel'];
                    export type SubscriptionAdhocOrderUseCaseModelMaybe = components['schemas']['SubscriptionAdhocOrderUseCaseModelMaybe'];
                    export type AdhocOrderViewModel = components['schemas']['AdhocOrderViewModel'];
                    export type ConfirmUpdateViewModel = components['schemas']['ConfirmUpdateViewModel'];
                    export type CancelViewModel = components['schemas']['CancelViewModel'];
                    export type SubscriptionUpdateResult = components['schemas']['SubscriptionUpdateResult'];
                    export type SubscriptionPriceViewModel = components['schemas']['SubscriptionPriceViewModel'];
                    

        export function DeliveryParcelShopOptionpriceDiscriminator<T>(
    model: components['schemas']['DeliveryParcelShopOption']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type OverviewModelSuccess = paths['/internal/myprofile/Subscription/OverviewModel']['get']['responses']['200']['content']['text/plain'];

        export const OverviewModelUrl = '/internal/myprofile/Subscription/OverviewModel';

        
        export function OverviewModel(): Promise<OverviewModelSuccess> {
            return fetchJSON<OverviewModelSuccess>(`/internal/myprofile/Subscription/OverviewModel`)
        }
    
        
     
    
        

        

        export type GetAllSuccess = paths['/internal/myprofile/Subscription/GetAll']['get']['responses']['200']['content']['text/plain'];

        export const GetAllUrl = '/internal/myprofile/Subscription/GetAll';

        
        export function GetAll(): Promise<GetAllSuccess> {
            return fetchJSON<GetAllSuccess>(`/internal/myprofile/Subscription/GetAll`)
        }
    
        
     
    
        

        

        

        export const DeliveryGroupModelUrl = '/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel';

        
        export function DeliveryGroupModel(): Promise<unknown> {
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel`)
        }
    
        
     
    
        

        

        

        export const GetPrivateAddressModelUrl = '/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel';

        
        export function GetPrivateAddressModel(): Promise<unknown> {
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel`)
        }
    
        
     
    
        

        export type SelectAndUpdatePrivateDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['parameters']['query'];

        export type SelectAndUpdatePrivateDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdatePrivateDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress';

        
        export function SelectAndUpdatePrivateDeliveryAddress(params: SelectAndUpdatePrivateDeliveryAddressParams): Promise<SelectAndUpdatePrivateDeliveryAddressSuccess> {
            return postQuery<SelectAndUpdatePrivateDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectAndUpdateCompanyDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['parameters']['query'];

        export type SelectAndUpdateCompanyDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateCompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress';

        
        export function SelectAndUpdateCompanyDeliveryAddress(params: SelectAndUpdateCompanyDeliveryAddressParams): Promise<SelectAndUpdateCompanyDeliveryAddressSuccess> {
            return postQuery<SelectAndUpdateCompanyDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectAndUpdateParcelShopDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['parameters']['query'];

        export type SelectAndUpdateParcelShopDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateParcelShopDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery';

        
        export function SelectAndUpdateParcelShopDelivery(params: SelectAndUpdateParcelShopDeliveryParams): Promise<SelectAndUpdateParcelShopDeliverySuccess> {
            return postQuery<SelectAndUpdateParcelShopDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['parameters']['query'];

        export type SelectMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery';

        
        export function SelectMatasDelivery(params: SelectMatasDeliveryParams): Promise<SelectMatasDeliverySuccess> {
            return postQuery<SelectMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery${formatParams(params)}`)
        }
    
        
     
    
        

        export type SelectAndUpdateMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['parameters']['query'];

        export type SelectAndUpdateMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery';

        
        export function SelectAndUpdateMatasDelivery(params: SelectAndUpdateMatasDeliveryParams): Promise<SelectAndUpdateMatasDeliverySuccess> {
            return postQuery<SelectAndUpdateMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListMatasParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['parameters']['query'];

        export type ListMatasParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops';

        
        export function ListMatasParcelShops(params: ListMatasParcelShopsParams): Promise<ListMatasParcelShopsSuccess> {
            return fetchJSON<ListMatasParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops${formatParams(params)}`)
        }
    
        
     
    
        

        export type ListParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['parameters']['query'];

        export type ListParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListParcelShops';

        
        export function ListParcelShops(params: ListParcelShopsParams): Promise<ListParcelShopsSuccess> {
            return fetchJSON<ListParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListParcelShops${formatParams(params)}`)
        }
    
        
     
    
        

        

        

        export const CompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress';

        
        export function CompanyDeliveryAddress(): Promise<unknown> {
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress`)
        }
    
        
     
    
        

        export type ValidateFieldForShippingSwaggerParams = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['parameters']['query'];

        export type ValidateFieldForShippingSwaggerSuccess = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['responses']['200']['content']['text/plain'];

        export const ValidateFieldForShippingSwaggerUrl = '/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger';

        
        export function ValidateFieldForShippingSwagger(params: ValidateFieldForShippingSwaggerParams): Promise<ValidateFieldForShippingSwaggerSuccess> {
            return postQuery<ValidateFieldForShippingSwaggerSuccess>(`/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetDetailsParams = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['parameters']['query'];

        export type GetDetailsSuccess = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetDetailsUrl = '/internal/myprofile/SubscriptionDetails/GetDetails';

        
        export function GetDetails(params: GetDetailsParams): Promise<GetDetailsSuccess> {
            return fetchJSON<GetDetailsSuccess>(`/internal/myprofile/SubscriptionDetails/GetDetails${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetMoreParams = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['parameters']['query'];

        export type GetMoreSuccess = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['responses']['200']['content']['text/plain'];

        export const GetMoreUrl = '/internal/myprofile/SubscriptionDetails/GetMore';

        
        export function GetMore(params: GetMoreParams): Promise<GetMoreSuccess> {
            return fetchJSON<GetMoreSuccess>(`/internal/myprofile/SubscriptionDetails/GetMore${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['parameters']['query'];

        export type GetAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['responses']['200']['content']['text/plain'];

        export const GetAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/GetAdhocOrder';

        
        export function GetAdhocOrder(params: GetAdhocOrderParams): Promise<GetAdhocOrderSuccess> {
            return fetchJSON<GetAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/GetAdhocOrder${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['parameters']['query'];

        export type UpdateAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['responses']['200']['content']['text/plain'];

        export const UpdateAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder';

        
        export function UpdateAdhocOrder(params: UpdateAdhocOrderParams): Promise<UpdateAdhocOrderSuccess> {
            return postQuery<UpdateAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetConfirmUpdateInfoParams = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['parameters']['query'];

        export type GetConfirmUpdateInfoSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmUpdateInfoUrl = '/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo';

        
        export function GetConfirmUpdateInfo(params: GetConfirmUpdateInfoParams): Promise<GetConfirmUpdateInfoSuccess> {
            return fetchJSON<GetConfirmUpdateInfoSuccess>(`/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetCancelParams = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['parameters']['query'];

        export type GetCancelSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['responses']['200']['content']['text/plain'];

        export const GetCancelUrl = '/internal/myprofile/SubscriptionUpdate/GetCancel';

        
        export function GetCancel(params: GetCancelParams): Promise<GetCancelSuccess> {
            return fetchJSON<GetCancelSuccess>(`/internal/myprofile/SubscriptionUpdate/GetCancel${formatParams(params)}`)
        }
    
        
     
    
        

        export type ConfirmCancelParams = paths['/internal/myprofile/SubscriptionUpdate/ConfirmCancel']['get']['parameters']['query'];

        

        export const ConfirmCancelUrl = '/internal/myprofile/SubscriptionUpdate/ConfirmCancel';

        
        export function ConfirmCancel(params: ConfirmCancelParams): Promise<unknown> {
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionUpdate/ConfirmCancel${formatParams(params)}`)
        }
    
        
     
    
        

        export type OnHoldParams = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['parameters']['query'];

        export type OnHoldSuccess = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['responses']['200']['content']['text/plain'];

        export const OnHoldUrl = '/internal/myprofile/SubscriptionUpdate/OnHold';

        
        export function OnHold(params: OnHoldParams): Promise<OnHoldSuccess> {
            return fetchJSON<OnHoldSuccess>(`/internal/myprofile/SubscriptionUpdate/OnHold${formatParams(params)}`)
        }
    
        
     
    
        

        export type UpdateParams = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['parameters']['query'];

        export type UpdateSuccess = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['responses']['200']['content']['text/plain'];

        export const UpdateUrl = '/internal/myprofile/SubscriptionUpdate/Update';

        
        export function Update(params: UpdateParams): Promise<UpdateSuccess> {
            return fetchJSON<UpdateSuccess>(`/internal/myprofile/SubscriptionUpdate/Update${formatParams(params)}`)
        }
    
        
     
    
        

        export type GetSubscriptionPriceTotalParams = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['parameters']['query'];

        export type GetSubscriptionPriceTotalSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['responses']['200']['content']['text/plain'];

        export const GetSubscriptionPriceTotalUrl = '/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal';

        
        export function GetSubscriptionPriceTotal(params: GetSubscriptionPriceTotalParams): Promise<GetSubscriptionPriceTotalSuccess> {
            return fetchJSON<GetSubscriptionPriceTotalSuccess>(`/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal${formatParams(params)}`)
        }
    
        
     
    
    